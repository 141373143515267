<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <h3 class="tit_section text-center">차명주식회수</h3>
                <p class="desc_section text-center"><strong>실제 소유자가 다른 사람의 명의를<br> 빌려 보유한 주식을 뜻합니다.</strong><br> 차명주식을 회수하지 않는다면<br> 그 주식에 대한 재산권 분쟁,<br> 회사의 경영권 간섭 등 여러 가지<br> 문제가 발생할 수 있습니다 </p>
            </div>
            <div class="particle_circle-border" style="top:29vw;left:4.444vw"></div>
            <div class="particle_circle-double" style="bottom:-17vw;right:-10vw;width:42vw;height:42vw"></div>
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(189)" type="button">
                <span>차명주식회수 상담신청</span>
            </button>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        <h3 class="tit_section">차명주식회수 컨설팅 사례</h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/e56EQSjFpwU" data-title="법적 안전장치가 필요한 명의신탁 주식" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/e56EQSjFpwU/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="list_talk">
            <div class="item_talk left">
                <p class="tit_talk">의뢰인</p>
                <img class="profile" :src="imgPath+'example_talk_profile_client1.jpg'" alt="">
                <div class="tooltip">
                    <p class="tit">문제점</p>
                    <p>명의수탁자는 작은 회사로 시작되었기 때문에 관심이 없다가 세월이 지나 회사의 규모가 커지고 주식의 가치가 높아진 것을 보고 소유권 주장하였습니다.</p>
                </div>
            </div>
            <div class="item_talk right">
                <p class="tit_talk">컨설팅 전문가</p>
                <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                <div class="tooltip">
                    <p class="tit">해결방법</p>
                    <ol>
                        <li>차명주식 신탁 당시 작성된 명의신탁 약정서를 찾아내어 주주확인 소송을 진행하고 관할 세부서를 통해 명의신탁 해지를 접수하여 완료하였습니다. </li>
                        <li>지원본부 소속의 세무사와 변호사의 발빠른 대응으로 주주확인 소송과 명의신탁 해지 절차로 마무리가 되었습니다</li>
                    </ol>
                </div>
            </div>
            <div class="item_talk right">
                <div class="tooltip">
                    <p class="tit">결론</p>
                    <p>의뢰인과의 정확한 이해와 빠른 판단 그리고 지원본부 소속의 전문가 그룹과의 발빠른 대응으로 <span class="color-ff6815">안정적으로 신탁을 회수</span>할 수 있었습니다.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 차명주식 문제점 -->
    <section id="problem" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">차명주식 문제점</h3>
            <div class="cons_info_area">
                <p class="tit_cons pl-45"><i class="bullet">1.</i>명의수탁자의 신변의 변화에 따른 문제가 발생할 수 있다.</p>
                <ul class="list_box">
                    <li class="item_box">
                        <img class="illust" style="top:5vw" :src="imgPath+'problem_illust1.png'" alt="">
                        <p class="tit">명의수탁자 <em class="color-ff6815">사망 시,</em></p>
                        <p class="desc">해당 차명주식을 상속받은 배우자<br> 또는 자녀가 소유권을 주장할 수 있습니다. </p>
                    </li>
                    <li class="item_box">
                        <img class="illust" style="top:5vw" :src="imgPath+'problem_illust2.png'" alt="">
                        <p class="tit">명의수탁자 <em class="color-ff6815">신용 문제 시,</em></p>
                        <p class="desc">차명주식을 압류 당할 수도 있으며,<br> 명의수탁자가 변심하여 소유권을<br> 주장할 수도 있습니다. </p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area">
                <p class="tit_cons pl-45"><i class="bullet">2.</i>차명주식의 명의자와 실제소유자가 다르기 때문에 문제가 발생할 수 있다.</p>
                <div class="pt-65">
                    <img class="d-block mx-auto mb-40" :src="imgPath+'problem_illust4.png'" style="width:45.074%" alt="">
                    <p class="word-keep">실제소유자인 명의신탁자의 가업상속계획, 지분이동계획 등에 제약이 있을 수 있습니다. 또한, 이러한 제약 때문에 차명주식을 회수하는데 드는 거래비용 (증여세, 양도소득세, 간주취득세 등 세금부담)이 발생할 수도 있습니다.</p>
                </div>
            </div>
            <div class="cons_info_area area3 mb-0">
                <p class="tit_cons pl-45"><i class="bullet">3.</i>차명주식의 보유 자체만으로 세무상 위험을 가지게 된다.</p>
                <div class="pt-65">
                    <img class="d-block mx-auto mb-40" :src="imgPath+'problem_illust3.png'" style="width:50%" alt="">
                    <p class="word-keep">과세당국은 차명주식의 편법증여나 주가조작을 통한 불공정거래에 차명주식을 이용하여 탈세를 한 경우 끝까지 추적하여 적발하고 있으며, 세금 추징 또한 엄격하게 하고 있습니다. 차명주식 보유로 인해 세무조사 대상이 되기도 합니다.</p>
                </div>
            </div>
        </div>
    </section>
    <!-- //차명주식 문제점 -->

    <!-- 회수방법 -->
    <section id="collect" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">회수 방법</h3>
            <div class="cons_info_area">
                <p class="tit_cons pl-45"><i class="bullet">1.</i>차명주식을 명의수탁자로부터 증여하거나 양도하는 방법</p>
                <ul class="list_box">
                    <li class="item_box">
                        <p class="tit">주식 <em class="color-ff6815">증여(수증)</em> 활용</p>
                        <p class="desc">실제 소유자인 명의신탁자가 증여세를<br> 납부하여야 합니다.</p>
                        <img class="illust" style="top:5vw" :src="imgPath+'collect_illust1.png'" alt="">
                    </li>
                    <li class="item_box">
                        <p class="tit">주식 <em class="color-ff6815">양도</em> 활용 </p>
                        <p class="desc">실제소유자인 명의신탁자가 주식 양수도<br> 대금을 명의수탁자에게 지급하여야 하고,<br> 명의수탁자는 양도소득세를 납부합니다. </p>
                        <img class="illust" style="top:5vw" :src="imgPath+'collect_illust2.png'" alt="">
                    </li>
                </ul>
            </div>
            <div class="cons_info_area">
                <p class="tit_cons pl-45"><i class="bullet">2.</i>차명주식 실제소유자 확인제도를 이용하는 방법</p>              
                <p class="tit_sub">차명주식 실제소유자 확인제도는 간소화 된 서류를 통해 명의신탁주식을 실제소유자에게 환원할 수 있도록 과세 당국이 지원하는 제도입니다.</p>
                <div class="terms">
                    <p class="tit">차명주식 실제소유자 확인제도 <strong>자격 조건</strong></p>                
                    <div class="terms_cont">
                        <ul>
                            <li>조세특례제한법 상 중소기업에 해당 되어야 함</li>
                            <li>2001년 7월 23일 이전에 법인을 설립한 기업</li>
                            <li class="top_dot">실제 소유자와 명의수탁자 모두 법인 설립<br> 당시 발기인이어야 함</li>
                            <li class="top_dot">실소유자 별, 주식발행 법인별로 실명 전환하는 주식가액의 합계약이 30억원 미만이어야 함</li>
                        </ul>
                    </div>
                </div>  
                <div class="terms">
                    <p class="tit">차명주식 실제소유자 확인제도 <strong>신청 절차</strong></p>
                    <img class="d-block mx-auto mb-100" :src="imgPath+'collect_terms.png'" style="width:90%" alt="">                    
                </div>
            </div>            
            <div class="cons_info_area area3">
                <p class="tit_cons pl-45"><i class="bullet">3.</i>차명주식의 실제소유자인 명의신탁자가 과세당국에 명의신탁 해지신청을 하는 방법</p>
                <div class="submit">
                    <p class="tit">차명주식이라는 사실을 입증하기 위한<br>명의신탁자 <strong>증빙 제출 서류</strong> </p>
                    <ul class="list_submit">
                        <li class="item_submit">
                            <div class="box_ico"><img :src="imgPath+'collect_submit1.png'" alt=""></div>
                            <p class="tit_item">명의신탁주식 실<br>소유자 확인서</p>
                        </li>
                        <li class="item_submit">
                            <div class="box_ico"><img :src="imgPath+'collect_submit2.png'" alt=""></div>
                            <p class="tit_item">중소기업 등<br>기준검토표</p>
                        </li>
                        <li class="item_submit">
                            <div class="box_ico"><img :src="imgPath+'collect_submit3.png'" alt=""></div>
                            <p class="tit_item">명의신탁경위 에<br>대한 진술서</p>
                        </li>
                        <li class="item_submit">
                            <div class="box_ico"><img :src="imgPath+'collect_submit4.png'" alt=""></div>
                            <p class="tit_item">명의신탁시 <br>통장거래내역</p>
                        </li>
                    </ul>
                    <div class="list_submit-footer">
                        <p>과세당국에 제출<span>* 명의신탁 당시에 증여세 발생할 수 있음</span></p>
                        <img :src="imgPath+'collect_submit_footer.png'" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //회수방법 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/collection/'
        }
    },
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/collection.scss';
</style>
import { render, staticRenderFns } from "./policy_b.vue?vue&type=template&id=15e5f688&scoped=true&"
import script from "./policy_b.vue?vue&type=script&lang=js&"
export * from "./policy_b.vue?vue&type=script&lang=js&"
import style0 from "./policy_b.vue?vue&type=style&index=0&id=15e5f688&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15e5f688",
  null
  
)

export default component.exports
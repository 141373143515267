<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="b">
        <div class="inner">
            <h2>가지급금정리</h2>
            <p class="desc_section">
                법인에서 <b>실제 현금의 지출은 있었지만 금액이 확정되지</b><br>
                <b>않은 경우</b> 에 그 지출액에 대해 임시적으로 표시하는<br>
                가계정으로 상당수의 법인 대표들은 회사에 가지급금이<br>
                얼마나 있는지 모르는 경우가 많습니다.
            </p>
            <div class="div-button-consult">
                <button class="btn_cont" onclick="LAYER_CONT(190)" type="button">
                    <span>가지급금 상담신청</span>
                    <i></i>
                </button>
            </div>
        </div>
    </section>
    <!-- //메인 -->

     <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="b">
        <h3 class="tit_section">가지급금정리 컨설팅 사례</h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/pDt5dbrg_2g" data-title="가지급금 발생원인과 효과적인 해결방안" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/pDt5dbrg_2g/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="inner">
            <div class="list_talk">
                <div class="item_talk">
                    <img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
                    <div class="tooltip">
                        <p class="tit"><strong>컨설팅 문의</strong></p>
                        <p>제조업으로 과거 운영 과정에서 불가피하게 70여억원에 이르는 가지급금이 발생되어 이를 해결하기를 원합니다.</p>
                    </div>
                </div>
                <div class="item_solution">
                    <p class="item_tit"><strong>해결방법</strong></p>
                    <div class="item_answer">
                        <p>지원본부 소속의 세무사와 변호사와의 팀플레이를 통해 상법상 적법한 절차 범위 내에서 산업재산권과 자기주식을 매입하였으며, 배당이 가능한 이익의 범위 내에서 가지급금 상계처리를 진행하였습니다.</p>
                        <p>적은 세금으로 70억원의 가지급금 해결 및 소명하였습니다.</p>
                        <p>이에 따라 회사의 부실자산이 줄어들면서 재무구조 개선과 신용도가 향상되었으며 또한 인정이자가 영업 외 이익으로 잡히게 되어 추가로 납부하던 법인세 경감의 효과를 얻었습니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /컨설팅 사례 -->


    <!-- 효과 -->
     <section id="plan">
        <h3 class="tit_section">가지급금 정리방안</h3>
        <div class="inner">
            <div class="plan_list">
                <div class="plan_item isAppear">
                    <p class="no-number">가지급금의 정리방안은  그 과정이 상당히 <strong>전문적이고 어려워 더 큰 세금 문제나 법률 위반 문제로 번질 수 있기 때문에 반드시 관련된 전문가의 도움을 받는 것을 추천</strong>합니다.</p>
                    <div class="round_item">
                        <ul>
                            <li data-action-number="2"><span>대표이사<br>개인자산<br>매각</span></li>
                            <li data-action-number="1"><span>배당정책을 통한<br>배당수익으로<br>처리</span></li>
                            <li data-action-number="3"><span>대표이사<br>상여, 급여로<br>처리</span></li>
                            <li data-action-number="6"><span>자기주식 취득으로 처리</span></li>
                            <li data-action-number="4"><span>법인<br>이익잉여금<br>활용 처리</span></li>
                            <li data-action-number="5"><span>산업재산권으로<br>처리</span></li>
                            <li class="center"><span>대표이사<br>퇴직금으로<br>처리</span> </li>
                        </ul>
                    </div>
                </div>
                <div class="plan_item isAppear">
                    <div class="checkpoint">
                        <p>가지급금<i>&nbsp;Check point</i></p>
                        <ol>
                            <li>
                                <p>Check&nbsp;</p>
                                <div class="check_text">
                                    법인자산 매도 시 업무와 관련이 있는<br> 자산이어야 다시 가지급금으로 인정되거나<br> 상여 처분이 되지 않습니다.
                                </div>
                            </li>
                            <li>
                                <p>Check&nbsp;</p>
                                <div class="check_text">
                                    산업재산권을 활용할 경우 업무유관 자산인지,<br> 대표가 직접 개발한 것인지, 기존 법인특허의<br> 상관관계 등을 고려해야 합니다.
                                </div>
                            </li>
                            <li>
                                <p>Check&nbsp;</p>
                                <div class="check_text">
                                    산업재산권, 자사주 매입, 특허권을 활용할 경우<br> ‘특허권 및 비상장주식 가치평가’ 가 중요합니다.
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / 효과 -->

     <!-- 문제점 -->
    <section id="risk">
        <h3 class="tit_section">가지급금 발생 원인 및 리스크</h3>
        <div class="inner">
            <div class="risk_list">
                <div class="risk_item isAppear">
                    <p><strong>가지급금 발생 원인</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 자기주식을 취득하여 보유하다가 처분하는 경우" :src="imgPath+'payment_b_riskimg_01.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">대표이사 및 임원, 주주에게<br> 자금대여</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 자기주식을 취득하여 보유하다가 처분하는 경우" :src="imgPath+'payment_b_riskimg_02.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">일용직 노동자 임금, 접대비,<br> 리베이트 등 증빙 처리하지<br> 못한 지출</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 자기주식을 취득하여 보유하다가 처분하는 경우" :src="imgPath+'payment_b_riskimg_03.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">관계회사에 자금 대여</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 자기주식을 취득하여 보유하다가 처분하는 경우" :src="imgPath+'payment_b_riskimg_04.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">대표이사 및 임원의<br> 개인적 비용 지출</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 자기주식을 취득하여 보유하다가 처분하는 경우" :src="imgPath+'payment_b_riskimg_05.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">실무자의 회계적 실수</div>
                        </div>
                    </div>
                </div>
                <div class="risk_item isAppear">
                    <p><strong>법인 가지급금 리스크</strong></p>
                    <ul>
                        <li>인정이자발생</li>
                        <li>인정이자 만큼<br> 법인세 증가 </li>
                        <li>인정이자 미납 시<br> 대표 상여처리 </li>
                        <li>지급이자<br> 손금불산입 </li>
                        <li>대손처리<br> 불가능 </li>
                        <li>세무조사의<br> 위험성 증가 </li>
                        <li>기업신용평가<br> 시 불이익 </li>
                        <li>가업승계 시 상속 및<br> 증여세 부담 가중 </li>
                        <li>양도/폐업 시<br> 대표 상여처리 </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!-- / 문제점 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess_b data-type="1" data-list-count="7" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/payment_b/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/payment_b.scss';
</style>
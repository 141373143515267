<template>
<!-- main -->
<main id="container">
	<!-- 메인 -->
	<section id="visual" class="isAppear" data-type="b">
		<div class="inner">
			<h2>법인전환</h2>
			<p class="desc_section">
				법인전환을 하게 되면 정부가 <b>개인의 고소득세율을</b><br>
				<b>상대적으로 낮은 법인세로 전환</b>하기 위하여<br>
				다양한 세제상의 지원을 하고있습니다.
			</p>
			<div class="div-button-consult">
				<button class="btn_cont" onclick="LAYER_CONT(192)" type="button">
					<span>법인전환 상담신청</span>
					<i></i>
				</button>
			</div>
		</div>
	</section>
	<!-- //메인 -->

	<!-- 컨설팅 사례 -->
	<section id="example" class="isAppear" data-type="b">
		<h3 class="tit_section">법인전환 컨설팅 사례</h3>
		<button class="videoLayer" data-src="https://www.youtube.com/embed/rw7BtqATOMg" data-title="법인전환 방법 총정리! 나에게 맞는 법인전환을 찾자!" data-type="youtube" type="button" title="영상재생">
			<img class="thumb" src="https://img.youtube.com/vi/rw7BtqATOMg/maxresdefault.jpg" alt="스크린샷">
		</button>
		<div class="inner">
			<div class="list_talk">
				<div class="item_talk">
					<img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
					<div class="tooltip">
						<p class="tit"><strong>컨설팅 문의</strong></p>
						<p>강남구 소재의 중소형빌딩을 20년전에 취득 후 양도, 상속, 증여 등의 다각도의 절세방안을 검토하였으나, 현물출자방식의 임대법인전환이 가장 합리적이라고 판단하여 이에 따른 방법과 절차에 대해 알고 싶습니다.</p>
					</div>
				</div>
				<div class="item_solution">
					<p class="item_tit"><strong>해결방법</strong></p>
					<div class="item_answer">
						<p>임대용 부동산의 토지 및 건물의 가치를 감정평가사의 감정절차를 통해 평가, 회계사의 감사 및 결산이라는 절차를 통하여 신설법인을 설립하였습니다.</p>
						<p>법원의 설립인가를 위한 법무사의 법적인 실무작업을 동시에 진행하였습니다.</p>
						<p>지원본부의 세무사, 감정평가사, 법무사와 팀을 이뤄 현물출자 법인전환을 통해 개정된 주식가치 평가 방법과 이월된 양도소득세가 주식 가치 평가에 영향을 주어 주식가치가 하락하고 절대적인 상속재산의 가치를 평가절하 함으로 상속세 절세효과를 기대하게 되었습니다.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /컨설팅 사례 -->

	<section id="target">
		<h3 class="tit_section">법인전환 컨설팅 사례</h3>
		<div class="inner">
			<div class="target_list">
				<div class="target_item isAppear">
					<p>지원 요건</p>
					<div class="thumb_list">
						<div class="thumb_item">
							<div class="thumb">
								<img alt="성실신고 확인제도 대상 기업으로 세금부담이 크고, 과세당국의 중점관리 대상이 되는 기업" :src="imgPath+'conversion_b_target_img_01.png'">
							</div>
							<div class="text">
								<div class="text_cnt"><strong>성실신고 확인제도 대상 기업</strong>으로 세금부담이 크고, 과세당국의 중점관리 대상이 되는 기업</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="가업승계에 따라 상속세와 증여세 부담이 큰 기업" :src="imgPath+'conversion_b_target_img_02.png'">
							</div>
							<div class="text">
								<div class="text_cnt">가업승계에 따라 상속세와 증여세 부담이 큰 기업</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="기업 소유 부동산 가격이 급격하게 상승했거나 예정이어서, 양도소득세 부담이 큰 기업" :src="imgPath+'conversion_b_target_img_03.png'">
							</div>
							<div class="text">
								<div class="text_cnt">기업 소유 부동산 가격이 급격하게 상승했거나 예정이어서, 양도소득세 부담이 큰 기업</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="순이익과 사업소득 이외에 배당소득, 임대소득 등 타 소득이 높아 종합소득세 부담이 큰 기업" :src="imgPath+'conversion_b_target_img_04.png'">
							</div>
							<div class="text">
								<div class="text_cnt">순이익과 사업소득 이외에 배당소득, 임대소득 등 타 소득이 높아 종합소득세 부담이 큰 기업</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="임대사업자로 가업승계를 하려고 하나 가업상속공제 대상에서 제외되어 있는 기업" :src="imgPath+'conversion_b_target_img_05.png'">
							</div>
							<div class="text">
								<div class="text_cnt">임대사업자로 가업승계를 하려고 하나 가업상속공제 대상에서 제외되어 있는 기업</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="금융권 대출이나 정부기관 입찰 참여를 위해 대외 신용도를 높이고자 하는 기업" :src="imgPath+'conversion_b_target_img_06.png'">
							</div>
							<div class="text">
								<div class="text_cnt">금융권 대출이나 정부기관 입찰 참여를 위해 대외 신용도를 높이고자 하는 기업</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="정부 정책자금 지원과 기타 고용지원 정책의 혜택을 받고자 하는 기업" :src="imgPath+'conversion_b_target_img_07.png'">
							</div>
							<div class="text">
								<div class="text_cnt">정부 정책자금 지원과 기타 고용지원 정책의 혜택을 받고자 하는 기업</div>
							</div>
						</div>
					</div>

					<table class="table table-gray">
						<caption><strong class="orange">성실신고확인대상</strong> 수입기준 금액 조정</caption>
						<thead>
							<tr>
								<th>구분</th>
								<th>농업,<br>도소매업</th>
								<th>제조업,<br>건설업 </th>
								<th>서비스업 </th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>2017년</td>
								<td>20억원<br>이상</td>
								<td>10억원<br>이상</td>
								<td>5억원<br>이상</td>
							</tr>
							<tr class="gray">
								<td>2018년~<br>2019년</td>
								<td>15억원<br>이상</td>
								<td>7억 5천원<br>이상</td>
								<td>5억원<br>이상</td>
							</tr>
							<tr>
								<td>2020년</td>
								<td>10억원<br>이상</td>
								<td>5억원<br>이상</td>
								<td>3억 5천원<br>이상</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="target_item isAppear">
					<p>개인사업자 법인전환 방법</p>
					<div class="thumb_list type">
						<div class="thumb_item">
							<div class="thumb">
								<img alt="일반사업 양.수도에 의한 방법 " :src="imgPath+'conversion_b_target_img_08.png'">
							</div>
							<div class="text">
								<div class="text_cnt">일반사업<br> 양.수도에 의한 방법 </div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="현물출자에 의한 방법" :src="imgPath+'conversion_b_target_img_09.png'">
							</div>
							<div class="text">
								<div class="text_cnt">현물출자에<br> 의한 방법</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="포괄사업 양.수도에 의한 방법" :src="imgPath+'conversion_b_target_img_10.png'">
							</div>
							<div class="text">
								<div class="text_cnt"> 포괄사업 양.수도에<br> 의한 방법 </div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="중소기업 간 통합에 의한 방법" :src="imgPath+'conversion_b_target_img_11.png'">
							</div>
							<div class="text">
								<div class="text_cnt"> 중소기업 간<br> 통합에 의한 방법 </div>
							</div>
						</div>
					</div>
				</div>
				<div class="target_item isAppear">
					<p>법인전환 시 꼭 필요한 정관을 통한 제도 정비</p>
					<div class="tab-container isAppear">
						<strong>법인 CEO의 법규</strong>
						<ul>
							<li>
								<span>급여</span>
								<span>상여</span>
								<span>퇴직금</span>
								<span>배당</span>
								<span>자기주식</span>
								<span>유족보상금</span>
							</li>
							<li>
								정관
							</li>
						</ul>
					</div>
					<div class="tab-container isAppear">
						<strong>근로자의 법규</strong>
						<ul>
							<li>
								<span>최저임금</span>
								<span>주40 + 12<br>(52시간)</span>
								<span>연장ㆍ야간ㆍ<br>휴일수당</span>
								<span>육아휴직</span>
								<span>산재보험</span>
								<span>퇴직금</span>
								<span>주휴수당</span>
								<span>고용보험</span>
							</li>
							<li>
								최저임금법,<br>
								고용보험법,<br>
								모성보호법,<br>
								근로기준법,<br>
								퇴직급여보장법,<br>
								산업재해보상법,<br>
								중대재해처벌법 등
							</li>
						</ul>
					</div>
					<div class="tab-container gray isAppear">
						<strong>상법상 상대적 기재사항</strong>
						<ul>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_1_1.png" alt="">
								</div>
								<p>중간배당,현물배당</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_1_2.png" alt="">
								</div>
								<p>주식양도제한 규정</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_1_3.png" alt="">
								</div>
								<p>주식매수 선택권</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_1_4.png" alt="">
								</div>
								<p>직무발명보상</p>
							</li>
						</ul>
					</div>
					<div class="tab-container gray isAppear">
						<strong>세법상 상대적 기재사항</strong>
						<ul>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_2_1.png" alt="">
								</div>
								<p>현물출자</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_2_2.png" alt="">
								</div>
								<p>이익소각</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_2_3.png" alt="">
								</div>
								<p>자기주식 취득</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_2_4.png" alt="">
								</div>
								<p>임원의<br>상여금 및 퇴직금</p>
							</li>
							<li>
								<div class="thumb">
									<img src="/img/consulting/conversion_b/target_img_2_5.png" alt="">
								</div>
								<p>유족보상</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="target_item isAppear">
					<div class="checkpoint">
						<p>개인사업자 법인전환<i>&nbsp;Check point</i></p>
						<ol>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									현재 처한 회사의 사정에 따라 이익이 되는지를 고려해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									세금 부담은 어떻게 달라지는지 고려해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									회사의 업무 특성과 부합하는지 고려해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									기업의 자금 여건을 고려해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									향후 매출을 고려해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									비용 등을 고려해야 합니다.
								</div>
							</li>
						
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- 지원제도 -->
	<section id="support">
		<h3 class="tit_section">개인사업자 VS 법인사업자</h3>
		<div class="inner">
			<div class="tab-container">
				<div class="tab_nav">
					<button type="button" class="tab-btn active"><span>법인사업자</span></button>
					<button type="button" class="tab-btn"><span>개인사업자</span></button>
				</div>
				<div class="tab-wrapper">
					<div class="tab_wrap active">
						<div class="item_cont">
							<p class="item_title">1. 개인사업자 VS 법인사업자</p>
							<div class="list">
								<p class="list_title">설립절차와 비용</p>
								<ul>
									<li>법원 설립등기 절차가 필요하며 자본금과 등록면허세 등의 설립비용이 소요됨</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">사업의 책임성과 신뢰도</p>
								<ul>
									<li>법인의 주주는 출자나 지분한도 내에서만 책임을 지고 개인에 비래 대외신뢰도가 높음</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">자금조달과 이익분배</p>
								<ul>
									<li>주식발행이나 회사채 발행을 통해 자금을 조달</li>
									<li>배당을 통해 이익이 분배됨</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">소득처분</p>
								<ul>
									<li>세무조정금액이 사외로 유출된 경우</li>
									<li>귀속자의 소득이로 처분하여 귀속자의 소득세 납세의무 유발</li>
									<li>무조정금액이 시내에 남아 있는 경우 </li>
									<li>유보로 처분하여 관리</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">대표자/사업자 급여</p>
								<ul>
									<li>손금인정</li>
									<li>대표에게 근로소득세부과</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">대표자/사업자 퇴직급여</p>
								<ul>
									<li>퇴직급여 충당금의 설정 가능</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">가업승계 시 자산평가</p>
								<ul>
									<li>지분승계로 비상장주식 평가법 적용</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="tab_wrap">
						<div class="item_cont">
							<p class="item_title">1. 개인사업자 VS 법인사업자</p>
							<div class="list">
								<p class="list_title">설립절차와 비용</p>
								<ul>
									<li>경영상 발생하는 모든 문제와 부채손실에 대한 위험은 전적으로 사업주 혼자 책임</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">금조달과 이익분배</p>
								<ul>
									<li>자본조달에 한계</li>
									<li>발생이익 활용에 제약 없음</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">소득처분</p>
								<ul>
									<li>세무조정금액이 사외로 유출된 경우</li>
									<li>귀속자의 소득으로 처분하지 않고 사업주가 인출하여 증여한 걷으로 봄</li>
									<li>세무조정금액이 기업내부에 남아있는 경우 </li>
									<li>유보로 처분하여 유보소득조정 명세서에서 관리함</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">대표자/사업자 급여</p>
								<ul>
									<li>필요경비 불 산입</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">대표자/사업자 퇴직급여</p>
								<ul>
									<li>퇴직급여 충당금의 설정 불가</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">가업승계 시 자산평가</p>
								<ul>
									<li>시가(실거래가)</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="item_cont">
					<p class="item_title">2. 개인사업자 VS 법인사업자 세율 비교</p>
					<table class="table table-gray">
						<thead>
							<tr>
								<th>소득구간</th>
								<th>개인사업자<br>세율</th>
								<th>소득구간</th>
								<th>법인사업자<br>세율</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>1,200만<br>이하</td>
								<td>6%</td>
								<td>2억원<br>이하</td>
								<td>10%</td>
							</tr>
							<tr class="gray">
								<td>4,600만<br>이하</td>
								<td>15%</td>
								<td>2억 ~ 200억원<br>이하</td>
								<td>20%</td>
							</tr>
							<tr>
								<td>8,800만<br>이하</td>
								<td>24%</td>
								<td>200억원<br>초과</td>
								<td>22%</td>
							</tr>
							<tr class="gray">
								<td>1억 5천만<br>이하</td>
								<td>35%</td>
								<td>3000억원<br>초과</td>
								<td>25%</td>
							</tr>
							<tr>
								<td>3억 이하</td>
								<td>38%</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr class="gray">
								<td>5억 이하</td>
								<td>40%</td>
								<td>-</td>
								<td>-</td>
							</tr>
							<tr>
								<td>5억 이상</td>
								<td>42%</td>
								<td>-</td>
								<td>-</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="item_cont">
					<p class="item_title">3. 개인사업자 법인전환 방법별 주요 세금</p>
					<table class="table table-gray">
						<thead class="thead-multiline">
							<tr>
								<th rowspan="2" style="border-right:1px solid #e1e0e1">구분</th>
								<th colspan="3" style="border-bottom:1px solid #e1e0e1">법인전환방법</th>
							</tr>
							<tr>
								<th>세 감면<br>포괄양수도</th>
								<th>현물출자</th>
								<th>일반사업<br>양수도</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<th>부가가치세</th>
								<td>과세제외</td>
								<td>과세제외</td>
								<td>과세</td>
							</tr>
							<tr class="gray">
								<th>양도소득세</th>
								<td>이월과세</td>
								<td>이월과세</td>
								<td>과세</td>
							</tr>
							<tr>
								<th>부동산 등<br>취득세</th>
								<td>면제</td>
								<td>면제</td>
								<td>과세</td>
							</tr>
							<tr class="gray">
								<th>차량 등<br>취득세</th>
								<td>면세</td>
								<td>면세</td>
								<td>과세</td>
							</tr>
							<tr>
								<th>법인성립 등록면허세</th>
								<td>과세</td>
								<td>과세</td>
								<td>과세</td>
							</tr>
							<tr class="gray">
								<th>국민주택<br>채권</th>
								<td>적액매입</td>
								<td>적액매입</td>
								<td>적액매입</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</section>
	<!-- /지원제도 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-type="1" data-list-count="7" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="isAppear"></section>
	<!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/conversion_b/'
		}
	}
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/conversion_b.scss';
</style>
<template>
	<section id="networkTab" class="section isAppear" data-tab-index="1">
		<h3 class="tit_section">전문가 네트워크</h3>
		<ul class="list_illust">
			<li class="item_illust">
				<label>
					<img src="이미지경로" alt="" />
					<p class="tit">내용</p>
				</label>
			</li>
		</ul>
	</section>
</template>



<script>
import $ from "jquery";

	export default {
		name: "networkTab",
		data() {
			return {
				imgPath: "/img/common/contents/networkTab/",
			};
		},
		mounted() {
			
			$(function () {

				var isReal = /mkbiz\.kr/.test(location.href);
				var isTest = /web\.inplat\.kr/.test(location.href);

				function getJson (url){
					var result;
					$.ajax({
						url : url,
						contentType : 'text/plain',
						async : false,
						success : function(data){
							result = data;
						},
						error : function(data){
							result = JSON.parse(data.responseText.replace(/\/\*.*\*\/|\/\/.*/g, ''));
						}
					});
					return result;
				}

				function getNetwork () {
					var jsonUrl;
					if(isReal){
						jsonUrl = '//json.peoplelife.co.kr';
					} else if(isTest) {
						jsonUrl = '//json-web.inplat.kr';
					} else {
						jsonUrl = '//localhost:58401';
						// jsonUrl = '//10.159.32.136:58401';
					}
					return getJson(jsonUrl+'/json/network.json');
				}
				var network = getNetwork();

				// DOCUMENT READY...
				/*
					■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
					*/
				(function () {
					/* 리스트 생성 */
					var $wrap = $("#networkTab");
					var $list = $wrap.find(".list_illust");
					var $item = $wrap.find(".item_illust");
					var $item_copied = $item.detach();

					var networkList = network.slice(1);
					
					networkList.forEach(function (each) {
						var $_li = $item_copied.clone();
						$_li.attr({
							"data-name": each.id,
							"data-type": each.type,
							"data-group": each.name,
						});
						$_li.find("img").attr("src","/img/common/contents/networkTab/" + each.id + ".jpg");
						$_li.find(".tit").html(each.name);
						$_li.appendTo($list);
					});
				})();
				/*
					■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
					*/
				(function () {
					var $wrap = $("#networkTab");
					var $tabBtn = $wrap.find(".btn_tab");

					$tabBtn.on("click", function () {
						var $this = $(this);
						var index = $this.index() + 1;
						$wrap.attr("data-tab-index", index);
					});
				})();
				/*
				       ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
				       */
			}); // DOCUMENT READY...
		},
	};

</script>



<style lang="scss" scoped>
	$imgPath: "/img/common/contents/networkTab/";

	#networkTab {
		.tit_section {
			margin-bottom: 6vw;
			padding-top: 10vw;
			font-size: 6.4vw;
			font-weight: 500;
			text-align: center;
			letter-spacing: -0.07em;
		}

		.desc_section {
			padding: 7vw 0 6.7vw;
			font-size: 4vw;
			color: #666666;
			text-align: center;
		}

		.list_tab {
			display: flex;
			justify-content: space-between;
			margin-bottom: 6.7vw;
			padding: 2.4vw 4.4444vw;
			border-top: 1px solid #f0f0f0;
			border-bottom: 1px solid #f0f0f0;
			background-color: #f8f9fb;

			.btn_tab {
				height: 6.7vw;
				padding: 0 4vw;
				border-radius: 2em;
				font-size: 4vw;
				color: #89898f;
				line-height: 1em;
			}
		}

		@for $i from 1 through 3 {
			&[data-tab-index="#{$i}"] {
				.btn_tab:nth-child(#{$i}) {
					color: #fff;
					font-weight: 500;
					background-color: $G-colorPrimary;
				}
			}
		}

		.list_illust {
			position: relative;
			transition: height 0.4s ease-in-out;

			.item_illust {
				position: absolute;
				transition: all 0.4s ease-in-out;

				img {
					width: 26.67vw;
					height: 26.67vw;
					border: 1px solid #e7e7e7;
					border-radius: 100%;
					transform: scale(0.6);
					transition: none;
					filter: grayscale(100%);
				}

				.tit {
					position: absolute;
					bottom: -9vw;
					left: 50%;
					width: 80%;
					height: 8vw;
					font-size: 3.2vw;
					color: #666666;
					text-align: center;
					line-height: 1.3em;
					letter-spacing: -0.06em;
					transform: translateX(-50%);
					word-break: keep-all;
				}

				&.is-active {
					img {
						filter: grayscale(0);
					}
				}
			}
		}

		&.is-event-none .item_illust img {
			filter: grayscale(0);
		}

		&[data-tab-index="1"] .list_illust {
			min-height: 90.67vw;
		}

		&[data-tab-index="2"] .list_illust {
			height: 90.67vw;
		}

		&[data-tab-index="3"] .list_illust {
			height: 90.67vw;
		}

		$topCenter: 45.73vw;
		$leftCenter: 36.53vw;
		$value: 
			// name				top1		left1			opacity1	top2			left2			opacity2		top3				left3			opacity3	index
			"sejong_tsi"		0 			5.5vw 			1	 		0		 		 5.5vw 		 		1	 		$topCenter 			$leftCenter 		0		12,
			"gaius"			    0 			5.5vw 			1	 		0		 		 5.5vw 		 		1	 		$topCenter 			$leftCenter 		0		11,
			"seohyun"			0 			26.13vw 		1	 		0		 		 5.5vw 		 		1	 		$topCenter 			$leftCenter 		0		10,
			"ikseon"			0 			47.2vw  		1	 		0		 		 $leftCenter 		1	 		$topCenter 			$leftCenter 		0		9,
			"manda"				0 			68.13vw 		1	 		$topCenter	 	 $leftCenter 		0	 		$topCenter 			56.8vw 				1		8,
			"ipo"				43.6vw  	5.5vw 			1	 		$topCenter	  	 $leftCenter 		0	 		0 		   			16.67vw 			1		7,
			"estate"			43.6vw  	26.13vw 		1	 		$topCenter	  	 $leftCenter 		0	 		0 		   			56.8vw 				1		6,
			"dpbs"				43.6vw  	47.2vw 			1	 		$topCenter	  	 5.5vw 		 		1	 		$topCenter 			$leftCenter			1		5;
		@each $name, $top1, $left1, $opacity1, $top2, $left2, $opacity2, $top3, $left3, $opacity3, $index in $value {
			.item_illust[data-name="#{$name}"] {
				z-index: #{$index};
			}
			&[data-tab-index="1"] {
				.item_illust[data-name="#{$name}"] {
					top: #{$top1};
					left: #{$left1};
					opacity: #{$opacity1};
				}
			}

			&[data-tab-index="2"] {
				.item_illust[data-name="#{$name}"] {
					top: #{$top2};
					left: #{$left2};
					opacity: #{$opacity2};
				}
			}

			&[data-tab-index="3"] {
				.item_illust[data-name="#{$name}"] {
					top: #{$top3};
					left: #{$left3};
					opacity: #{$opacity3};
				}
			}
		}

		&.appear .item_illust {
			img {
				transform: scale(1) !important;
				transition: all 0.6s 0.3s ease-in-out;
			}
		}
	}
</style>
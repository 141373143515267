<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <h3 class="tit_section text-center">가업승계전략</h3>
                <p class="desc_section text-center">기업의 가업승계는 <strong>기업의 규모와<br>형태, 상속 및 증여자의 요건에 따라<br>그 방법을 달리</strong>해야 하며, <strong>상속세나<br>증여세의 문제에 대한 대책을<br>체계적으로 마련</strong>해야 합니다. </p>
            </div>
            <div class="particle_circle-border" style="top:30vw;left:4.444vw"></div>
            <div class="particle_circle-double" style="bottom:-24vw;right:-10vw;width:42vw;height:42vw"></div>
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(193)" type="button"><span>가업승계전략 상담신청</span></button>
        </div>
       
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        <h3 class="tit_section">가업승계전략 컨설팅 사례 </h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/ZKy8V5yukFQ" data-title="특정법인(관계회사)을 활용한 가업승계" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/ZKy8V5yukFQ/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="inner">
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_client1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>대표이사 부부의 지분이 100%로 후계자에 대한 지분이 없어 상속 발생 시 상속세가 30억 이상 발생되는 문제가 있습니다. </p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>기업가치 인하에 우선적으로 방향성을 두어 실질적인 절세효과를 가져왔습니다.</li>
                            <li>사모님의 지분을 먼저 이동 후에 대표이사의 주식을 이동하였으며, 기업가치 인하 플랜은 구체적으로 순손익과 순자산 두가지 요인을 구분해서 5년간의 솔루션을 도출하였습니다.</li>
                            <li>후계자 지분 100%로 관련 신설법인 설립 후 합병하였습니다.</li>
                            <li>대표이사의 실질적인 임원퇴직을 하여 순손익 주당가치 평가금액을 인하 하였습니다</li>
                            <li>임원퇴직 후 반영된 기업가치로 사모님 40% 지분을 STEP UP 이익소각 => 후계자 가족대상으로 3개년 차등배당을 실시하였습니다.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 지원제도 -->
    <section id="support" class="isAppear">
        <h3 class="tit_section">가업승계 지원제도 </h3>
      
        <div class="tab-container">
            <nav class="tab-nav">
                <button class="tab-btn" type=button>기업상속공제</button>
                <button class="tab-btn" type=button>가업승계 증여세 과세특례</button>
            </nav>
            <div class="tab-wrapper">
                <div class="tab-slide">
                    <div class="item_cont">
                        <p class="tit_container">1. 지원요건</p>
                        <div class="item">
                            <p class="tit">기업요건</p>
                            <div class="desc">
                                <p class="mb-20 pl-20"><i class="bullet">•</i>피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 조특법상 중소기업(매출액과 독립성 요건 충족)으로서 자산총액 5천억원 미만인 중소기업</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 직전 3개 사업연도의 평균매출액이 3천억원 미만인 조특법상 중견기업</p>
                                <p class="pl-20"><i class="bullet">•</i>2개 이상의 가업을 영위하는 경우 가업에 해당하는지 여부는 각 사업장별로 판단</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">피상속자 (증여자)</p>
                            <div class="desc">
                                <p class="mb-20 pl-20"><i class="bullet">•</i>상속개시일 현재 거주자</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>피상속인과 그 특수관계자가 지분 50%이상을 10년 이상 계속하여 보유</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>아래 3가지 중 1가지를 충족하는 기간을 대표이사로 재직(공동대표이사 포함)</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">1.</i>가업영위기간의 50% 이상</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">2.</i>상속개시일부터 소급한 10년 중 5년 이상(위 1,2는 피상속인이 상속개시일 현재 가업을 사망일까지 영위하여야 함)</p>
                                <p class="ml-20 pl-30"><i class="bullet">3.</i>10년 이상의 기간(상속인이 피상속인의 대표이사 직을 승계한 날부터 상속개시일까지 계속하여 재직한 경우에 한함)</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">상속인 (수증자) </p>
                            <div class="desc">
                                <p class="mb-20 pl-20"><i class="bullet">•</i>상속개시일 현재 18세 이상</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>상속개시일 전 2년 이상 가업에 종사(피상속인이 65세 이전이나 천재지변으로 사망 시 등 일부 예외인정 규정 존재)</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>상속제 신고기한까지 임원취임 및 신고기한부터 2년 이내 대표이사 취임</p>
                                <p class="mb-10 pl-20"><i class="bullet">•</i>중견기업은 가업상속재산 외에 상속재산의 가액이 해당 상속인이 상속세로 납부할 금액에 2배를 초과하지 않아  야 함</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i>위 1,2,3번의 요건을 상속인의 배우자가 충족시에도 인정</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i>2인 이상의 공동상속 인정</p>
                                <p class="ml-20 pl-30"><i class="bullet">-</i>가업재산 중 일부만의 가업상속도 인정<br>(단,모든요건을 갖춘 상속인이 상속받는 가업상속재산만 가업상속공제가 됨)</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">신고서 제출 </p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>상속세 과세표준신고서와 함께 가업상속공제신고서 등을 관할 세무서장에게 제출하여야 함</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">지원배제 요건</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>피상속인 또는 상속인이 가업경영에서 탈세/회계부정 행위로 징역형 또는 벌금형을 선고받고 형이 확정될 경우에는 가업상속공제 배제 (사후관리기간이라면 추징)</p>
                            </div>
                        </div>
                    </div>
                    <div class="item_cont">
                        <p class="tit_container">2. 지원 제도</p>
                        <div class="item">
                            <p class="tit">가업요건</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>가업상속재산가액 전액을 한도범위내에서 상속세 계산 시 상속공제를 해줌</p>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i><strong>가업상속재산(법인가업)</strong></p>
                                <p class="ml-20 mb-20 pl-30">상증법상 주식평가액 X [1-(사업무관자산가액/총자산가액)]</p>
                                <p class="ml-20 pl-30"><i class="bullet">-</i><strong>사업무관자산(상속개시일 현재)</strong></p>
                                <p class="ml-50 pl-40"><i class="bullet">①</i>비사업용토지(법인세법 55조의 2)</p>
                                <p class="ml-50 pl-40"><i class="bullet">②</i>업무무관자산(법인세법 시행령 49조) 및 임대용 부동산</p>
                                <p class="ml-50 pl-40"><i class="bullet">③</i>대여금(법인세법 시행령 61조1항2호)</p>
                                <p class="ml-50 pl-40"><i class="bullet">④</i>과다보유 현금(직전 5개 사업연도말 평균 현금 보유액의 150% 초과)</p>
                                <p class="ml-50 pl-40"><i class="bullet">⑤</i>법인의 영업활동과 무관하게 보유한 주식등, 채권 및 금융상품④는 제외)</p>
                                <p class="mt-20 ml-40 pl-30"><i class="bullet">-</i><strong>공제한도 : 피상속인의 가업영위기간별로 10년 이상 200억원, 20년이상 300억원, 30년 이상 500억원으로 차등 적용</strong></p>
                                <p class="ml-65 pl-40"><i class="bullet">①</i>일반연부연납 가능</p>
                                <p class="ml-65 pl-40"><i class="bullet">②</i>가업상속재산에 해당 하나 가업상속공제를 받지 못한 부분에 대해서는 최장 20년의 연부연납 가능</p>
                            </div>
                        </div>
                    </div>
                    <div class="item_cont">
                        <p class="tit_container">3. 사후 관리</p>
                        <div class="item">
                            <p class="tit">기간</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>상속개시일부터 7년을 사후관리 </p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">자산처분 금지요건 </p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>가업용자산의 20%(상속개시일부터 5년 이내는 10%)이상을 처분하는 경우 </p>
                                <p class="mt-10 ml-20 pl-30"><i class="bullet">-</i><strong>가업용자산이란 가업법인의 사업에 직접 사용되는 사업용 고정자산</strong></p>
                                <p class="mt-10 ml-20 pl-30"><i class="bullet">-</i><strong>처분비율 : 처분한 가업용자산/가업용 자산(가액은 상속개시일 기준)</strong></p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">가업종사 요건</p>
                            <div class="desc">
                                <p class="mb-10 pl-20"><i class="bullet">•</i>피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i><strong>대표이사 직을 유지하지 않는 경우</strong></p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i><strong>가업의 주된 업종을 변경하는 경우(중분류 내 변경은 인정, 평가심의위원회 심의시는 중분류 외 변경 허용)</strong></p>
                                <p class="ml-20 pl-30"><i class="bullet">-</i><strong>1년 이상 휴/폐업하는 경우</strong></p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">지분유지 요건</p>
                            <div class="desc">
                                <p class="mb-10 pl-20"><i class="bullet">•</i>상속인의 지분이 감소하는 경우</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i><strong>처분/유상증자시 실권하는 경우</strong></p>
                                <p class="ml-20 pl-30"><i class="bullet">-</i><strong>상속인의 특수관계자가 처분 및 유상증자시 실권하여 상속인이 최대주주에 해당되지 않은 경우</strong></p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">고용유지 요건1</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>아래를 모두 충족하는 경우</p>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i><strong>매년의 정규직 근로자 수의 평균이 기준고용인원의 80%에 미달하는 경우</strong></p>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i><strong>매년의 정규직 근로자의 총급여액이 기준총급여액의 80%에 미달하는 경우</strong></p>
                                <p class="ml-50 pl-40"><i class="bullet">①</i>정규직 근로자는 근로기준법에 의하되 계약기간 1년 미만 / 월60시간 미만 근로자 / 건강.국민연금 미가입자 등은 제외</p>
                                <p class="ml-50 pl-40"><i class="bullet">②</i>기준고용인원 : 상속개시 직전 2개 사업연도의 정규직근로자 수의 평균</p>
                                <p class="ml-50 pl-40"><i class="bullet">③</i>기준총급여액 : 상속개시 직전 2개 사업연도의 정규직근로자에게 지급한 총급여액의 평균</p>
                                <p class="ml-50 pl-40"><i class="bullet">④</i>이 기준은 매년 판단함</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">고용유지 요건2</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>상속개시된 사업연도말부터 7년간 정규직 근로자 수의 전체평균이 기준고용인원에 미달하고, 7년간 총급여액의 전체 평균이 기준총급여액에 미달하는 경우(7년 후 판단)</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">위반시 제재</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>[공제받은 금액 X 사후의무 위반기간에 따른 추징율]<br> 한 금액을 상속개시 당시의 상속세 과세가액에 산입하여 상속세를 재계산한 상속세에 추징사유 발생일까지의 이자 상당액(연1.8%의 이율)을 가산하여 납부하여야 함</p>
                                <p class="mt-20 pl-20"><i class="bullet">•</i>단, 자산처분금지 위반시는<br> (공제받은 금액 X 추징율 X 자산처분비율)한 금액을<br> 기준으로 함</p>
                                <p class="mt-20 ml-20 pl-20"><i class="bullet">-</i><strong>기간별 추징율 : 5년 미만은 100%, 5년 이상은 80%</strong></p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">정당한 사유</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>사후관리의무 위반에 정당한 사유가 있는 경우에는 상속세가 추징되지 않음<br> Ex) 내용연수가 지난 가업용자산의 처분, 상속인의 사망, 합병 등 조직변경 / 물납 / 상장요건, 충족위한 지분감소(단, 최대주주는 유지해야 함)</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">기타</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>가업상속재산에 대한 양도소득세 이월과세</p>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i>가업상속공제를 적용받는 재산 중 양도소득세 과세대상 재산에 대해서는 상속인이 양도할 때 피상속인의 보유기간 동안 발생한 지본이득에 대하여 양도소득세를 납부하여야 함</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-slide">
                    <div class="item_cont">
                        <p class="tit_container">1. 지원요건</p>
                        <div class="item">
                            <p class="tit">기업요건</p>
                            <div class="desc">
                                <p class="mb-20 pl-20"><i class="bullet">•</i>피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 조특법상 중소기업(매출액과 독립성 요건 충족)으로서 자산총액 5천억원 미만인 중소기업</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 직전 3개 사업연도의 평균매출액이 3천억원 미만인 조특법상 중견기업</p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>2개 이상의 가업을 영위하는 경우 가업에 해당하는지 여부는 각 사업장별로 판단</p>
                                <p class="pl-20"><i class="bullet">•</i>단, 증여자를 기준으로 요건충족여부를 판단하며,  개인사업체의 경우에는 증여세 과세특례규정이  적용되지 않음 </p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">피상속자 (증여자)</p>
                            <div class="desc">
                                <p class="mb-20 pl-20"><i class="bullet">•</i>증여일 현재 60세 이상인 수증자의 부모(증여당시 부모가 사망한 경우에는 그 사망한 부모의 부모를 포함) </p>
                                <p class="mb-20 pl-20"><i class="bullet">•</i>증여자와 그 특수관계자가 기분의 50%이상을 10년 이상 계속하여 보유</p>
                                <p class="mb-10 pl-20"><i class="bullet">•</i>가업을 10년 이상 계속하여 경영(대표이사 재직요건은 없음)</p>
                                <p class="ml-20 pl-20 color-212e50"><i class="bullet">*</i> 부모가 각각 10년 이상 계속하여 가업을 경영한 경우에는 부와 모가 모두 특례증여의 증여자가 될 수 있음.</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">상속인 (수증자) </p>
                            <div class="desc">
                                <p class="mb-20 pl-20"><i class="bullet">•</i>증여일 현재 18세 이상의 거주자인 자녀</p>
                                <p class="mb-10 pl-20"><i class="bullet">•</i>수증자 또는 그 배우자가 증여세 신고 기한까지 가업에  종사하고, 증여일로 부터 5년 이내에 대표이사 (공통대표 이사 포함)에 취임</p>
                                <p class="ml-20 pl-20"><i class="bullet">-</i>2인 이상이 가업을 승계하는 경우 가업승계자 모두에게 특례적용</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">신고서 제출 </p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>증여세 신고기한까지 과세표준 신고서와 함께 주식 등 특례신청서를 납세지 관할세무서장에게 제출하여야 함 </p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">지원배제 요건</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>없음</p>
                            </div>
                        </div>
                    </div>
                    <div class="item_cont">
                        <p class="tit_container">2. 지원 제도</p>
                        <div class="item">
                            <p class="tit">가업요건</p>
                            <div class="desc">
                                <p class="mb-40 pl-20"><i class="bullet">•</i>가업주식등의 가액 중 가업자산상당액에 대한 증여세의 과세가액(100억원 한도)에서 5억원을 공제한 후, 10%(과세표준 30억원 초과분은 20%)세율을 적용하여 증여세를 계산</p>
                                <table class="table mb-40">
                                    <colgroup>
                                        <col style="width:auto;">
                                        <col style="width:auto;">
                                        <col style="width:auto;">
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>가업자산상당액</th>
                                            <th>공제</th>
                                            <th>과세표준</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>5억</td>
                                            <td>5억</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td>35억</td>
                                            <td>5억</td>
                                            <td>30억</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i><strong>가업자산상당액 </strong></p>
                                <p class="ml-20 pl-40">상증법상 주식평가액 X [1-(사업무관자산가액/총자산가액)]</p>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i><strong>사업무관자산은 좌동(증여일 현재)</strong></p>
                                <p class="mt-20 ml-20 pl-30"><i class="bullet">-</i><strong>공제한도는 100억원</strong></p>
                                <p class="ml-50 pl-40"><i class="bullet">①</i>해당 증여 전에 부모로부터 동일 가업주식을 증여받은 가액은 합산하나, 일반증여재산과는 합산하지 않음</p>
                                <p class="ml-50 pl-40"><i class="bullet">②</i>신고세액공제 미적용</p>
                                <p class="ml-50 pl-40"><i class="bullet">③</i>가업주식 등의 가액 중 사업무관자산비율 상당액과 과세특례한도초과분은 일반증여재산으로서의 증여세를 계산함</p>
                                <p class="ml-50 pl-40"><i class="bullet">④</i>일반연부연납 가능</p>
                                <p class="ml-50 pl-40"><i class="bullet">⑤</i>가업상속요건을 갖춘 경우에는 특례증여 받은 주식도 추후 가업상속공제가 가능</p>
                                <p class="ml-50 pl-40"><i class="bullet">⑥</i>상속공제 종합한도에서 차감는 사전증여에서 제외됨</p>
                                <p class="ml-50 pl-40"><i class="bullet">⑦</i>한 수증자가 가업승계와 창업자금의 과세특례를 중복적용 받을 수는 없음</p>
                            </div>
                        </div>
                    </div>
                    <div class="item_cont">
                        <p class="tit_container">3. 사후 관리</p>
                        <div class="item">
                            <p class="tit">기간</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>증여일부터 7년을 사후관리 </p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">자산처분 금지요건 </p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>해당사항 없음</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">가업종사 요건</p>
                            <div class="desc">
                                <p class="mb-10 pl-20"><i class="bullet">•</i>피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i><strong>대표이사 직을 유지하지 않는 경우</strong></p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i><strong>가업의 주된 업종을 변경하는 경우(중분류 내 변경은 인정, 평가심의위원회 심의시는 중분류 외 변경 허용)</strong></p>
                                <p class="ml-20 pl-30"><i class="bullet">-</i><strong>1년 이상 휴/폐업하는 경우</strong></p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">지분유지 요건</p>
                            <div class="desc">
                                <p class="mb-10 pl-20"><i class="bullet">•</i>상속인의 지분이 감소하는 경우</p>
                                <p class="mb-10 ml-20 pl-30"><i class="bullet">-</i><strong>처분/유상증자시 실권하는 경우</strong></p>
                                <p class="ml-20 pl-30"><i class="bullet">-</i><strong>상속인의 특수관계자가 처분 및 유상증자시 실권하여 상속인이 최대주주에 해당되지 않은 경우</strong></p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">고용유지 요건1</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>해당사항 없음</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">고용유지 요건2</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>해당사항 없음</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">위반시 제재</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>해당 가업주식의 가액을 일반증여재산으로 보아 기본세율(10%~50%)로 증여세를 재계산하여 추징사유 발생일까지의 이자상당액(1일 2.5/10,000의 이율)과 함께 납부하여야 함</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">정당한 사유</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>사후관리의무 위반에 정당한 사유가 있는 경우에는 증여세가 추징되지 않음<br> Ex) 수증자의 사망, 증여받은 주식을 국가 등에 증여, 수증자의 병역의무 이행 / 질병요양 등, 합병 등 조직변경 / 물납 / 상장요건 충족위한 지분감소(단, 최대주주는 유지해야 함)</p>
                            </div>
                        </div>
                        <div class="item">
                            <p class="tit">기타</p>
                            <div class="desc">
                                <p class="pl-20"><i class="bullet">•</i>기간에 관계없이 상속재산에 합산<br>(증여 후 10년이 경과하여도 상속재산에 합산. 단 요건 충족시에는 해당 주식에 대하여 가업상속공제 적용 가능)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //지원제도 -->

    <!-- 3가지 방법 -->
    <section id="triple" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">가업승계 3가지 방법</h3>
            <div class="cons_info_area area1">
                <p class="tit_cons text-center mb-40">가업승계 전략 </p>
                <img class="img-fluid" :src="imgPath+'triple_graph.png'" alt="">
            </div>
            <div class="cons_info_area">
                <p class="tit_cons mb-50 pl-45"><i class="bullet">1.</i>중소기업의 대주주가 비상장법인의 주식을 <br><span class="color-f47557">양도</span>하는 경우</p>                
                <img class="img-fluid" :src="imgPath+'triple_graph2.png'" alt="">
            </div>
            <div class="cons_info_area">
                <p class="tit_cons">2. 자녀가 부모의 주식을 <span class="color-f47557">증여</span>를 통해 승계하는 경우</p>
                <p class="desc_cons"><i class="bullet">•</i>10% ~ 50% 증여세율 적용</p>
                <p class="desc_cons"><i class="bullet">•</i>승계 시점에 부모의 주식가치가 높다면 증여세 부담이 증가되기에 승계 이전 주식가치를 낮추는 것이 좋음</p>
                <p class="desc_cons"><i class="bullet">•</i>증여세 부담이 있다면 원활한 가업승계가 어렵기 때문에 현행 세법의 다양한 가업승계 지원제도 이용<br> (가업상속공제, 가업승계 증여세 과세특례)</p>
            </div>
            <div class="cons_info_area">
                <p class="tit_cons">3. <span class="color-f47557">상속</span>을 통한 가업승계</p>
                <p class="desc_cons"><i class="bullet">•</i>비상장회사일수록 상속제 재원문제를 법인 활용하여 준비하는 것이 필수적</p>
                <p class="desc_cons"><i class="bullet">•</i>상속받는 법인에서 상당 부분의 납세재원을 충당하는 것이 바람직</p>
                <p class="desc_cons"><i class="bullet">•</i>계약자와 수익자는 법인으로 피보험자는 CEO의 형태로 종신보험, 정기보험에 가입하는 방안 추천<br> 피보험자인 CEO의 유고 시 보험회사로부터 약속된 보험금을 현금성 자산으로 지급 받음으로써 법인의 경영활동에 필요한 지금과 무관한<br> 잉여현금흐름을 발생 시킬 수 있으며, 해당 자금을 상속제 납세재원으로 활용 가능</p>
            </div>
            <div class="cons_info_area">
                <div class="box_checkpoint">
                    <p class="tit">가업승계 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt="">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">가업승계 시 높은 상속세율에 따른 세금부담을 완화를 위한 전략을 수립해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">비업무용자산(현금, 업무무관 부동산, 가지급금, 투자유가증권 등)은 가업상속에서 제외해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">가업상속공제 활용을 위한 명의신탁 차명주식을 회수해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">가업승계를 위한 효과적인 지배구조 개선 방안을 제시해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">가업승계지원제도 활용 및 사후관리 방안을 제시해야 합니다.</span></p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- //3가지 방법 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/strategy/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/strategy.scss';
</style>
<template>
    <footer data-auto-load="false">
        <div class="inner">
            <nav class="list_corp">
                <button class="btn_corp" onclick="LAYER('policy_privacy')" type="button" style="color: #24a1ff">개인정보처리방침</button>
                    <i style="margin: 0 2.5vw; color: #fff;">|</i>
                <button class="btn_corp" onclick="LAYER('policy_usege')" type="button">이용약관</button>
                    <i style="margin: 0 2.5vw; color: #fff;">|</i>
                <b class="" style="color: #fff;">감수 <span style="font-weight:100;">세무법인 세종TSI</span></b>
            </nav>
            <div class="list_help">
                <p class="tit">평  일<span>09:00~18:00</span></p>
                <div class="link_button">
                    <a class="btn_call" href="tel:1800-9440"><img class="ico" src="" data-json-path="/image/svg/phone04.svg" alt=""></a>
                    <a class="btn_blog" href="https://blog.naver.com/mkbiz_kr" target="_blank"><img src="/img/common/ico/mk_blog.png" alt="네이버 블로그 바로가기"></a>
                </div>
            </div>
            <address class="address">
                <p class="bi">매경경영지원본부</p>
                <p><span data-peoplelife="description"></span></p>
                <p class="deliberation" data-thispage="deliberation"></p>
                <p>대표이사 : <span data-peoplelife="owner"></span></p>
                <p>사업자등록번호 : <span data-peoplelife="corpNumber"></span></p>
                <p>(<span data-peoplelife="postNumber"></span>) <span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span></p>
                <p>© 2021 매경경영지원본부. All rights reserved</p>
            </address>
            <div class="floating fixed is-hidden">
                <button class="btn_cont" title="무료상담" type="button"><span>무료상담</span></button>
                <button class="btn_top mb-10" title="위로가기" type="button"><img class="ico" src="" data-json-path="/image/svg/angle-up.svg" alt=""><span style="color: #212121;">TOP</span></button>
            </div>
        </div>
    </footer>
</template>



<style lang="scss">
</style>



<script>
export default {}
</script>
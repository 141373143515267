<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <h3 class="tit_section">배당정책 </h3>
                <p class="desc_section">배당은 소유와 경영이 분리된<br><strong>주식회사에서 소유주가 이익금을<br>회수하는 주요 방법이자 절세를 위한<br>효과적인 도구</strong>이므로 이를 적극적으로<br>활용할 필요가 있습니다 </p>
            </div>
            <i class="line-wire line-x line-circle-left" style="bottom:30.6vw;right:4.4444vw;width:25vw"></i>
            <i class="line-wire line-y" style="bottom:-33vw;right:4.4444vw;height:64vw"></i>
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(191)" type="button"><span>배당정책 상담신청</span></button>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        <div class="inner">
            <h3 class="tit_section">배당정책 컨설팅 사례</h3>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/R-wwYrF-4b0" data-title="중소기업의 주식배당" data-type="youtube" type="button" title="영상재생">
                <i class="ico_play2"><img src="" data-json-path="/image/svg/play01.svg" alt=""></i>
                <img class="thumb" src="https://img.youtube.com/vi/R-wwYrF-4b0/maxresdefault.jpg" alt="스크린샷">
            </button>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_client1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>가업승계 전 소액주주의 지분 정리와 자녀, 손자들 명의로 주식 이동을 희망합니다.</p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>대표이사가 보유한 주식의 일부를 비과세 범위내에서 3명의 손주들에게 증여하였으며 자녀들과 손주들의 주식양수대금은 차등배당으로 해결하였습니다.</li>
                            <li>주식가치평가를 통해 시가 산정 후 주식양수도 계약을 체결하였습니다.</li>
                            <li>매매당사자의 관계가 특수관계자 여부에 따라 적합한 범위 내 주식 가격 인하가 가능했습니다.</li>
                            <li>지원본부 소속의 전문가 그룹인 세무사, 변호사와 팀을 이루어 증여세, 양도세, 차등배당 소득세 신고 및 납부하였습니다.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <i class="line-wire line-y" style="top:0;right:4.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;right:4.4444vw;width:12vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;left:4.4444vw;width:12vw"></i>
        <i class="line-wire line-y" style="top:12.5vw;left:4.4444vw;height:44.5vw"></i>
        <i class="line-wire line-x" style="top:57vw;left:4.4444vw;width:81vw"></i>
        <i class="line-wire line-y" style="top:57vw;right:14.4444vw;height:400vw"></i>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 배당정책 효과 -->
    <section id="effect" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">배당정책 효과</h3>
            <div class="cons_info_area area1">
                <p class="desc_cons">배당은 소유와 경영이 분리된 주식회사에서 <strong>소유주가 이익금을 회수하는 주요 방법이자 절세를 위한 효과적인 도구</strong>이므로 이를 적극적으로 활용할 필요가 있습니다.</p>
                <ul class="list_box">
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust1.png'" alt="">
                        <p class="tit">소득분산 및<br>자금출처 확보</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust2.png'" alt="">
                        <p class="tit">절세효과</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust3.png'" alt="">
                        <p class="tit">중간배당 가능</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust4.png'" alt="">
                        <p class="tit">가업승계와<br> 상속플랜으로<br> 연결 가능 </p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust5.png'" alt="">
                        <p class="tit">가지급금정리<br>솔루션으로 적용 가능</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust6.png'" alt="">
                        <p class="tit">비상장주식의<br>주가관리 가능</p>
                    </li>
                </ul>
            </div>
        </div>
        <i class="line-wire line-y" style="top:0;right:14.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;right:14.4444vw;width:12vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;left:4.4444vw;width:22vw"></i>
        <i class="line-wire line-y" style="top:12.5vw;left:4.4444vw;height:66.5vw"></i>
        <i class="line-wire line-x" style="top:79vw;left:4.4444vw;width:81vw"></i>
        <i class="line-wire line-y" style="top:79vw;right:14.4444vw;height:63vw"></i>
        <i class="line-wire line-x" style="top:142vw;right:14.4444vw;width:81vw"></i>
        <i class="line-wire line-y" style="top:142vw;left:4.4444vw;height:73vw"></i>
        <i class="line-wire line-x" style="top:215vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:215vw;right:4.4444vw;height:40vw"></i>
    </section>
    <!-- //배당정책 효과 -->

    <!-- 종류 및 활용 -->
    <section id="howto" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">배당정책 종류 및 활용</h3>
            <div class="cons_info_area area1">
                <p class="tit_cons mb-50 pl-50">1. 적극적인 배당정책 종류 </p>
                <ul class="list_info">
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust1.png'" alt="">
                        <p class="tit">차등배당<br>플랜</p>
                    </li>
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust2.png'" alt="">
                        <p class="tit">중간배당<br>플랜</p>
                    </li>
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust3.png'" alt="">
                        <p class="tit">Step-UP배당<br>플랜</p>
                    </li>
                    <li class="item_info">
                        <img :src="imgPath+'howto_illust4.png'" alt="">
                        <p class="tit">명의신탁해지<br>배당</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area">
                <p class="tit_cons mb-50 pl-50">2. 배당정책 활용 목적</p>
                <ul class="list_graph">
                    <li class="item_graph">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'howto_graph1.png'" alt="">
                        </div>
                        <p class="tit">법인자금 회수</p>
                    </li>
                    <li class="item_graph">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'howto_graph2.png'" alt="">
                        </div>
                        <p class="tit">가업승계를 위한<br> 사전준비</p>
                    </li>
                    <li class="item_graph">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'howto_graph3.png'" alt="">
                        </div>
                        <p class="tit">상속 및 증여세,<br> 양도소득세<br> 절세전략의 일환</p>
                    </li>
                    <li class="item_graph">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'howto_graph4.png'" alt="">
                        </div>
                        <p class="tit">주가관리 목적</p>
                    </li>
                    <li class="item_graph">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'howto_graph5.png'" alt="">
                        </div>
                        <p class="tit">대표의<br> 경영리스크 분산</p>
                    </li>
                    <li class="item_graph">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'howto_graph6.png'" alt="">
                        </div>
                        <p class="tit">주주의 <br> 배당압력 해소</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area mb-65">
                <div class="box_checkpoint">
                    <p class="tit">배당정책 활용 시 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt="">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">배당정책 활용 시 법에서 정한 요건을 충족하고 있는지 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">적법한 규정과 절차 준수 여부를 확인해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">지분설계, 절세, 배당가능금액 등의 철저한 분석 여부를 확인해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">법인정관에 배당 관련 사항 명시 여부를 확인해야 합니다.</span></p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                </div>
            </div>
        </div>
        <i class="line-wire line-y" style="top:0;right:4.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;right:4.4444vw;width:16vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;left:4.4444vw;width:16vw"></i>
        <i class="line-wire line-y" style="top:12.5vw;left:4.4444vw;height:39.5vw"></i>
        <i class="line-wire line-x" style="top:52vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:52vw;right:4.4444vw;height:60.5vw"></i>
        <i class="line-wire line-x" style="top:112.5vw;right:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:112.5vw;left:4.4444vw;height:51.5vw"></i>
        <i class="line-wire line-x" style="top:164vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:164vw;right:4.4444vw;height:31.5vw"></i>
        <i class="line-wire line-x line-circle-left" style="top:195vw;right:4.4444vw;width:90.5vw"></i>
    </section>
    <!-- //종류 및 활용 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/policy/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/policy.scss';
</style>
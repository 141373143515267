<template>
<!-- main -->
<main id="container">

    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="b">
       <div class="inner">
            <h2>차명주식회수</h2>
            <p class="desc_section">
                실제 소유자가 다른 사람의 명의를 빌려<br>
                보유한 주식을 뜻합니다. <br>
                <b>차명주식을 회수하지 않는다면</b> 그 주식에 대한<br>
                <b>재산권 분쟁, 회사의 경영권 간섭</b> 등 여러 가지 <br>
                문제가 발생할 수 있습니다. 
            </p>
            <div class="div-button-consult">
                <button class="btn_cont" onclick="LAYER_CONT(189)" type="button">
                    <span>차명주식회수 상담신청</span>
                    <i></i>
                </button>
            </div>
       </div>
    </section>
    <!-- /메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="b">
        <h3 class="tit_section">차명주식회수 컨설팅 사례</h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/kG9hyBjbuf4" data-title="차명주식의 문제점 및 효율적인 회수방법" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/kG9hyBjbuf4/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="inner">
            <div class="list_talk">
                <div class="item_talk">
                    <img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
                    <div class="tooltip">
                        <p class="tit"><strong>문제점</strong></p>
                        <p>명의수탁자는 작은 회사로 시작되었기 때문에 관심이 없다가 세월이 지나 회사의 규모가 커지고 주식의 가치가 높아진 것을 보고 소유권 주장하였습니다.</p>
                    </div>
                </div>
                <div class="item_solution">
                    <p class="item_tit"><strong>해결방법</strong></p>
                    <div class="item_answer">
                        <p> 차명주식 신탁 당시 작성된 명의신탁 약정서를 찾아내어 주주확인 소송을 진행하고 관할 세부서를 통해 명의신탁 해지를 접수하여 완료하였습니다. </p>
                        <p> 지원본부 소속의 세무사와 변호사의 발빠른 대응으로 주주확인 소송과 명의신탁 해지 절차로 마무리가 되었습니다. </p>
                    </div>
                </div>
                <div class="item_conclusion">
                    <p class="item_tit"><strong>결론</strong></p>
                    <div class="item_answer">
                        <p>의뢰인과의 정확한 이해와 빠른 판단 그리고 지원본부 소속의 전문가 그룹과의 발빠른 대응으로 <b>안정적으로 신탁</b>을 회수할 수 있었습니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /컨설팅 사례 -->

    <!-- 문제점 -->
    <section id="problem">
        <h3 class="tit_section">차명주식회수 문제점</h3>
        <div class="inner">
            <div class="problem_list">
                <div class="problem_item isAppear">
                    <p><strong>명의수탁자의 신변의 변화에 따른 문제가 발생할 수 있다.</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="명의수탁자 사망 시" :src="imgPath+'collect_b_problem_img1.jpg'">
                        </div>
                        <div class="text">
                            <span class="text_tit">명의수탁자 <b>사망 시,</b></span>
                            <div class="text_cnt">해당 차명주식을 상속받은 배우자 또는 자녀가 소유권을 주장할 수 있습니다. </div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="명의수탁자 신용 문제시" :src="imgPath+'collect_b_problem_img2.jpg'">
                        </div>
                        <div class="text">
                            <span class="text_tit">명의수탁자 <b>신용 문제 시,</b></span>
                            <div class="text_cnt">차명주식을 압류 당할 수도 있으며, 명의수탁자가 변심하여 소유권을 주장할 수도 있습니다.</div>
                        </div>
                    </div>
                </div>
                <div class="problem_item isAppear">
                    <p><strong>차명주식의 명의자와 실제소유자가 다르기 때문에 문제가 발생할 수 있다.</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="" :src="imgPath+'collect_b_problem_img3.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">실제소유자인 명의신탁자의 가업상속계획, 지분이동계획 등에 제약이 있을 수 있습니다. 또한, 이러한 제약 때문에 차명주식을 회수하는데 드는 거래비용 (증여세, 양도소득세, 간주취득세 등 세금부담)이 발생할 수도 있습니다.</div>
                        </div>
                    </div>
                </div>
                <div class="problem_item isAppear">
                    <p><strong>차명주식의 명의자와 실제소유자가 다르기 때문에 문제가 발생할 수 있다.</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="" :src="imgPath+'collect_b_problem_img4.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">
                                과세당국은 <b>차명주식의 편법증여나 주가조작을 통한 불공정거래에 차명주식을 이용하여 탈세를 한 경우</b> 끝까지 추적하여 적발하고 있으며, <b>세금 추징 또한 엄격</b>하게 하고 있습니다. <b>차명주식 보유로 인해 세무조사 대상</b>이 되기도 합니다.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / 문제점 -->

    <!-- 회수방법 -->
    <section id="collect">
        <h3 class="tit_section">회수 방법</h3>
        <div class="inner">
            <div class="collect_list">
                <div class="collect_item isAppear">
                    <p><strong>차명주식을 명의수탁자로부터 증여하거나 양도하는 방법 </strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="명의수탁자 사망 시" :src="imgPath+'collect_b_collect_img1.jpg'">
                        </div>
                        <div class="text">
                            <span class="text_tit">주식 <b>증여(수증) </b>활용</span>
                            <div class="text_cnt">실제 소유자인 명의신탁자가 증여세를 납부하여야 합니다.</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="명의수탁자 신용 문제시" :src="imgPath+'collect_b_collect_img2.jpg'">
                        </div>
                        <div class="text">
                            <span class="text_tit">명의수탁자 <b>신용 문제시,</b></span>
                            <div class="text_cnt">실제소유자인 명의신탁자가 주식 양수도 대금을 명의수탁자에게 지급하여야 하고, 명의수탁자는 양도소득세를 납부합니다.</div>
                        </div>
                    </div>
                </div>
                <div class="collect_item isAppear">
                    <p><strong>차명주식 실제소유자 확인제도를 이용하는 방법</strong></p>
                    <div class="process_item">
                        <div class="process">
                            <p class="text_tit">차명주식 실제소유자 확인제도 <b>신청 절차</b></p>
                            <ol>
                                <li>
                                   <div class="li_inner">
                                        <div class="thumb">
                                            <img alt="신청요건 확인 및 구비서류 준비" :src="imgPath+'collect_b_collect_process_1.jpg'">
                                        </div>
                                        <p>
                                            <span>신청요건 확인 및<br> 구비서류 준비</span>
                                        </p>
                                   </div>
                                </li>
                                <li>
                                    <div class="li_inner">
                                        <div class="thumb">
                                            <img alt="확인신청서 제출(신청인 주소지 관할  세무서)" :src="imgPath+'collect_b_collect_process_2.jpg'">
                                        </div>
                                        <p>
                                            <span>확인신청서 제출<br>(신청인 주소지 관할  세무서)</span>
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div class="li_inner">
                                        <div class="thumb">
                                            <img alt="국세청 검증 자문위원회 자문" :src="imgPath+'collect_b_collect_process_4.jpg'">
                                        </div>
                                        <p><span>국세청 검증<br> 자문위원회 자문</span></p>
                                    </div>
                                </li>
                                <li>
                                    <div class="li_inner">
                                        <div class="thumb">
                                            <img alt="결과통지 및 사후관리" :src="imgPath+'collect_b_collect_process_3.jpg'">
                                        </div>
                                        <p><span>결과통지 및<br> 사후관리</span></p>
                                    </div>
                                </li>
                            </ol>
                        </div>
                        <div class="text">
                            <div class="text_cnt"> 차명주식 실제소유자 확인제도는 <b>간소화 된 서류를 통해</b> 명의신탁주식을 <b>실제소유자에게 환원</b>할 수 있도록 과세 당국이 지원하는 제도입니다.</div>
                        </div>
                    </div>
                </div>
                <div class="collect_item isAppear">
                    <p><strong>차명주식의 실제소유자인 명의신탁자가 과세당국에 명의신탁 해지신청을 하는 방법</strong></p>
                    <div class="process_item">
                        <div class="process">
                            <p class="text_tit">차명주식이라는 사실을 입증하기 위한<br> 명의신탁자 <b>증빙 제출 서류</b></p>
                            <ul>
                                <li>
                                    <div class="thumb">
                                        <img alt="명의신탁주식 실소유자 확인서 " :src="imgPath+'collect_b_collect_docu_1.png'">
                                    </div>
                                    <p>
                                        <span>명의신탁주식<br> 실소유자 확인서 </span>
                                    </p>
                                </li>
                                <li>
                                    <div class="thumb">
                                        <img alt="중소기업 등 기준검토표" :src="imgPath+'collect_b_collect_docu_2.png'">
                                    </div>
                                    <p>
                                        <span>중소기업 등<br> 기준검토표</span>
                                    </p>
                                </li>
                                <li>
                                    <div class="thumb">
                                        <img alt="명의신탁 경위에 대한 진술서" :src="imgPath+'collect_b_collect_docu_3.png'">
                                    </div>
                                    <p><span>명의신탁 경위에<br> 대한 진술서</span></p>
                                </li>
                                <li>
                                    <div class="thumb">
                                        <img alt="결과통지 및 사후관리" :src="imgPath+'collect_b_collect_docu_4.png'">
                                    </div>
                                    <p><span>결과통지 및<br> 사후관리</span></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="arrow_obj isAppear">
                        <div class="text_box">
                            <strong>과세당국에 제출</strong> 
                            <span>* 명의신탁 당시에 증여세 발생할 수 있음</span>
                            <img alt="" :src="imgPath+'collect_b_collect_arrow.png'">
                        </div>
                        <img alt="" :src="imgPath+'collect_b_collect_arrow_logo.png'" class="arrow_logo">
                        <img alt="" :src="imgPath+'collect_b_collect_arrow_shadow.png'" class="arrow_shadow">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / 회수방법 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess_b data-type="1" data-list-count="7" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/collection_b/'
        }
    },
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/collection_b.scss';
</style>
<template>
    <div id="wrap" data-homepage="true" data-page-type="corp" data-target-device="mo" data-grid-width="auto" data-sheet-name="매경경영지원본부MO" style="visibility:hidden;opacity:0">
        <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
        <Header/>
        <router-view/>
        <Footer/>
    </div>
</template>



<style lang="scss">
// 전역 SCSS 파일은 vue.config.js 또는 common.scss 추가 
</style>



<script>
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default { 
    name: 'wrap',
    components: {
        Header,
        Footer,
    },
    mounted() {},  
}    
</script>

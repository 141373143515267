<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <h3 class="tit_section">지배구조개선</h3>
                <p class="desc_section">비상장기업 지배구조란 기업의 소유권<br>구조에 큰 변화가 발생하는 내용 등<br><strong>경영권에 대한 내용들이 대표적인<br> 지배구조와 관련된 오너 CEO<br>리스크</strong>들입니다. </p>
            </div>
            <i class="line-wire line-x line-circle-left line-01" style="bottom:23vw;right:4.4444vw;width:44vw"></i>
            <i class="line-wire line-y line-02" style="bottom:-27vw;right:4.4444vw;height:50vw"></i>
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(239)" type="button"><span>지배구조개선 상담신청</span></button>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 기업지배구조 -->
    <section id="optimum" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">최적의 기업지배구조</h3>
            <div class="cons_info_area area1">
                <ul class="list">
                    <li class="item">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'optimum_item1.png'" alt="">
                        </div>
                        <p class="tit">자본구조</p>
                    </li>
                    <li class="item">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'optimum_item2.png'" alt="">
                        </div>
                        <p class="tit">임원 및 회사의<br> 기관구성</p>
                    </li>
                    <li class="item">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'optimum_item3.png'" alt="">
                        </div>
                        <p class="tit">주주구성</p>
                    </li>
                    <li class="item">
                        <div class="box_illust">
                            <img class="img-fluid" :src="imgPath+'optimum_item4.png'" alt="">
                        </div>
                        <p class="tit">이익금회수방안</p>
                    </li>
                </ul>
                <img class="bg" :src="imgPath+'optimum_bg.png'" alt="">
                <p class="desc_cons mb-70 color-0aaeaf word-keep">
                    이 과정에서 비상장사의 특성상 외부 이해관계자는 적으므로 소액주주 보호, 내부 감시 및 통제 등에 대한 리스크 요인보다는 세금과 관련된 리스크 요인이 더 큰 비중을 차지하므로 절세측면이 중요하게 고려되어야 합니다.
                </p>
                <div class="desc_cons fw-bold word-keep">
                    <p>TIPS</p>
                    <p>가업승계를 위해서는 새롭게 시작하는 성장성 있는 사업을 자녀 명의의 법인으로 하는 것이 유리한 이유는 추후 가업승계 시 부모님의 지분을 인수할 때 자녀명의의 법인을 활용하는 것이 절세나 자금조달/활용 측면에서 유리한 점이 있습니다. </p>
                </div>                
            </div>            
            <div class="cons_info_area">
                <div class="box_checkpoint">
                    <p class="tit">기업지배구조개선 처리 시 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt="">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">세금과 비용을 절감할 수 있느냐가 중요합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">합법적인 절차와 규정을 준수해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">신설회사 분할에 대한 국세청 소명 요구 대응 전략에 대한 수립 여부를 확인해야 합니다.</span></p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                </div>
            </div>
        </div>
        <i class="line-wire line-y line-03" style="top:0;right:4.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x line-04" style="top:12.5vw;right:4.4444vw;width:16vw"></i>
        <i class="line-wire line-x line-05" style="top:12.5vw;left:4.4444vw;width:16vw"></i>
        <i class="line-wire line-y line-06" style="top:12.5vw;left:4.4444vw;height:27.5vw"></i>
        <i class="line-wire line-x line-07" style="top:40vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y line-08" style="top:40vw;right:4.4444vw;height:43.8vw"></i>
        <i class="line-wire line-x line-09" style="top:83vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y line-10" style="top:83vw;left:4.4444vw;height:140vw"></i>
        <i class="line-wire line-x line-11" style="top:223vw;left:4.4444vw;width:81vw"></i>
        <i class="line-wire line-y line-12" style="top:223vw;right:14.4444vw;height:140vw"></i>
    </section>
    <!-- //기업지배구조 -->

    <!-- 기대효과 -->
    <section id="effect">
        <div class="inner">
            <h3 class="tit_section">개선 이유 및 기대효과</h3>
            <div class="cons_info_area area1">
                <p class="tit_cons mb-50 ml-50 pl-50"><span class="bullet">1.</span>지주회사를 통한 기업지배구조 개선이 필요한 이유</p>
                <ul class="list_box pt-10">
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust1.png'" alt="">
                        <p class="tit">가업승계를 위해 법인의<br> 지배구조개선이 필요</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust2.png'" alt="">
                        <p class="tit">비상장주식 가치가 높아 <br> 가업승계가 어려운 이유</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust3.png'" alt="">
                        <p class="tit">자회사를 취득해야 하는 경우</p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area area2 mb-65">
                <p class="tit_cons mb-50 ml-50 pl-50"><span class="bullet">2.</span>기업지배구조개선을 위한 지주회사 <br> 기대효과</p>
                <ul class="list_box pt-10">
                    <li class="item_box">
                        <img class="illust" src="/img/consulting/restructure/effect_illust4.png" alt="">
                        <p class="tit">대주주 지분율 증가로 인해 기업의 지배력 강화 가능</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" src="/img/consulting/restructure/effect_illust5.png" alt="">
                        <p class="tit">경영권 방어 가능</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" src="/img/consulting/restructure/effect_illust6.png" alt="">
                        <p class="tit">세 부담을 줄인<br> 성공적인 가업승계 가능</p>
                    </li>
                </ul>
            </div>
        </div>
        <i class="line-wire line-y line-13" style="top:0;right:14.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x line-14" style="top:12.5vw;right:14.4444vw;width:6vw"></i>
        <i class="line-wire line-x line-15" style="top:12.5vw;left:4.4444vw;width:16vw"></i>
        <i class="line-wire line-y line-16" style="top:12.5vw;left:4.4444vw;height:53.5vw"></i>
        <i class="line-wire line-x line-17" style="top:66vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y line-18" style="top:66vw;right:4.4444vw;height:61.8vw"></i>
        <i class="line-wire line-x line-19" style="top:127vw;right:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y line-20" style="top:127vw;left:4.4444vw;height:87.8vw"></i>
        <i class="line-wire line-x line-21" style="top:214vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y line-22" style="top:214vw;right:4.4444vw;height:65.8vw"></i>
        <i class="line-wire line-x line-23" style="top:279vw;right:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y line-circle-bottom line-24" style="top:279vw;left:4.4444vw;height:65vw"></i>
    </section>
    <!-- //기대효과 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="3" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/restructure/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/restructure.scss';
</style>
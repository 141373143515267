<template>
<!-- main -->
<main id="container">
   <!-- 메인비주얼 -->
    <section id="visual" class="isAppear">
        <div class="inner">
            <h3 class="tit_section"> 세무법인 세종TSI는<br> 매경경영지원본부와 제휴된 업체로 <br> 법인 및 고액자산가들에 대한<br> 경영&자산관리 컨설팅을<br> 제공하고 있는 세무법인 입니다. </h3>
        </div>
        <img class="bg" :src="imgPath+'visual_bg.jpg'" alt="">
    </section>
    <!-- //메인비주얼 -->


    <!-- 인물정보 -->
    <section id="networkList">
        <div class="inner">
            <p class="tit_section"><strong>세무법인 세종TSI</strong>절세 전략 연구 개발(상속, 증여, 법인세 등)</p>
            <div class="ready">
                <div class="hourglass">
                    <div class="tit"><strong>프로필 준비중</strong> 입니다.</div>
                    <img class="ico" :src="imgPath+'networkList_hourglass.png'" alt="">
                    <i class="ico_shadow"></i>
                </div>
                <div class="particle_circle" style="top:8vw;right:20vw"></div>
                <div class="particle_circle" style="bottom:15vw;left:12vw"></div>
                <div class="particle_circle-border" style="bottom:23vw;left:6vw"></div>
            </div>            
            <ul class="list_member">
                <li class="item_member">
                    <label class="inner_item">
                        <p class="tit" data-type="">
                            <span class="role"></span>
                            <span class="name"></span>
                        </p>
                        <button class="btn_more" type="button">
                            <img class="ico" src="" data-json-path="/image/svg/plus03.svg" alt="">
                        </button>
                        <div class="thumb">
                            <img src="" alt="">
                        </div>
                    </label>
                </li>
            </ul>
        </div>
    </section>
   <!-- //인물정보 -->
</main ><!-- /main -->
<!-- //container -->
</template>



<script>
    export default {
        data(){
            return {
                imgPath: '/img/company/tsi/'
            }
        }
    }
</script>



<style scoped lang="scss">
    @import '@/assets/scss/company/tsi.scss';
</style>
<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">100년 기업으로 가는 길.<br> 중소기업 CEO 여러분을<br> 초청합니다.</h3>
        </div>
        <img class="bg" :src="imgPath+'visual_bg.jpg'" alt="">
    </section>
    <!-- //메인비주얼 -->

    <!-- 최신뉴스 -->
    <section id="topNews" class="isAppear">        
        <div class="headline">
            <img class="tit" src="/img/main/seminar_headline_tit.png" alt="">
            <i class="particle_circle" style="top:16.53vw;left:7.8vw"></i>            
            <i class="particle_circle-diagonal" style="top:9.7vw;left:-9.7vw;z-index:-1"></i>
            <p class="seminar_tit"></p>
        </div>
        <div class="position-relativ">
            <ul class="swiper">
                <li class="item_news">
                    <a class="link_news hidden">
                        <p class="state"></p>
                        <p class="tit" data-text="/0/title" data-clamp="1"></p>
                        <p class="local" data-text="/0/content" data-clamp="1"></p>
                        <p class="date"></p>
                        <p class="address" data-html="/0/location" data-clamp="2"></p>
                    </a>
                </li>
            </ul>
            <!-- Add Pagination -->
            <div class="paging swiper-pagination hidden"></div>            
        </div>
        <div class="bgc"></div>
    </section>
    <!-- //최신뉴스 -->

    <!-- 전체뉴스 -->
    <section id="allNews" class="isAppear">
        <div class="tab">
            <button class="btn_tab" type="button">2023</button>
            <button class="btn_tab" type="button">2022</button>
            <button class="btn_tab" type="button">2021</button>
            <button class="btn_tab" type="button">2020</button>
            <button class="btn_tab" type="button">2019</button>
            <button class="btn_tab" type="button">2018</button>
            <button class="btn_tab" type="button">2017</button>
            <!-- <p class="count">세미나 <span class="num">5</span>건</p> -->
        </div>
        <ul class="list_news">
            <li class="item_news">
                <a class="link_news">
                    <div class="box_thumb"></div>
                    <p class="tit" data-text="title" data-clamp="1"></p>
                    <p class="local" data-text="/eachSeminar/content" data-clamp="1"></p>
                    <p class="date" data-text="/eachSeminar/closeTime" data-clamp="1"></p>
                    <p class="address" data-html="/eachSeminar/location" data-clamp="2"></p>
                </a>
            </li>
        </ul>
    </section>
    <!-- //전체뉴스 -->
</main ><!-- /main -->
</template>



<script>
    export default {
        data(){
            return {
                imgPath: '/img/seminar/seminar/'
            }
        }
    }
</script>



<style scoped lang="scss">
    @import '@/assets/scss/seminar/seminar.scss';
</style>
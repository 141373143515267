<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="isAppear">
        <h3 class="tit_section">매경경영지원본부는<br> 법인컨설팅 분야에서 매번 뛰어난<br> 경제력을 입증하고 있습니다.</h3>
        <img class="bg" :src="imgPath+'visual_bg.jpg'" alt="">
        <p class="desc_section">대한민국 중소ㆍ중견기업에 특화된 서비스와<br>고도화 된 전문성을 만나보세요! </p>
    </section>
    <!-- //메인비주얼 -->

    <!-- 지원안내 -->
    <section id="support">
        <ul class="list_support">
            <li class="item_support item_headline isAppear">
                <div class="box_thumb">
                    <p class="tit">구체적인 실행지원과<br>사후 책임관리</p>
                    <img :src="imgPath+'support_thumb1.jpg'" alt="">
                </div>
                <p class="desc">고민의 시작부터 사후관리까지 전문가로 구성된<br>One Team이 One-Stop-Service를 책임집니다. </p>
            </li>
            <li class="item_support isAppear">
                <div class="box_thumb">
                    <img :src="imgPath+'support_thumb2.jpg'" alt="">
                    <i class="particle_circle" style="top:-3vw;left:22vw"></i>
                    <i class="particle_circle-border" style="top:2vw;left:12vw"></i>
                </div>
                <div class="box_txt" style="margin-top:-26vw">
                    <p class="tit">경영권 강화를 위한<br>기업구조의 기틀 마련</p>
                    <p class="desc">변화되는 법과 제도에 대한 고객사의 상황에 맞는<br>기업구조 마련을 위해 도움을 드리고 있습니다.</p>
                </div>
            </li>
            <li class="item_support item_model isAppear">
                <div class="box_thumb">
                    <img :src="imgPath+'support_thumb3.jpg'" alt="">
                </div>
                <div class="box_txt">
                    <p class="tit">기업 오너에 대한<br>현실적인 성과 보상</p>
                    <p class="desc">급여, 상여, 배당, 퇴직 프로그램<br>등 현실적인 프로그램을 보유하고<br>있어실질적인 도움을 드리고<br>있습니다.</p>
                    <i class="particle_circle-diagonal" style="width:40vw;height:40vw;top:-10vw;left:22vw;z-index:-1"></i>
                </div>
            </li>
            <li class="item_support isAppear">
                <div class="box_thumb">
                    <img :src="imgPath+'support_thumb4.jpg'" alt="">
                </div>
                <div class="box_txt" style="margin-top:-34vw">
                    <p class="tit">불필요한 비용구조와<br> 복잡성을 줄여<br> 성과를 향상</p>
                    <p class="desc">지원본부는 수 많은 기업사례를 기반으로<br>업종별, 규모별로 최적의 대안을 제시합니다.</p>
                </div>
            </li>
            <li class="item_support isAppear">
                <div class="box_thumb">
                    <img :src="imgPath+'support_thumb5.jpg'" alt="">
                    <i class="particle_circle" style="top:5vw;right:15vw"></i>
                    <i class="particle_circle-border" style="top:10vw;right:22vw"></i>
                </div>
                <div class="box_txt" style="margin-top:-26vw">
                    <p class="tit">계속 기업을 위한<br>승계구도의 완성</p>
                    <p class="desc">지원본부는 기업의 최종 목표인 계속기업을 위한<br>고민을 함께하고 있습니다.</p>
                </div>
            </li>
        </ul>
    </section>
    <!-- //지원안내 -->

    <!-- 오시는길 -->
    <section id="map" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">오시는길</h3>
            <div class="info">
                <p><i class="bullet">•</i>주소 : <span data-peoplelife="address"></span> <span data-peoplelife="addressDetail"></span></p>
                <p><i class="bullet">•</i>전화번호 : 1800-9440</p>
                <p><i class="bullet">•</i>FAX : 02-6909-2130</p>
            </div>
            <div class="btn_area">
                <button class="btn_map" type="button"><img class="ico" src="/img/common/ico/map-marker.svg" alt=""><span>지도보기</span></button>
            </div>            
        </div>
    </section>
    <!-- //오시는길 -->
</main ><!-- /main -->
<!-- //container -->
</template>



<script>
    export default {
        data(){
            return {
                imgPath: '/img/company/introduce/'
            }
        }
    }
</script>



<style scoped lang="scss">
    @import '@/assets/scss/company/introduce.scss';
</style>
<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">어려운 법인컨설팅에 대해 쉽게<br>풀어드리는 매경경영지원본부의<br>전문가 영상을 확인하세요! </h3>
        </div>
        <img class="bg" :src="imgPath+'visual_bg.jpg'" alt="">
    </section>
    <!-- //메인비주얼 -->

    <!-- 전체뉴스 -->
    <section id="allNews" class="isAppear">
        <div class="tab">
            <button class="btn_tab is-active" type="button" data-value=""><span>전체</span></button>
            <button class="btn_tab" type="button" data-value="자기주식"><span>자기주식</span></button>
            <button class="btn_tab" type="button" data-value="(가족법인활용)자산이전"><span>(가족법인활용)자산이전</span></button>
            <button class="btn_tab" type="button" data-value="지배구조개선"><span>지배구조개선</span></button>
            <button class="btn_tab" type="button" data-value="가업승계전략"><span>가업승계전략</span></button>
            <button class="btn_tab" type="button" data-value="차명주식회수"><span>차명주식회수</span></button>
            <button class="btn_tab" type="button" data-value="가지급금정리"><span>가지급금정리</span></button>
            <button class="btn_tab" type="button" data-value="배당정책"><span>배당정책</span></button>
            <button class="btn_tab" type="button" data-value="개인(임대)사업자법인전환"><span>개인(임대)사업자법인전환</span></button>
            <button class="btn_tab" type="button" data-value="인증/정책자금/경정청구"><span>인증/정책자금/경정청구</span></button>
            <button class="btn_tab" type="button" data-value="병/의원컨설팅"><span>병/의원컨설팅</span></button>
            <button class="btn_tab" type="button" data-value="이익소각"><span>이익소각</span></button>
            <button class="btn_tab" type="button" data-value="가치평가"><span>가치평가</span></button>
            <button class="btn_tab" type="button" data-value="기타"><span>기타</span></button>
        </div>
        <div class="list_wrap">
            <ul class="list_news">
                <li class="item_news">
                    <button class="btn_news" type="button">
                        <em></em>
                        <i class="ico_play"></i>
                        <div class="thumb"></div>
                    </button>
                    <div class="info_inner">
                        <div class="new_info">
                            <p class="date" data-text="writeDate"></p>
                            <p class="count" data-text="viewCnt">1</p>
                        </div>
                        <p class="tit" data-text="title" data-clamp="2"></p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="btn_area">
            <button class="btn_more" type="button">더 보기</button>
        </div>
    </section>
    <!-- 전체뉴스 -->
</main ><!-- /main -->
</template>



<script>
    export default {
        data(){
            return {
                imgPath: '/img/media/video/'
            }
        }
    }
</script>


<style scoped lang="scss">
    @import '@/assets/scss/media/video.scss';
</style>
<template>
<!-- main -->
<main id="container">

	<!-- 메인 -->
	<section id="visual" class="isAppear" data-type="b">
		<div class="inner">
				<h2>인증/ 정부지원사업<br>(정책자금)/ 경정청구</h2>
				<p class="desc_section">
					기업의 여러 이해관계 기관이 <b>기업에 주는 우대제도,</b><br> 
					정부가 기업에게 지원할 목적으로<br>
					<b>정책자금을 집행하는 사업,</b> 사업자가 세금을 더 납부했거나<br>
					잘 못 납부할 경우 <b>국세청에 돌려달라고 요청하는 제도</b>에<br>
					대해 알아봅니다.
				</p>
				<div class="div-button-consult">
					<button class="btn_cont" onclick="LAYER_CONT(247)" type="button">
						<span>인증/정책자금/경정청구 상담신청</span>
						<i></i>
					</button>
				</div>
		</div>
	</section>
	<!-- /메인 -->

	<!-- 인증제도 -->
	<section id="system" class="section">
		<div class="inner">
			<h3 class="tit_section">인증제도</h3>
			<div class="box isAppear">
				<strong>인증의 정의</strong>
				<p>기업활동을 영위하는 과정에서 여러 이해관계 기관의<br>공식적인 인정요인(R&D, 기술, 사업성 등)을 입증하고<br>사실관계를 확인하여 기업에 주는 우대제도</p>
			</div>
			<div class="box">
				<strong>인증의 범위</strong>
				<ul>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/certify_b/system_img_1_1.png" alt="">
						</div>
						<div class="info">
							<strong>소극적 범위</strong>
							<p>
								<span>개별 기관의 업무상 필요한 거래 절차 및 확인 절차</span>
								<span>각종 기관 및 산하기관의 인증서</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/certify_b/system_img_1_2.png" alt="">
						</div>
						<div class="info">
							<strong>적극적 범위</strong>
							<p>
								<span>기업의 성장을 위한 상호연계성 확인 및 연결 기반 마련</span>
								<span>인증을 활용한 사업규모/범위 확대 및 신사업 모색</span>
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="box">
				<strong>인증제도 활용 시 장점</strong>
				<div class="img_area">
					<img class="isAppear" src="/img/consulting/certify_b/system_img_2_1.png" alt="">
					<img class="isAppear" src="/img/consulting/certify_b/system_img_2_2.png" alt="">
					<img class="isAppear" src="/img/consulting/certify_b/system_img_2_3.png" alt="">
				</div>
			</div>
			<div class="box">
				<strong>인증제도 활용 시 장점</strong>
				<ol>
					<li class="isAppear">
						<span>기업부설 연구소</span>
						<img src="/img/consulting/certify_b/system_img_3_1.png" alt="">
					</li>
					<li class="isAppear">
						<span>메인/이노비즈</span>
						<img src="/img/consulting/certify_b/system_img_3_2.png" alt="">
					</li>
					<li class="isAppear">
						<span>벤처기업</span>
						<img src="/img/consulting/certify_b/system_img_3_3.png" alt="">
					</li>
					<li class="isAppear">
						<span>뿌리기업확인</span>
						<img src="/img/consulting/certify_b/system_img_3_4.png" alt="">
					</li>
					<li class="isAppear">
						<span>병역특례기업</span>
						<img src="/img/consulting/certify_b/system_img_3_5.png" alt="">
					</li>
					<li class="isAppear">
						<span>정부R&D사업</span>
						<img src="/img/consulting/certify_b/system_img_3_6.png" alt="">
					</li>
				</ol>
			</div>
		</div>
	</section>
	<!-- / 인증제도 -->

	<!-- 정부지원사업(정책자금) -->
	<section id="funds" class="section">
		<div class="inner">
			<h3 class="tit_section">정부지원사업(정책자금)</h3>
			<div class="box isAppear">
				<strong>정부지원사업(정책자금)의 정의</strong>
				<p>정부가 기업이나 단체, 개인을 지원 할 목적으로<br>정책자금을 집행하는 사업</p>
			</div>
			<div class="box">
				<strong>정책자금의 종류</strong>
				<ul>
					<li class="isAppear">
						<div class="info">
							<strong>운전자금</strong>
							<p>
								<span>기업에 필요한 <b>원자재의 구매, 생산, 판매 활동</b>에 소요되는 자금을 저금리로 대출해주는 자금</span>
								<span>중소벤처기업진흥공단, 소상공인시장진흥공단, 기술보증기금, 신용보증기금, 신용보증재단 등</span>
							</p>
						</div>
						<img src="/img/consulting/certify_b/funds_img_1_1.png" alt="">
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>시설자금</strong>
							<p>
								기업에 필요한 <b>기계설비, 공장설립, 토지구입 등 시설, 설비 투자 및 구매</b>에 소요되는 자금을 저금리로 대출해 주는 자금
							</p>
						</div>
						<img src="/img/consulting/certify_b/funds_img_1_2.png" alt="">
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>연구과제(R&D)</strong>
							<p>
								<b>정부과제사업에 선정된 기업을 대상</b>으로 기술 또는 신용을 담보로 지원되는 자금
							</p>
						</div>
						<img src="/img/consulting/certify_b/funds_img_1_3.png" alt="">
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>고용지원금</strong>
							<p>
								<b>고용창출, 고용안정, 고용유지 등 근로자의 고용</b>과 관련한 인건비 등을 지원하는 장려금
							</p>
						</div>
						<img src="/img/consulting/certify_b/funds_img_1_4.png" alt="">
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>사업화 패키지</strong>
							<p>
								<span>기업창업 아이디어를 보유한 <b>예비창업자의 성공창업을 위해</b> 사업화 자금, 비즈니스모델 고도화 및 시제품 제작 등을 <b>지원</b></span>
								<span>유망 창업아이템 및 고급기술을 보유한 창업 3년 이내 창업기업에 사업화 자금 및 초기 창업프로그램을 지원하여 기업의 안정화 및 성장을 지원 (창업진흥원)</span>
							</p>
						</div>
						<img src="/img/consulting/certify_b/funds_img_1_5.png" alt="">
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>수출 바우처</strong>
							<p>
								수출 역량을 키우고 싶은 기업들이 <b>수출지원 서비스를 선택하여 활용</b>할 수 있도록 보조금을 <b>바우처 형태로 지원</b>
							</p>
						</div>
						<img src="/img/consulting/certify_b/funds_img_1_6.png" alt="">
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- / 정부지원사업(정책자금) -->


	<!-- 경정청구 -->
	<section id="correction" class="section">
		<div class="inner">
			<h3 class="tit_section">경정청구</h3>
			<div class="contents">
				<div class="box">
					<strong>경정청구의 정의</strong>
					<p>국세기본법 제45조의 2에 따라 납세자(사업자)가 세금을 더 납부했거나 잘 못 납부한 경우 이를 국세청에 돌려달라고 요청하는 제도</p>
				</div>
				<div class="box">
					<strong>경정청구의 주요 공제항목</strong>
					<ul>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_1_1.png" alt="">
							</div>
							<div class="info">
								고용 지원금,<br>고용 관련 세액공제
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_1_2.png" alt="">
							</div>
							<div class="info">
								사회보험료<br>세액공제
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_1_3.png" alt="">
							</div>
							<div class="info">
								통합 투자<br>세액공제
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_1_4.png" alt="">
							</div>
							<div class="info">
								연구 인력 개발비<br>세액공제
							</div>
						</li>
					</ul>
				</div>
				<div class="box">
					<strong>경정청구 시 유리할 수 있는 조건</strong>
					<div class="list_box">
						<strong>경정청구</strong>
						<dl>
							<dd class="isAppear">
								<div class="thumb">
									<img src="/img/consulting/certify_b/correction_img_2_1.png" alt="">
								</div>
								<div class="info">
									직전 5년 전부터 매년 고용인원 증가
								</div>
							</dd>
							<dd class="isAppear">
								<div class="thumb">
									<img src="/img/consulting/certify_b/correction_img_2_2.png" alt="">
								</div>
								<div class="info">
									직전 5년간 법인세 또는 종합소득세 증가
								</div>
							</dd>
							<dd class="isAppear">
								<div class="thumb">
									<img src="/img/consulting/certify_b/correction_img_2_3.png" alt="">
								</div>
								<div class="info">
									직원이 많을수록 유리
								</div>
							</dd>
						</dl>
						<em>
							<span>* 단, 소비성 서비스업 제외(호텔업 및 여관업, 주점업, 오락·유흥 등을 목적으로 하는 사업)</span>
							<span>* 관광진흥법에  따른 관광숙박업, 외국인 전용 유흥음식점업, 관광유흥음식점업은 가능</span>
						</em>
					</div>
				</div>
				<div class="box">
					<strong>경정청구 컨설팅의 필요성</strong>
					<ol>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_3_1.png" alt="">
							</div>
							<div class="info">
								지속적인 세법 변경
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_3_2.png" alt="">
							</div>
							<div class="info">
								세제혜택에 대한<br>공제 적용 제한
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_3_3.png" alt="">
							</div>
							<div class="info">
								조세특례제한법의<br>내용이 광범위<br>(140개 이상)
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_3_4.png" alt="">
							</div>
							<div class="info">
								비용증가나 순자산<br>감소 없이 절세
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_3_5.png" alt="">
							</div>
							<div class="info">
								최대 10년간 이월공제<br>및 세금 절세
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/certify_b/correction_img_3_6.png" alt="">
							</div>
							<div class="info">
								환급세액 발생시<br>기업 유동성에 도움
							</div>
						</li>
					</ol>
				</div>
			</div>
		</div>
	</section>
	<!-- //경정청구 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-type="1" data-list-count="7" />
	<!-- / 컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- / 전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="isAppear"></section>
	<!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/certify_b/'
		}
	},
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/certify_b.scss';
</style>
<template>
    <!-- main -->
    <main id="container">
        <!-- 메인비주얼 -->
        <section id="visual">
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="swiper-inner">
                            <p class="tit">
                                <span>국내 최고의 전문가와 함께하는 <i class="color-ff6815">맞춤 솔루션</i></span> <br>
                                <strong>중소기업 전문 컨설팅</strong>
                            </p>
                        </div>
                        <div class="bg" :style="'background-image:url(' + imgPath + 'visual_bg10.jpg)'"></div>
                    </div>
                </div>
            </div>
            <button class="btn_cont" type="button">
                <span>무료 컨설팅 상담신청</span><i></i>
            </button>
        </section>
        <!-- //메인비주얼 -->

        <!-- 세미나 -->
        <section id="seminar" class="hidden">
            <h4 class="tit_section"><a href="/seminar/seminar">세미나</a></h4>
            <div class="headline">
                <img class="tit" :src="imgPath + 'seminar_headline_tit.png'" alt="" />
                <i class="particle_circle" style="top: 16.53vw; left: 7.8vw"></i>
                <i class="particle_circle-border" style="top: -9.9vw; left: 13.47vw"></i>
                <i class="particle_circle-diagonal" style="top: 9.7vw; left: -9.7vw; z-index: -1"></i>
                <p class="seminar_tit"></p>
            </div>
            <div class="contents">
                <div class="list">
                    <ul>
                        <li>
                            <p class="flag"></p>
                            <p class="tit" data-text="title" data-clamp="1"></p>
                            <p class="local" data-text="/eachSeminar/content" data-clamp="1"></p>
                            <p class="date"></p>
                            <p class="address" data-text="/eachSeminar/location" data-clamp="2"></p>
                            <a class="link">세미나 참가신청</a>
                        </li>
                    </ul>
                    <div class="swiper-pagination"></div>
                </div>
            </div>
            <div class="bgc"></div>
        </section>
        <!-- //세미나 -->

        <!-- 실시간 상담현황 -->
        <section id="realTime"></section>
        <!-- /실시간 상담현황 -->

        <!-- 무료 컨설팅 상담신청 -->
        <section id="consulting" class="isAppear"></section>
        <!-- /무료 컨설팅 상담신청 -->
        
        <!-- 컨설팅 프로세스 -->
        <consultingProcess data-list-idx="1" />
        <!-- /컨설팅 프로세스 -->
        
        <!-- 언론보도 -->
        <section id="media">
            <h4 class="tit_section"><strong>매경경영지원본부 언론보도</strong></h4>
            <div class="contents">
                <ul>
                    <li>
                        <em class="cate"></em>
                        <p class="title">
                            <strong data-text="title" data-clamp="2"></strong>
                        </p>
                        <p class="content">
                            <span data-text="content" data-clamp="2"></span>
                        </p>
                        <i class="thumb"></i>
                    </li>
                </ul>
                <div class="swiper-pagination"></div>
            </div>
            <div class="btn_area">
                <a href="/media/news" class="link_more">언론보도 전체 보기</a>
            </div>
        </section>
        <!-- /언론보도 -->

        <!-- 전문가 네트워크 -->
        <section id="network" class="isAppear">
            <h4 class="tit_section mb-70"><strong>전문가 네트워크</strong></h4>
            <!-- <p class="desc_section">중소기업 경영 고민의 시작부터 사후까지<br>11 R&D 전문가 네트워크가 해결해드립니다.</p> -->
            <img class="img-fluid mb-100" :src="imgPath + 'network_graph.png'" alt="" />
            <div class="contents">
                <div class="logo_list">
                    <div class="swiper-container swiper-container-free-mode">
                        <ul class="net_list swiper-wrapper">
                            <li class="swiper-slide" data-attr-type="type">
                                <em>
                                    <b data-html="type"></b>
                                    <span data-attr-name="name" data-html="name"></span>
                                </em>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="member">
                    <div class="mem_grp_wrap">
                        <div class="mem_grp">
                            <ul class="mem_list">
                                <li data-attr-type="type">
                                    <img src="" alt="">
                                    <em>
                                        <span class="mem_role"></span>
                                        <span class="mem_name"></span>
                                    </em>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="swiper-pagination"></div>
                    <button class="d-none mem_arrow mem_prev" data-json-path="/image/common/prev01.png" type="button">
                        이전
                    </button>
                    <button class="d-none mem_arrow mem_next" data-json-path="/image/common/next01.png" type="button">
                        다음
                    </button>
                    <i class="particle_circle-diagonal"
                        style="bottom: -10vw; left: 50%; z-index: -1; width: 80vw; height: 80vw; transform: translateX(-50%);"></i>
                </div>
            </div>
            <div class="btn_area">
                <a class="link_more" href="/company/network">전문가 전체 보기</a>
            </div>
        </section>
        <!-- //전문가 네트워크 -->

        <!-- 컨설팅 현황 -->
        <section id="board" class="isAppear">
            <div class="contents">
                <ul>
                    <li>
                        <canvas></canvas>
                        <strong data-text="title"></strong>
                        <p data-text="source"></p>
                        <em>
                            <b data-attr-count="count"></b>
                            <i data-text="unit"></i>
                        </em>
                        <span data-html="comment"></span>
                    </li>
                </ul>
                <div class="swiper-pagination"></div>
                <button type="button" class="board_prev" data-json-path="/image/common/prev03.png">
                    이전
                </button>
                <button type="button" class="board_next" data-json-path="/image/common/next03.png">
                    다음
                </button>
            </div>
            <div class="particle_circle" style="top: 31vw; left: 7vw"></div>
            <div class="particle_circle-border" style="top: 19vw; left: 16vw"></div>
        </section>
        <!-- //컨설팅 현황 -->

        <!-- 전문가영상 -->
        <section id="video">
            <h4 class="tit_section"><strong>중소기업경영에 도움되는 전문가 영상</strong></h4>
            <div class="contents">
                <div class="swiper-container">
                    <ul clss="swiper-wrapper">
                        <li class="item_video">
                            <div class="swiper_grop">
                                <div class="swiper-inner">
                                    <button class="btn_news" type="button">
                                        <em></em>
                                        <i class="ico_play"></i>
                                        <div class="thumb"></div>
                                    </button>
                                    <div class="info_inner">
                                        <div class="new_info">
                                            <p class="date" data-text="writeDate"></p>
                                            <p class="count" data-text="viewCnt">1</p>
                                        </div>
                                        <p class="tit" data-text="title" data-clamp="2"></p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="swiper-pagination"></div>
            </div>
            <div class="btn_area">
                <a href="/media/video" class="link_more">전체보기</a>
            </div>
        </section>
        <!-- / 전문가영상 -->

    </main ><!-- /main -->
</template>



<script>
    export default {
        data() {
            return {
                imgPath: "/img/main/",
            };
        },
    };
</script>



<style scoped lang="scss">
    @import "@/assets/scss/main/main.scss";
</style>
<template>
<!-- main -->
<main id="container">

	<!-- 메인 -->
	<section id="visual" class="isAppear" data-type="b">
		<div class="inner">
				<h2>병/의원 컨설팅</h2>
				<p class="desc_section">
					의료보건서비스에 한정된 병/의원과는<br>
					다르게 의료행위와 분리되어 병/의원의 전반적인<br>
					경영지원과 마케팅, 세무회계, 법률지원 등을<br>
					담당하는 전문 회사로 MSO법인은 병/의원의<br>
					<b>세금 절약(절세) 및 효율적인 운영 관리(투자수익)에 있어</b><br>
					<b>필수적인 요소</b>가 되고 있습니다.
				</p>
				<div class="div-button-consult">
					<button class="btn_cont" onclick="LAYER_CONT(248)" type="button">
						<span>병/의원 컨설팅 상담신청</span>
						<i></i>
					</button>
				</div>
		</div>
	</section>
	<!-- /메인 -->

	<!-- 장점 및 기대효과 -->
	<section id="expect" class="section">
		<div class="inner">
			<h3 class="tit_section">장점 및 기대효과</h3>
			<div class="box">
				<strong>병/의원 컨설팅의 정의<br>[MSO (Management Service Organization)]</strong>
				<p>
					의료보건서비스에 한정된 병/의원과는 다르게 <b>의료행위와 분리되어 병/의원의 전반적인 경영지원과 마케팅, 세무회계, 법률지원 등을 담당하는 전문 회사</b>로 병원 경영에 필요한 다양한 지원 업무를 수행할 수 있으며 진료 과목과 연관된 제품개발 또는 일반 서비스, 도소매, 교육업 등 자유롭게 원하는 사업들을 운영할 수 있는 것이 특징.<br><br>개인사업자 또는 상법상 법인 형태로 설립이 가능하며, 주로 병/의원들이 지분투자하여 주식회사 형태로 설립되었으며, <b>MSO법인은 병/의원의 세금 절약(절세) 및</b> <b>효율적인 운영 관리(투자수익)에 있어 필수적인 요소</b>가 되고 있다.
				</p>
			</div>
			<div class="box">
				<strong>장점 및 기대효과</strong>
				<ul>
					<li class="isAppear">
						<div class="info">
							<strong>재정적 안정성 구축</strong>
							<p>
								<span>대량/공동 구매를 통한 비용/원가 절감 및 수익구조 개선</span>
								<span>유관 사업 연계로 새로운 수익 상품을 창출 가능</span>
								<span>수입 분산 통한 세금 절감 및 재정구조 최적화</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>경영 효율화 향상</strong>
							<p>
								<span>업무 전문화 및 아웃소싱 활용한 전문적인 관리와 자원 배분</span>
								<span>브랜드 인지도 강화</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>규제 준수와 리스크 관리</strong>
							<p>
								<span>법적 규제와 세무 리스크 관리, 병원 시설의 임대나 의료기기 리스, 렌탈 등을 통한 간접투자 가능</span>
							</p>
						</div>
					</li>
				</ul>
			</div>
			<div class="arrow isAppear">
				<img src="/img/consulting/hospital_b/expect_arrow.png" alt="" data-delay="0.6">
			</div>
			<div class="box">
				<ol class="isAppear">
					<li>
						<b>MSO법인과 병/의원 간의 효과적인 협력을 통해 장기적이고 지속 가능한 성장</b>
					</li>
					<li>
						<b>시장 동향을 분석하고 변화에 빠르고 유연하게 대응함으로써 경쟁력 향상</b>
					</li>
					<li>
						<b>지속적인 혁신과 개발을 통해 병/의원 서비스의 질 향상</b>
					</li>
				</ol>
			</div>
		</div>
	</section>
	<!-- //장점 및 기대효과 -->

	<!-- 병/의원 컨설팅 종류와 형태 -->
	<section id="type" class="section">
		<div class="inner">
			<h3 class="tit_section">병/의원 컨설팅 종류와 형태</h3>
			<div class="contents">
				<div class="box">
					<strong>병/의원 종류</strong>
					<ul>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_1_1.png" alt="">
								<b>경영효율화형</b>
							</div>
							<div class="info">
								<p>
									<span>의약품, 의료기기 공동구매 및 고가 의료장비, 시설을 공동 이용</span>
									<span>법률, 회계, 세무, 노무 등 <b>아웃소싱을 통해 전문인력이 전담하여 비용 절감</b></span>
								</p>
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_1_2.png" alt="">
								<b>네트워크형</b>
							</div>
							<div class="info">
								<p>
									<span>복수의 의료기관 간 지분 출자를 통해 네트워크 활성을 목적으로 설립</span>
									<span><b>2개 이상의 의료기관의 지분 출자를 통하여 MSO를 설립</b></span>
									<span>수평적ㆍ수직적 계열로 의료기술, 연계 진료 등 의료기관 네트워크 활성화에 중점</span>
									<span>1~3차 의료기관 간에 연계하여 환자 편의 증진 및 효율적 자원관리</span>
								</p>
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_1_3.png" alt="">
								<b>산업연계형</b>
							</div>
							<div class="info">
								<p>
									<span><b>의료산업 및 관광/보험 등 연계산업으로의 확대를 목적으로 설립</b></span>
									<span>의약품, 의료기기, 연구개발 등에 직접 투자를 통한 의료산업 내 계열화 가능</span>
									<span>관광 및 보험 등 금융서비스와의 연계상품 개발 및 연계된 서비스업에 투자 가능</span>
								</p>
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_1_4.png" alt="">
								<b>자본조달형</b>
							</div>
							<div class="info">
								<p>
									<span><b>외부자본유치를 통해</b> 병원시설 임대, 리스, 경영지원위탁 등 <b>간접적인 투자 활성화</b></span>
									<span>현행 의료법상 의료법인 및 개인 병/의원에 대해 외부자본 투자유치는 불가</span>
									<span>사무장 병원의 형태로 운영할 시 형사처분의 대상</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="box">
					<strong>병/의원 형태</strong>
					<ul>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_2_1.png" alt="">
								<b>병원 내 자체 운영</b>
							</div>
							<div class="info">
								<p>
									<span>규모가 큰 병원의 경우 병원 사업장 내 홍보 부서, 구매 부서 등을 운영하여 자체적으로 운영</span>
								</p>
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_2_2.png" alt="">
								<b>MSO 회사 공동 설립</b>
							</div>
							<div class="info">
								<p>
									<span>각 병원을 운영하는 원장이 모여 공동투자 형태로 법인을 설립</span>
									<span>해당 법인의 투자자가 되어 구매와 업무 등을 공동으로 운영</span>
								</p>
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_2_3.png" alt="">
								<b>기존 MSO 회사에<br>위탁 운영</b>
							</div>
							<div class="info">
								<p>
									<span>전문적으로 병원 경영지원 업무를 담당하는 회사에 진료행위 이외에 전반적인 업무를 위탁</span>
								</p>
							</div>
						</li>
						<li class="isAppear">
							<div class="thumb">
								<img src="/img/consulting/hospital_b/type_img_2_4.png" alt="">
								<b>네트워크 병원</b>
							</div>
							<div class="info">
								<p>
									<span>설립한 MSO 회사를 본사의 개념으로 하여 네트워크 병원을 프랜차이즈 형식으로 운영</span>
									<span>브랜드 가치를 높이고 규모 있게 운영 가능</span>
								</p>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>
	<!-- //병/의원 컨설팅 종류와 형태 -->

	<!-- MSO법인 설립 시 주요사항 -->
	<section id="MSO" class="section">
		<div class="inner">
			<h3 class="tit_section">MSO법인 설립 시 주요사항</h3>
			<div class="contents">
				<ul>
					<li class="isAppear">
						<div class="info">
							<strong>명확한 사업 목적과 범위 설정</strong>
							<p>
								<span>사업의 목적을 명확하고 구체적으로 정의, <b>법인의 운영 방향과 범위를</b> <b>결정하는 기준</b></span>
								<span>미래 확장 가능성을 고려하여 사업 범위를 계획, 변화하는 시장 환경에 유연하게 대응 가능</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>정관의 정확한 수립</strong>
							<p>
								<span>정관은 법인의 기본 규범이자 운영의  기준, 사업목적과 운영방식을 규정</span>
								<span>관련 법령과 규정의 철저한 검토/ 준수를 통해 <b>법적 문제를 미연에 방지</b></span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>정당한 계약 체결</strong>
							<p>
								<span><b>병/의원과 MSO법인 간 계약에는</b> <b>법적, 윤리적 타당성</b>이 있어야 하고,  계약 내용은 양측의 권리와 의무를 명확히 규정</span>
								<span>계약은 공정거래법을 준수해야 하고, 거래의 공정성을 위해 어떠한 우월적 지위 남용 행위도 포함해서는 안됨</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>세무 및 법률적 준수</strong>
							<p>
								<span><b>세무 조사의 가능성을 고려, 모든</b> <b>거래와 재정 활동은 투명하게 관리</b>하고 법규를 준수</span>
								<span>설립 과정에서 법적인 조언을 받아 법적인 문제를 예방하고, 복잡한 규정을 정확히 이해하는 것이 필요</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="info">
							<strong>근거 자료의 준비와 관리</strong>
							<p>
								<b>계약의 정당성과 법인의 운영을</b> <b>뒷받침하는 근거자료를 준비하고</b> <b>관리</b>(세무 조사나 법적 분쟁 시 필수적인 증거 자료)
							</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- //MSO법인 설립 시 주요사항 -->

	<!-- 병/의원 컨설팅 서비스 -->
	<section id="service" class="section">
		<h3 class="tit_section">병/의원 컨설팅 서비스</h3>
		<div class="inner">
			<div class="box">
				<ul>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/hospital_b/service_img_1_1.png" alt="">
						</div>
						<div class="info">
							<strong>전문 컨설팅 제공</strong>
							<p>
								<span><b>각 병/의원의 특성/상황과 요구를 반영한 효과적인 접근 방식</b>을 통해 맞춤형 전략을 제공</span>
								<span>운영의 최적화, 비용 관리, 수익성 향상에 중점을 두고 경영 효율화를 높이는 방안 제시</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/hospital_b/service_img_1_2.png" alt="">
						</div>
						<div class="info">
							<strong>MSO법인 설립 및 운영 지원</strong>
							<p>
								<span><b>법적, 재정적 조언</b>을 포함한 MSO법인 설립 과정을 지원</span>
								<span>설립된 MSO법인의 효과적인 운영 관리를 위한 전략과 조언을 제공</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/hospital_b/service_img_1_3.png" alt="">
						</div>
						<div class="info">
							<strong>법적 규제 준수 및 세무적 지원</strong>
							<p>
								<span><b>병/의원과 MSO법인이 세무, 공정거래법 등 관련 법규</b>를 문제없이 준수 할 수 있도록 지원</span>
								<span>법적 분쟁이나 세무 조사의 위험을 최소화하기 위한 위험 관리 및 예방 전략을 제공</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/hospital_b/service_img_1_4.png" alt="">
						</div>
						<div class="info">
							<strong>교육 및 훈련 프로그램 제공</strong>
							<p>
								<span><b>운영 효율성을 향상시키고 전문성을 높이는</b> 교육 및 훈련 프로그램을 제공</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/hospital_b/service_img_1_5.png" alt="">
						</div>
						<div class="info">
							<strong>연구 및 개발</strong>
							<p>
								<span>최신 시장 동향 분석과 연구를 통해 실질적인 통찰력과 전략적 가이드 제공</span>
								<span>시장에서 경쟁 우위를 점할 수 있도록 <b>혁신적인 솔루션과 서비스 개발 지원</b></span>
							</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- / 병/의원 컨설팅 서비스 -->

	<!-- 주의사항 -->
	<section id="caution" class="section">
		<h3 class="tit_section">주의사항</h3>
		<div class="inner">
			<div class="box">
				<p class="tit">
					<span>주의사항</span>
				</p>
				<ul>
					<li class="isAppear">
						<span>현재 개인병원의 원장이 투자자로 참여하여 MSO를 설립하는 것은 의료법상 가능</span>
					</li>
					<li class="isAppear">
						<span>설립된 회사와 투자자인 원장이 운영하는 병원은 세법상 특수관계에 해당하므로, 양사 거래 간에는 반드시 시가(時價)를 기준으로 거래 가격을 산정</span>
					</li>
					<li class="isAppear">
						<span>시가로 거래하는 것이 법적으로 불이익은 없지만, 시가보다 높은 가격으로 거래한 경우 그 초과 금액은 필요경비로 인정받지 못함</span>
					</li>
					<li class="isAppear">
						<span>MSO에 일정 수수료를 지급하게 되면 필요경비에 산입되므로 사업소득의 절세 효과는 가능하나 투자자로서 MSO의 이익에 대한 배당을 받게 되면 배당소득에 대한 과세 문제가 추가로 발생</span>
					</li>
					<li class="isAppear">
						<span>효과적이고 실질적인 절세효과를 위해서는 주주 구성, 임원 구성, 배당 규모 및 시기 등을 계획적으로 실행 필요</span>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- / 주의사항 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-type="1" data-list-count="7" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="isAppear"></section>
	<!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/hospital_b/'
		}
	},
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/hospital_b.scss';
</style>
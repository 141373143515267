<template>
<!-- main -->
<main id="container">
   <!-- 메인비주얼 -->
    <section id="visual" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">네트워크 전문가들이<br> 여러분의 입장에서 함께합니다. </h3>
        </div>
        <img class="bg" :src="imgPath+'visual_bg.jpg'" alt="">
    </section>
    <!-- //메인비주얼 -->


    <!-- 전문가 네트워크 -->
    <networkTab></networkTab>
    <!-- //전문가 네트워크 -->

    <section id="networkSmall">
        <div class="inner">
            <ul class="fake_list">
                <li class="fake_illust">
                    <label>
                        <div class="image">
                            <img src="" alt="">
                        </div>
                        <p class="tit" data-html="name"></p>
                    </label>
                </li>
            </ul>
        </div>
    </section>

    <!-- 인물정보 -->
    <section id="networkList" class="hidden">
        <div class="inner">
            <p class="tit_section"><strong></strong></p>
            <div class="ready">
                <div class="hourglass">
                    <div class="tit"><strong>프로필 준비중</strong> 입니다.</div>
                    <img class="ico" :src="imgPath+'networkList_hourglass.png'" alt="">
                    <i class="ico_shadow"></i>
                </div>
                <div class="particle_circle" style="top:8vw;right:20vw"></div>
                <div class="particle_circle" style="bottom:15vw;left:12vw"></div>
                <div class="particle_circle-border" style="bottom:23vw;left:6vw"></div>
            </div>
            <ul class="list_member">
                <li class="item_member">
                    <label class="inner_item">
                        <p class="tit" data-type="">
                            <span class="role"></span>
                            <span class="name"></span>
                        </p>
                        <button class="btn_more" type="button">
                            <img class="ico" src="" data-json-path="/image/svg/plus03.svg" alt="">
                        </button>
                        <div class="thumb">
                            <img src="" alt="">
                        </div>
                    </label>
                </li>
            </ul>
        </div>
    </section>
   <!-- //인물정보 -->
</main ><!-- /main -->
<!-- //container -->
</template>



<script>
    export default {
        data(){
            return {
                imgPath: '/img/company/network/'
            }
        }
    }
</script>



<style scoped lang="scss">
    @import '@/assets/scss/company/network.scss';
</style>
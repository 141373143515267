<template>
    <section id="consultingProcess_b" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">컨설팅 프로세스</h3>
            <div class="step">
                <ul class="step_list">
                    <!-- <li></li> -->
                </ul>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'consultingProcess_b',
    data() {
        return {
            imgPath: '/img/common/contents/consultingProcess_b/'
        }
    },
}
</script>



<style lang="scss">

    $imgPath : "/img/common/contents/consultingProcess_b/";

    #consultingProcess_b { 
        position:relative;background-color:#fff;padding-bottom: vw(140);
        .tit_section{
            position:relative; padding-bottom: vw(90); font-size:vw(42); font-weight:700; line-height:1em; text-align:center;
        }
        .step_list{
            position: absolute;z-index: 1; top:0;left:0;width: 100%; height: 100%;
            li{
                position: absolute;
                width: vw(265);
                opacity: 0;
                transform: translateY(10px);
                .label{display: block; padding-left: vw(24); width: vw(220); height: vw(50); color:#fff; font-weight: 500; font-size: vw(28); border-radius: vw(25); background-color: #505056; text-transform: uppercase; line-height: vw(54);}
                .txt{
                    display: inline-block;
                    margin-top: vw(22);
                    color:#505056; line-height: 1.2; padding-left: vw(20);
                    &::before{display: block; font-size: vw(28); font-weight: 700;}
                    &::after{display: block; font-size: vw(18);font-weight: 500;}
                }
                
                .step_person{
                    position: absolute; margin-top: vw(-126);
                    width: vw(135); height:vw(251); background-size: contain;
                }
            }
        }
        .step{
            position:relative; width:100%; height:vw(758); margin-bottom:4vw;
            .bg { position:absolute; top:50%; left: 0; width:100%; height:auto; stroke:#e6e6e6; transform:translateY(-50%); max-width: 100%;
                &.fst {
                    .line{ stroke-dasharray: 2900; stroke-dashoffset: 2900; }
                }
            }
        }
    }

    #consultingProcess_b {
        &[data-type="1"] {
            $value:
                1  2900,
                2  2500,
                3  2000,
                4  1590,
                5  1000,
                6  800,
                7  0,
                8  0,
            ;
            &.appear{
                .step_list li{ opacity: 1; transform: translateY(0); transition: opacity 0.5s linear, transform 0.5s linear}
                .bg.fst .line{
                    animation: line 1s 0.5s linear forwards;
                    @include animation-mixin(line, 0);
                }
                .step {
                    @each $nth, $offset in $value {
                        .step_list li:nth-of-type(#{$nth}){ transition-delay: #{$nth * 0.5}s ; }
                    }
                }
            }
        }
        &[data-type="3"] {
            $value:
                1  2900,
                2  2500,
                3  2000,
                4  1590,
                5  1000,
                6  0,
            ;
            &.appear{
                .step_list li{ opacity: 1; transform: translateY(0); transition: opacity 0.5s linear, transform 0.5s linear;}
                .bg.fst .line{
                    animation: line 1s 0.5s linear forwards;
                    @include animation-mixin(line, 0);
                }
                .step {
                    @each $nth, $offset in $value {
                        .step_list li:nth-of-type(#{$nth}){ transition-delay: #{$nth * 0.5}s ; }
                    }
                }
            }
            .step{height: vw(508);}
        }
    }

    #consultingProcess_b {

        &[data-list-count="6"]{
            .step_list{
                $value:
                    1 '담당자 매칭' '지역별, 주제별 이슈를\A파악 후 최적의\A전문가 선정' -2.6vw auto auto 9vw 'consultingprocess_b_step02_.png' 45% 0 '담당자매칭',
                    2 '1차 미팅' '지배구조개선에 관한\A CEO 리스크 및\A법인현황 파악' -2.6vw 4.44vw auto auto 'consultingprocess_b_step03_.png' 29% -2% 'Day 1',
                    3 '협약서 및\A정보준수\A서약서 작성' '' 32vw 4.44vw auto auto 'consultingprocess_b_step04_.png' 20% -2% 'Day 5',
                    4 '솔루션 도출\A후 2차 미팅' '제안서 제출, 검토' 32vw auto auto 9vw 'consultingprocess_b_step05_.png' 30% 5% 'Day 10',
                    5 '지배구조개선\A솔루션 실행' '' 66.4vw auto auto 9vw 'consultingprocess_b_step06_.png' 37% 0 'Day 14',
                    6 '솔루션 실행\A후 사후관리' '' 66.4vw 4.44vw auto auto 'consultingprocess_b_step07_.png' 50% 7% 'Day 14 ~'
                ;
                @each $nth, $mainText, $semiText, $top, $right, $bottom, $left, $src, $srcTop, $srcRight, $rableText in $value {
                    li:nth-of-type(#{$nth}) {
                        top:#{$top}; right:#{$right}; bottom:#{$bottom}; left:#{$left};
                        .label::before{content:"#{$rableText}";}
                        .txt{
                            &::before{content:"#{$mainText}"; white-space: pre; text-align:left;}
                            &::after{content:"#{$semiText}"; white-space: pre; text-align:left;}
                        }
                        .step_person{
                            background-image: url(#{$imgPath}#{$src});
                            top:#{$srcTop}; right :#{$srcRight};
                        }
                    }
                }
            }
        }

        &[data-list-count="7"]{
            .step_list{
                $value:
                    1 '상담신청' '' -2.6vw auto auto 9vw 'consultingprocess_b_step01_.png' 45% 0,
                    2 '전문가 매칭' '' -2.6vw 4.44vw auto auto 'consultingprocess_b_step02_.png' 52% 10%,
                    3 '기업 검진' '' 32vw 4.44vw auto auto 'consultingprocess_b_step03_.png' 53% -2%,
                    4 '세부상담 미팅' '' 32vw auto auto 9vw 'consultingprocess_b_step04_.png' 30% 0,
                    5 '분석 및 브리핑' '솔루션 제안' 66.4vw auto auto 9vw 'consultingprocess_b_step05_.png' 37% 0,
                    6 '솔루션 실행' '' 66.4vw 4.44vw auto auto 'consultingprocess_b_step06_.png' 50% 7%,
                    7 '지속 점검' '' 101.2vw auto auto 30.55vw 'consultingprocess_b_step07_.png' 58% 5%
                ;
                @each $nth, $mainText, $semiText, $top, $right, $bottom, $left, $src, $srcTop, $srcRight in $value {
                    li:nth-of-type(#{$nth}) {
                        top:#{$top}; right:#{$right}; bottom:#{$bottom}; left:#{$left};
                        .txt{
                            &::before{content:"#{$mainText}";}
                            &::after{content:"#{$semiText}";}
                        }
                        .step_person{
                            background-image: url(#{$imgPath}#{$src});
                            top:#{$srcTop}; right :#{$srcRight};
                        }
                    }
                }
            }
        }

        &[data-list-count="8"]{
            .step_list{
                $value:
                    1 '주총소집\A위한 이사회' '이사회 의사록 작성' -2.6vw auto auto 9vw 'consultingprocess_b_step08_.png' 45% 0,
                    2 '주주에게 통지' '주총소집 통지서' -2.6vw 4.44vw auto auto 'consultingprocess_b_step02_.png' 52% 10%,
                    3 '주주총회' '매매계약서 작성\A_1개월 이내' 32vw 4.44vw auto auto 'consultingprocess_b_step03_.png' 30% 0%,
                    4 '이사회' '이사회 의사록 작성' 32vw auto auto 9vw 'consultingprocess_b_step09_.png' 30% 0,
                    5 '주주에게 통지' '통지서' 66.4vw auto auto 9vw 'consultingprocess_b_step10_.png' 37% 0,
                    6 '양도신청' '양도신청서 작성' 66.4vw 4.44vw auto auto 'consultingprocess_b_step07_.png' 38% 7%,
                    7 '주식매입' '매매계약서 작성\A_1개월 이내' 101.2vw 4.44vw auto auto 'consultingprocess_b_step06_.png' 38% 7%,
                    8 '증권거래세,\A양도소득세 신고' '증권거래세,양도소득세\A신고서 및 부속서류 작성' 101.2vw auto auto 9vw 'consultingprocess_b_step12_.png' 35% -11%
                ;
                @each $nth, $mainText, $semiText, $top, $right, $bottom, $left, $src, $srcTop, $srcRight in $value {
                    li:nth-of-type(#{$nth}) {
                        top:#{$top}; right:#{$right}; bottom:#{$bottom}; left:#{$left};
                        .txt{
                            &::before{content:"#{$mainText}";white-space: pre; text-align:left;}
                            &::after{content:"#{$semiText}";white-space: pre; text-align:left;}
                        }
                        .step_person{
                            background-image: url(#{$imgPath}#{$src});
                            top:#{$srcTop}; right :#{$srcRight};
                        }
                    }
                }
            }
        }
    }

</style>

<template>
    <section id="consultingProcess" class="isAppear">
        <h3 class="tit_section">컨설팅 PROCESS</h3>
        <div class="step"></div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="box_illust">
                        <img class="illust active" src="" alt="">
                        <img class="illust" src="" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'consultingProcess',
    data() {
        return {
            imgPath: '/img/common/contents/consultingProcess/'
        }
    },
}
</script>



<style lang="scss">
    $imgPath : "/img/common/contents/consultingProcess/";

    #consultingProcess {
        position:relative;padding-bottom:vw(115);background-color:#f6f6f6;
        .tit_section {
            position:relative;margin-bottom:vw(100);padding:vw(75) 0 vw(25);font-size:vw(42);font-weight:500;line-height:1em;text-align:center;
            &::before {
                content:"";position:absolute;bottom:0;left:50%;width:vw(120);height:vw(6);background-color:#{$G-colorPrimary};transform:translateX(-50%);
            }
        }
    }

    #consultingProcess .step {
        position:relative;width:vw(636);height:vw(141);margin-bottom:4vw;
        &::before {
            content:"STEP";position: absolute;top:-3vw;left:4vw;font-size:vw(24);font-weight:bold;color:#{$G-colorPrimary};
        }
        .num {
            display:flex;justify-content:center;align-items:center;position:absolute;z-index:2;width:vw(55);height:vw(55);border-radius:100%;font-family:"CenturyGothic";font-style:italic;font-size:vw(21);font-weight:bold;color:#999;background-color:#eee;transition:all 0.3s 0.1s #{$G-easeHover};
        }
        .bg {
            position:absolute;top:50%;left:-1.3vw;width:vw(660);height:auto;stroke:#e1e0e1;stroke-width:1.5vw;stroke-linecap:round;stroke-linejoin:round;transform:translateY(-50%);transition:all 0.3s #{$G-easeHover};
            &.fst {
                z-index:1;stroke:#{$G-colorPrimary};stroke-width:1.5vw;stroke-dasharray:1300, 1500;
            }
        }
    }

    #consultingProcess .step {
        $value:
            1   -0.3vw      vw(102)      -1610,
            2   -0.3vw      vw(247)      -1750,
            3   -0.3vw      vw(391)      -1900,
            4   -0.3vw      vw(535)      -2040,
            5   11.5vw      vw(582)      -2200,
            6   11.5vw      vw(438)      -2340,
            7   11.5vw      vw(294)      -2490,
            8   11.5vw      vw(150)      -2630,
        ;
        @each $nth, $top, $left, $offset in $value {
            .num#{$nth} {
                top:#{$top};left:#{$left};
            }
            &[data-active-index="#{$nth}"] .bg.fst {
                 stroke-dashoffset:#{$offset};
            }
        }
    }

    #consultingProcess .step .num.is-active {
        color:#fff;background-color:#{$G-colorPrimary};
    }

    #consultingProcess .box_illust {
        position:relative;
        &::after {
            content:"";position:absolute;top:37vw;right:-11vw;z-index:-1;width:14vw;height:14vw;background-image:url(#{$imgPath}type_link.png);background-position-x:-100%;background-size:cover;transform:translateX(-100%);transition:all 0.3s 0.3s #{$G-easeHover};
        }
        .illust {
            display:block;width:100%;height:auto;transition:all 0.3s 0.6s #{$G-easeHover};
            &.active {
                position:absolute;top:0;left:0;z-index:1;opacity:0;transition:all 0.3s 0.3s #{$G-easeHover};
            }
        }
    }

    #consultingProcess .swiper-slide.is-active .box_illust {
        &::after {
            transform:translateX(0);
        }
        .illust {
            opacity:0;
            &.active {
                opacity:1;
            }
        }
    }

    #consultingProcess .swiper-slide:last-child .box_illust::after {
        display:none;
    }

    #consultingProcess[data-list-idx="1"] .swiper-slide {
        $value:
            1       '1. 컨설팅 신청',
            2       '2. 전문가 매칭',
            3       '3. 사안 확인/ 기업 분석',
            4       '4. 1:1 세부 미팅',
            5       '5. 솔루션 도출 및 검토',
            6       '6. 솔루션 실행',
            7       '7. 지속 점검',
        ;
        @each $nth, $after in $value {
            &:nth-child(#{$nth})::after {
                content:"#{$after}";display:block;padding-top:vw(55);font-family:"BinggraeⅡ";font-size:vw(36);font-weight:bold;text-align:center;
            }
        }
    }

    #consultingProcess[data-list-idx="2"] .swiper-slide {
        $value:
            1       '1. 주총소집 위한 이사회',
            2       '2. 주주에게 통지',
            3       '3. 주주총회',
            4       '4. 이사회',
            5       '5. 주주에게 통지',
            6       '6. 양도신청',
            7       '7. 주식매입',
            8       '8. 증권거래세,\A 양도소득세 신고',
        ;
        @each $nth, $after in $value {
            &:nth-child(#{$nth})::after {
                content:"#{$after}";display:block;padding-top:vw(55);font-family:"BinggraeⅡ";font-size:vw(36);font-weight:bold;text-align:center;white-space:pre;
            }
        }
    }

    #consultingProcess[data-list-idx="3"] .swiper-slide {
        $value:
            1       '담당자 매칭',
            2       'Day1. 1차 미팅',
            3       'Day5. 솔루션 도출 후\A 2차 미팅',
            4       'Day10. 협약서 및\A정보준수서약서 작성',
            5       'Day14. 지배구조개선\A솔루션 실행',
            6       'Day14~. 솔루션 실행 후\A사후관리',
        ;
        @each $nth, $after in $value {
            &:nth-child(#{$nth})::after {
                content:"#{$after}";display:block;padding-top:vw(55);font-family:"BinggraeⅡ";font-size:vw(36);font-weight:bold;text-align:center;white-space:pre;
            }
        }
    }


</style>

<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <h3 class="tit_section text-center">가지급금 정리 </h3>
                <p class="desc_section text-center">법인에서 <strong>실제 현금의 지출은 있었지만 금액이 확정되지 않은 경우</strong>에 그<br> 지출액에 대해 임시적으로 표시하는 가계정으로 상당수의 법인 대표들은<br>회사에 가지급금이 얼마나 있는지<br>모르는 경우가 많습니다. </p>
            </div>
            <div class="particle_circle-border" style="top:27vw;left:2.222vw"></div>
            <div class="particle_circle-double" style="bottom:-20vw;right:-10vw;width:42vw;height:42vw"></div>
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(190)" type="button"><span>가지급금정리 상담신청</span></button>
        </div>
      
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        <h3 class="tit_section">가지급금정리 컨설팅 사례 </h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/UDmIjLVIfBo" data-title="효율적인 가지급금의 처리방안은?" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/UDmIjLVIfBo/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="inner">
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_client1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>제조업으로 과거 운영 과정에서 불가피하게 70여억원에 이르는 가지급금이 발생되어 이를 해결하기를 원합니다. </p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_professional1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>지원본부 소속의 세무사와 변호사와의 팀플레이를 통해 상법상 적법한 절차 범위 내에서 산업재산권과 자기주식을 매입하였으며, 배당이 가능한 이익의 범위 내에서 가지급금 상계처리를 진행하였습니다.</li>
                            <li>적은 세금으로 70억원의 가지급금 해결 및 소명하였습니다.</li>
                            <li>이에 따라 회사의 부실자산이 줄어들면서 재무구조 개선과 신용도가 향상되었으며 또한 인정이자가 영업 외 이익으로 잡히게 되어 추가로 납부하던 법인세 경감의 효과를 얻었습니다.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 정리방안 -->
    <section id="plan" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">가지급금 정리방안</h3>
            <div class="cons_info_area">
                <p class="tit_cons">가지급금의 정리방안은 그 과정이 <span class="color-bd4aa5">상당히 전문적이고 어려워 더 큰 세금 문제나 법률 위반 문제로 번질 수 있기 때문에 반드시 관련된 전문가의 도움을 받는 것을 추천</span>합니다.</p>
            </div>
            <div class="cons_info_area area2 mb-70">
                <ul class="list_box">
                    <li class="item_box">
                        <p class="tit">대표이사 개인자산<br> 매각 </p>
                    </li>
                    <li class="item_box">
                        <p class="tit">대표이사 상여, <br>급여로 처리</p>                        
                    </li>
                    <li class="item_box">
                        <p class="tit">배당정책을 통한<br> 배당수익으로 처리</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">대표이사<br>퇴직금으로 처리</p>                        
                    </li>
                    <li class="item_box">
                        <p class="tit">산업재산권으로<br>처리 </p>                        
                    </li>
                    <li class="item_box">
                        <p class="tit">자기주식<br>취득으로 처리 </p>
                    </li>
                    <li class="item_box">
                        <p class="tit">법인 이익잉여금<br> 활용 처리</p>                        
                    </li>
                </ul>
                <img class="bg" :src="imgPath+'plan_bg.png'" alt="">
            </div>
            <div class="cons_info_area mb-30">
                <div class="box_checkpoint">
                    <p class="tit">가지급금 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt="">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">법인자산 매도 시 업무와 관련이 있는 자산이어야 다시 가지급금으로 인정되거나 상여 처분이 되지 않습니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">산업재산권을 활용할 경우 업무유관 자산인지, 대표가 직접 개발한 것인지, 기존 법인특허의 상관관계 등을 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg" alt=""><span class="d-block">산업재산권, 자사주 매입, 특허권을 활용할 경우 ‘특허권 및 비상장주식 가치평가’ 가 중요합니다.</span></p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!-- //정리방안 -->

    <!-- 리스크 -->
    <section id="risk" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">가지급금 발생원인 및 리스크</h3>
            <div class="cons_info_area">
                <p class="tit_cons">1. 가지급금 발생 원인</p>
                <img class="img-fluid mb-60" :src="imgPath+'risk_graph.png'" alt="">
            </div>
            <div class="cons_info_area area2 mb-75">
                <p class="tit_cons">2. 법인 가지급금 리스크</p>                
                <ul class="list_info">
                    <li class="item_info">
                        <p class="tit">인정이자 발생</p>                        
                    </li>
                    <li class="item_info">
                        <p class="tit">인정이자 만큼 법인세 증가 </p>
                    </li>
                    <li class="item_info">
                        <p class="tit">인정이자 미납 시 대표<br> 상여처리</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">지급이자 손금불산입</p>                        
                    </li>
                    <li class="item_info">
                        <p class="tit">대손처리 불가능</p>                        
                    </li>
                    <li class="item_info">
                        <p class="tit">세무조사의 위험성 증가 </p>                        
                    </li>
                    <li class="item_info">
                        <p class="tit">기업신용평가 시 불이익</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">가업승계 시 상속 및<br> 증여세 부담 가중</p>
                    </li>
                    <li class="item_info">
                        <p class="tit">양도/폐업 시 대표 상여처리</p>                        
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //리스크 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/payment/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/payment.scss';
</style>
<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="b">
        <div class="inner">
            <h2>자사주매입</h2>
            <p class="desc_section">
                기업이 발행한 자기 회사의 주식을 다시 취득하는 <br>
                것으로 기업의 절세플랜으로 언급되는 이유는, <br>
                <b>세금을 줄이면서 주식 이동이 가능해 각종 기업의</b><br>
                <b>경영리스크를 해결</b> 할 수 있기 때문입니다.
            </p>
            <div class="div-button-consult">
                <button class="btn_cont" onclick="LAYER_CONT(238)" type="button">
                    <span>자사주매입 상담신청</span>
                    <i></i>
                </button>
            </div>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
     <section id="example" class="isAppear" data-type="b">
        <h3 class="tit_section">자사주 매입 활용방안</h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/TkJQfZF79js" data-title="보유목적 자사주 관련 쟁점판례 및 활용방안" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/TkJQfZF79js/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="inner">
            <div class="list_talk">
                <div class="item_talk">
                    <img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
                    <div class="tooltip">
                        <p class="tit"><strong>컨설팅 문의</strong></p>
                        <p>배우자가 유고상황이고 자녀앞으로 자본환원을 원합니다. 미처분이익잉여금 40억은 주주에게 배당으로 해결하고 있으나 소득세 및 건강보험료 폭탄으로 주주들의 불만이 발생하고있습니다.</p>
                    </div>
                </div>
                <div class="item_solution">
                    <p class="item_tit"><strong>해결방법</strong></p>
                    <div class="item_answer">
                        <p> 양도소득세 이월과세를 대상에서 제외하면 소각대가와 취득가액이 동일하여 소득세가 발생하지 않습니다. </p>
                        <p> 배당소득세 VS 증여세 비교 검토 후 이익소각으로 절세할 수 있습니다. </p>
                        <p> 본인의 주식 중 5억에 해당되는 주식을 자녀에게 증여 후 자사주 매입을 통해 미처분이익잉여금 해소 및 주식가치 인하 효과를 기대할 수 있습니다. </p>
                        <p> 주주자본 환원으로 최대주주의 의결권을 극대화 할 수 있습니다. </p>
                    </div>
                </div>
                <div class="item_talk">
                    <img class="profile" :src="imgPath+'example_talk_profile_client_2.png'" alt="">
                    <div class="tooltip">
                        <p class="tit"><strong>컨설팅 문의</strong></p>
                        <p>가업승계가 아닌 외부투자유치 및 핵심 임직원을 위한 자사주매입에 대해 문의합니다.</p>
                    </div>
                </div>
                <div class="item_solution">
                    <p class="item_tit"><strong>해결방법</strong></p>
                    <div class="item_answer">
                        <p> 대표이사 본인의 주식을 보유목적의 자기주식 취득 실행으로 양도차익에 대한 양도소득세만 납부하여 분류과세를 통한 절세효과를 받을 수 있습니다. </p>
                        <p> 핵심 임직원에 대한 주식보상을 법인에서는 상여로 처리하여 법인세 절감 효과를 받을 수 있습니다.</p>
                        <p> 주식을 부여받은 주주들에게 차등배당을 실행하여 이익이여금을 해소합니다. </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /컨설팅 사례 -->

    <!-- 효과 -->
    <section id="effect">
        <h3 class="tit_section">자사주 매입 효과</h3>
        <div class="inner">
            <div class="effect_list">
                <div class="effect_item isAppear">
                    <p><strong>명의수탁자의 신변의 변화에 따른 문제가 발생할 수 있다.</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <span class="thumb_text">자사주 매입할 경우</span>
                            <img alt="자사주 매입할 경우" :src="imgPath+'purchase_b_effect_img1.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">기업, 주주, 임직원 모두 절세효과의 혜택을 받을 수 있습니다.</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <span class="thumb_text">주주나 임원의 경우</span>
                            <img alt="주주나 임원의 경우" :src="imgPath+'purchase_b_effect_img2.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt"> 20%(중소기업 소액주주의경우 10%) 단일세율에 의한 과세, 4대 보험료와 관계는 없습니다. 주식의 소유권이 법인으로 변경되어 상속대상 재산에서 제외 되므로 절세가 가능합니다.</div>
                        </div>
                    </div>
                    <div class="thumb_item">
                        <div class="thumb">
                            <span class="thumb_text">법인의 경우</span>
                            <img alt="법인의 경우" :src="imgPath+'purchase_b_effect_img3.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">자기주식 취득 후 처분 시 손실이 발생할 경우 법인세 절세 효과가 있습니다.</div>
                        </div>
                    </div>
                </div>
                <div class="effect_item isAppear">
                    <p><strong>자사주 매입 기대 효과</strong></p>
                    <div class="plus_item">
                        <ol>
                            <li>
                                <div class="image">
                                    <img alt="명목세율 20%" :src="imgPath+'purchase_b_effect_img4.png'">
                                </div>
                                <span>명목세율 20%</span>
                            </li>
                            <li>
                                <div class="image">
                                    <img alt="절세 자문 의뢰" :src="imgPath+'purchase_b_effect_img5.png'">
                                </div>
                                <span>절세 자문 의뢰</span>
                            </li>
                        </ol>
                        <div class="down_orange">
                            <img alt="부담 실효세율 0% 까지 인하" :src="imgPath+'purchase_b_effect_img6.png'">
                        </div>
                    </div>
                </div>
                <div class="effect_item isAppear">
                    <p><strong>자사주 매입을 잘 활용하면 이것이 해결됩니다.</strong></p>
                    <div class="round_item">
                        <ul>
                            <li data-action-number="2"><span>대표이사<br>가지급금 처리</span></li>
                            <li data-action-number="1"><span>가업승계에<br>유리</span></li>
                            <li data-action-number="3"><span>미처분<br>이익잉여금<br>정리<br></span></li>
                            <li data-action-number="6"><span>주주의<br>자본환원</span></li>
                            <li data-action-number="4"><span>임직원에게<br>스톡그랜트/<br>스톡옵션<br>가능</span></li>
                            <li data-action-number="5"><span>투자자금<br>유치에 유리<br></span></li>
                            <li class="center"><span>분산주주<br>정리를 통한<br>대주주의결권<br>강화</span> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / 효과 -->

    <!-- 문제점 -->
    <section id="problem">
        <h3 class="tit_section">자사주 과세 문제점</h3>
        <div class="inner">
            <div class="problem_list">
                <div class="problem_item isAppear">
                    <p><strong>법인이 자기주식을 취득하여 보유하다가 처분하는 경우</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 자기주식을 취득하여 보유하다가 처분하는 경우" :src="imgPath+'purchase_b_problem_img1.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">주식을 양도한 주주의 양도차익에 대해서는 다른 소득과 합산되지 않고 분류 과세되며, 그 금액이 3억원 이하인 경우 20%의 단일세율로 과세 </div>
                        </div>
                    </div>
                </div>
                <div class="problem_item isAppear">
                    <p><strong>법인이 취득한 자기주식을 소각하는 경우</strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="법인이 취득한 자기주식을 소각하는 경우" :src="imgPath+'purchase_b_problem_img2.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">주주의 양도차익 상당액에 대해 의제배당으로 과세되어 2천만원 이하인 경우 14% 분리과세로 종결되나, 2천만원 초과되는 경우에는 다른 소득과 합산되어 누진세율로 과세 </div>
                        </div>
                    </div>
                </div>
                <div class="problem_item isAppear">
                    <p><strong>자사주 매입 관련 절세 효과를 극대화 시키는 방법 </strong></p>
                    <div class="thumb_item">
                        <div class="thumb">
                            <img alt="자사주 매입 관련 절세 효과를 극대화 시키는 방법" :src="imgPath+'purchase_b_problem_img3.jpg'">
                        </div>
                        <div class="text">
                            <div class="text_cnt">주식을 배우자에게 증여재산공제 6억 범위 내에서 증여한 후 해당 주식을 회사가 자기주식으로 취득하여 소각</div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    </section>
    <!-- / 문제점 -->


    <!-- 컨설팅 프로세스 -->
    <consultingProcess_b data-type="1" data-list-count="8" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/purchase_b/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/purchase_b.scss';
</style>
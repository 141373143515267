<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <h3 class="tit_section">자사주 매입</h3>
                <p class="desc_section"><strong>기업이 발행한 자기 회사의 주식을<br>다시 취득하는 것</strong>으로 기업의<br> 절세플랜으로 언급되는 이유는,<br> <strong>세금을 줄이면서 주식 이동이 가능해<br> 각종 기업의 경영리스크를<br> 해결</strong> 할 수 있기 때문입니다</p>
            </div>
            <i class="line-wire line-x line-circle-left" style="top:51vw;right:4.4444vw;width:25vw"></i>
            <i class="line-wire line-y" style="top:51vw;right:4.4444vw;height:44vw"></i>
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(238)" type="button"><span>자사주매입 상담신청</span></button>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        <div class="inner">
            <h3 class="tit_section">자사주 매입 활용방안</h3>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/TkJQfZF79js" data-title="보유목적 자사주 관련 쟁점판례 및 활용방안" data-type="youtube" type="button" title="영상재생">
                <i class="ico_play2"><img src="" data-json-path="/image/svg/play01.svg" alt=""></i>
                <img class="thumb" src="https://img.youtube.com/vi/TkJQfZF79js/maxresdefault.jpg" alt="스크린샷">
            </button>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인 1</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_client1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>배우자가 유고상황이고 자녀앞으로 자본환원을 원합니다.  미처분이익잉여금 40억은 주주에게 배당으로 해결하고 있으나  소득세 및 건강보험료 폭탄으로 주주들의 불만이 발생하고있습니다. </p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>양도소득세 이월과세를 대상에서 제외하면 소각대가와 취득가액이 동일하여 소득세가 발생하지 않습니다.</li>
                            <li>배당소득세 VS 증여세 비교 검토 후 이익소각으로 절세할 수 있습니다.</li>
                            <li>본인의 주식 중 5억에 해당되는 주식을 자녀에게 증여 후 자사주 매입을 통해 미처분이익잉여금 해소 및 주식가치 인하 효과를 기대할 수 있습니다.</li>
                            <li>주주자본 환원으로 최대주주의 의결권을 극대화 할 수있습니다.</li>
                        </ol>
                    </div>
                </div>
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인 2</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_client2.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>가업승계가 아닌 외부투자유치 및 핵심 임직원을 위한 자사주매입에 대해 문의합니다.</p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>대표이사 본인의 주식을 보유목적의 자기주식 취득 실행으로 양도차익에 대한 양도소득세만 납부하여 분류과세를 통한 절세효과를 받을 수 있습니다.</li>
                            <li>핵심 임직원에 대한 주식보상을 법인에서는 상여로 처리하여 법인세 절감 효과를 받을 수 있습니다.</li>
                            <li>주식을 부여받은 주주들에게 차등배당을 실행하여 이익이여금을 해소합니다</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <i class="line-wire line-y" style="top:0;right:4.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;right:4.4444vw;width:12vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;left:4.4444vw;width:12vw"></i>
        <i class="line-wire line-y" style="top:12.5vw;left:4.4444vw;height:44.5vw"></i>
        <i class="line-wire line-x" style="top:57vw;left:4.4444vw;width:81vw"></i>
        <i class="line-wire line-y" style="top:57vw;right:14.4444vw;height:405vw"></i>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 자사주 매입 효과 -->
    <section id="effect" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">자사주 매입 효과</h3>
            <div class="cons_info_area area1">
                <p class="tit_cons ml-60">1. 자사주 매입 절세 효과 </p>
                <ul class="list_box">
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust1.png'" alt="">
                        <p class="tit">자사주<br>매입할 경우</p>
                        <p class="desc">기업, 주주, 임직원 모두 절세효과의<br>혜택을 받을 수 있습니다.</p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust2.png'" alt="">
                        <p class="tit">주주나<br>임원의 경우 </p>
                        <p class="desc">20%(중소기업 소액주주의 경우 10%)<br> 단일세율에 의한 과세, 4대 보험료와 관계는<br> 없습니다. 주식의 소유권이 법인으로 변경되어<br> 상속대상 재산에서 제외 되므로 절세가 가능합니다. </p>
                    </li>
                    <li class="item_box">
                        <img class="illust" :src="imgPath+'effect_illust3.png'" alt="">
                        <p class="tit">법인의<br>경우</p>
                        <p class="desc">자기주식 취득 후 처분 시 손실이 발생할<br>경우 법인세 절세 효과가 있습니다. </p>
                    </li>
                </ul>
            </div>
            <div class="cons_info_area mb-100">
                <p class="tit_cons ml-60 mb-100">2. 자사주 매입 기대 효과 </p>
                <img class="img-fluid" :src="imgPath+'effect_calc.png'" alt="">
            </div>
            <div class="cons_info_area area3 mb-20">
                <p class="tit_cons ml-60">3. 자사주 매입을 잘 활용하면 이것이 해결됩니다! </p>
                <ul class="list_box">
                    <li class="item_box">
                        <p class="tit">대표이사<br>가지급금 처리</p>                        
                    </li>
                    <li class="item_box">
                        <p class="tit">미처분<br>이익잉여금 정리</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">가업승계에<br>유리</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">분산주주<br>정리를 통한<br>대주주의결권 강화 </p>
                    </li>
                    <li class="item_box">
                        <p class="tit">투자자금<br>유치에 유리 </p>                        
                    </li>
                    <li class="item_box">
                        <p class="tit">주주의<br>자본환원 </p>                        
                    </li>
                    <li class="item_box">
                        <p class="tit">임직원에게<br>스톡그랜트/<br>스톡옵션 가능 </p>                        
                    </li>
                </ul>
            </div>
        </div>
        <i class="line-wire line-y" style="top:0;right:14.4444vw;height:12.5vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;right:14.4444vw;width:12vw"></i>
        <i class="line-wire line-x" style="top:12.5vw;left:4.4444vw;width:22vw"></i>
        <i class="line-wire line-y" style="top:12.5vw;left:4.4444vw;height:34.5vw"></i>
        <i class="line-wire line-x" style="top:47vw;left:4.4444vw;width:81vw"></i>
        <i class="line-wire line-y" style="top:47vw;right:14.4444vw;height:75vw"></i>
        <i class="line-wire line-x" style="top:122vw;right:14.4444vw;width:81vw"></i>
        <i class="line-wire line-y" style="top:122.5vw;left:4.4444vw;height:75vw"></i>
        <i class="line-wire line-x" style="top:197vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:197vw;right:4.4444vw;height:94vw"></i>
        <i class="line-wire line-x" style="top:291vw;right:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:291vw;left:4.4444vw;height:56vw"></i>
        <i class="line-wire line-x" style="top:347vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:347vw;right:4.4444vw;height:56vw"></i>
        <i class="line-wire line-x" style="top:403vw;right:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:403vw;left:4.4444vw;height:150vw"></i>        
    </section>
    <!-- //자사주 매입 효과 -->

    <!-- 자사주 과세 문제 -->
    <section id="problem" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">자사주 과세 문제</h3>            
            <div class="cons_info_area area1">                
                <ul class="list_box">
                    <li class="item_box">
                        <p class="tit pl-50"><span class="bullet">1.</span>법인이 자기주식을 취득하여 보유하다가 처분하는 경우</p>
                        <div class="img_cont"><img class="illust" :src="imgPath+'problem_illust1.png'" alt="단일세율"></div>
                        <p class="desc">주식을 양도한 주주의 양도차익에 대해서는 다른 소득과 합산되지 않고 분류 과세되며, 그 금액이 3억원 이하인 경우 20%의 단일세율로 과세</p>
                    </li>
                    <li class="item_box">
                        <p class="tit">2. 법인이 취득한 자기주식을 소각하는 경우</p>
                        <div class="img_cont"><img class="illust" :src="imgPath+'problem_illust2.png'" alt="누진세율과제"></div>
                        <p class="desc">주주의 양도차익 상당액에 대해 의제배당으로 과세되어 2천만원 이하인 경우 14% 분리과세로 종결되나, 2천만원 초과되는 경우에는 다른 소득과 합산되어 누진세율로 과세</p>
                    </li>
                    <li class="item_box">
                        <p class="tit pl-50"><span class="bullet">3.</span>자사주 매입 관련 절세 효과를 극대화 시키는 방법</p>
                        <div class="img_cont"><img class="illust" :src="imgPath+'problem_illust3.png'" alt="증여재산공제"></div>
                        <p class="desc">주식을 배우자에게 증여재산공제 6억 범위 내에서 증여한 후 해당 주식을 회사가 자기주식으로 취득하여 소각</p>
                    </li>
                </ul>
            </div>
        </div>
        <i class="line-wire line-y" style="top:0;left:4.4444vw;height:68vw"></i>
        <i class="line-wire line-x" style="top:68vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:68vw;right:4.4444vw;height:89vw"></i>
        <i class="line-wire line-x" style="top:157vw;right:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:157vw;left:4.4444vw;height:102vw"></i>
        <i class="line-wire line-x" style="top:259vw;left:4.4444vw;width:90.5vw"></i>
        <i class="line-wire line-y" style="top:259vw;right:4.4444vw;height:77vw"></i>
        <i class="line-wire line-x line-circle-left" style="top:336vw;right:4.4444vw;width:90.5vw"></i>
    </section>
    <!-- //자사주 과세 문제 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="2" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/purchase/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/purchase.scss';
</style>
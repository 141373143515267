<template>
<!-- main -->
<main id="container">
    <!-- 메인비주얼 -->
    <section id="visual" class="isAppear">
        <div class="inner">
            <h3 class="tit_section">언론에 보도된<br>매경경영지원본부의 전문적인<br>법무 세무 노무 특허 서비스 </h3>
        </div>
        <img class="bg" :src="imgPath+'visual_bg.jpg'" alt="">
    </section>
    <!-- //메인비주얼 -->

    <!-- 전체뉴스 -->
    <section id="allNews" class="isAppear">
        <div class="tab">            
            <button class="btn_tab" type="button" data-value="">전체보기</button>
            <button class="btn_tab" type="button" data-value="자사주매입">자사주매입</button>
            <button class="btn_tab" type="button" data-value="(가족법인활용)자산이전">(가족법인활용)자산이전</button>
            <button class="btn_tab" type="button" data-value="지배구조개선">지배구조개선</button>
            <button class="btn_tab" type="button" data-value="가업승계전략">가업승계전략</button>
            <button class="btn_tab" type="button" data-value="차명주식회수">차명주식회수</button>
            <button class="btn_tab" type="button" data-value="가지급금정리">가지급금정리</button>
            <button class="btn_tab" type="button" data-value="배당정책">배당정책</button>
            <button class="btn_tab" type="button" data-value="개인(임대)사업자법인전환">법인전환</button>
            <button class="btn_tab" type="button" data-value="인증/정책자금/경정청구">인증/정책자금/경정청구</button>
            <button class="btn_tab" type="button" data-value="병/의원컨설팅">병/의원컨설팅</button>
            <button class="btn_tab" type="button" data-value="기타">기타</button>
        </div>
        <ul class="list_news">
            <li class="item_news">
                <a class="link_news">
                    <p class="cate"></p>
                    <p class="date" data-text="writeDate" data-clamp="1"></p>
                    <p class="tit" data-text="title" data-clamp="3"></p>
                    <div class="thumb"></div>
                </a>
            </li>
        </ul>
        <div class="btn_area">
            <button class="btn_more" type="button">더 보기</button>
        </div>
    </section>
    <!-- 전체뉴스 -->
</main ><!-- /main -->
</template>



<script>
    export default {
        data(){
            return {
                imgPath: '/img/media/news/'
            }
        }
    }
</script>




<style scoped lang="scss">
    @import '@/assets/scss/media/news.scss';
</style>
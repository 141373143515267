<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 문제점 -->
    <section id="problem" class="isAppear">
        
    </section>
    <!-- / 문제점 -->

    <!-- 회수방법 -->
    <section id="collect" class="isAppear">
        
    </section>
    <!-- //회수방법 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/hospital/'
        }
    },
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/hospital.scss';
</style>
<template>
<!-- main -->
<main id="container">
    <!-- 메인 -->
    <section id="visual" class="isAppear" data-type="a">
        <div class="inner">
            <div class="contents">
                <div class="text_inner">
                    <h3 class="tit_section">법인전환</h3>
                    <p class="desc_section">법인전환을 하게 되면 정부가 <strong>개인의 고소득세율을 상대적으로 낮은 법인세로 전환하기 위하여 다양한 세제상의 지원</strong>을 하고있습니다.</p>
                </div>
            </div>
          
        </div>
        <div class="div-button-consult">
            <button class="btn_cont" onclick="LAYER_CONT(192)" type="button"><span>법인전환 상담신청</span></button>
        </div>
    </section>
    <!-- //메인 -->

    <!-- 컨설팅 사례 -->
    <section id="example" class="isAppear" data-type="a">
        <div class="inner">
            <h3 class="tit_section"><span>법인전환 컨설팅 사례</span></h3>
            <div class="youtube_contents">
                <button class="videoLayer" data-src="https://www.youtube.com/embed/juH1pFtNaGw" data-title="수익형 임대사업자의 법인전환" data-type="youtube" type="button" title="영상재생">
                    <i class="ico_play2"><img src="" data-json-path="/image/svg/play01.svg" alt=""></i>
                    <img class="thumb" src="https://img.youtube.com/vi/juH1pFtNaGw/maxresdefault.jpg" alt="스크린샷">                
                </button>
            </div>
            <div class="list_talk">
                <div class="item_talk left">
                    <p class="tit_talk">의뢰인</p>
                    <img class="profile" src="/img/consulting/collection/example_talk_profile_client1.jpg" alt="">
                    <div class="tooltip">
                        <p class="tit">컨설팅 문의 </p>
                        <p>강남구 소재의 중소형빌딩을 20년전에 취득 후 양도, 상속, 증여 등의 다각도의 절세방안을 검토하였으나, 현물출자방식의 임대법인전환이 가장 합리적이라고 판단하여 이에 따른 방법과 절차에 대해 알고 싶습니다.</p>
                    </div>
                </div>
                <div class="item_talk right">
                    <p class="tit_talk">컨설팅 전문가</p>
                    <img class="profile" :src="imgPath+'example_talk_profile_professional1.jpg'" alt="">
                    <div class="tooltip">
                        <p class="tit">해결방법</p>
                        <ol>
                            <li>임대용 부동산의 토지 및 건물의 가치를 감정평가사의 감정절차를 통해 평가, 회계사의 감사 및 결산이라는 절차를 통하여 신설법인을 설립하였습니다.</li>
                            <li>법원의 설립인가를 위한 법무사의 법적인 실무작업을 동시에 진행하였습니다.</li>
                            <li>지원본부의 세무사, 감정평가사, 법무사와 팀을 이뤄 현물출자 법인전환을 통해 개정된 주식가치 평가 방법과 이월된 양도소득세가 주식 가치 평가에 영향을 주어 주식가치가 하락하고 절대적인 상속재산의 가치를 평가절하 함으로 상속세 절세효과를 기대하게 되었습니다.</li>
                        </ol>
                    </div>
                </div>                
            </div>
        </div>
    </section>
    <!-- //컨실팅 사례 -->

    <!-- 대상기업 -->
    <section id="target" class="isAppear">
        <div class="inner">
            <h3 class="tit_section"><span>법인전환 검토 대상기업</span></h3>
            <div class="cons_info_area area1 except">
                <p class="tit_cons">1. 지원 요건</p>
                <ul class="list_target isAppear">
                    <li class="item_target line">
                        <p><strong>성실신고 확인제도 대상 기업</strong>으로 세금부담이 크고, 과세당국의 중점관리 대상이 되는 기업</p>    
                    </li>
                    <li class="item_target">
                        <p>가업승계에 따라 상속세와 증여세 부담이 큰 기업</p>    
                    </li>
                    <li class="item_target line">
                        <p>기업 소유 부동산 가격이 급격하게 상승했거나 예정이어서, 양도소득세 부담이 큰 기업</p>
                    </li>
                    <li class="item_target illust"><img :src="imgPath+'target_bg.png'" alt=""> 
                        <p>순이익과 사업소득 이외에 배당소득, 임대소득 등 타 소득이 높아 종합소득세 부담이 큰 기업</p>
                    </li>
                    <li class="item_target">
                        <p>임대사업자로 가업승계를 하려고 하나 가업상속공제 대상에서 제외되어 있는 기업</p>
                    </li>
                    <li class="item_target">
                        <p>금융권 대출이나 정부기관 입찰 참여를 위해 대외 신용도를 높이고자 하는 기업</p>
                    </li>
                    <li class="item_target line">
                        <p>정부 정책자금 지원과 기타 고용지원 정책의 혜택을 받고자 하는 기업</p>
                    </li>
                </ul>                
            </div>
            <div class="cons_info_area area2">
                <p class="tit_cons text-center">
                    <span><strong class="color-203875">성실신고확인대상</strong> 수입기준 금액 조정</span>
                </p>
                <table class="table table-navy">
                    <colgroup>
                        <col style="width:auto;">
                        <col style="width:auto;">
                        <col style="width:auto;">
                        <col style="width:auto;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>구분</th>
                            <th>농업,<br>도소매업</th>
                            <th>제조업,<br>건설업 </th>
                            <th>서비스업 </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>2017년</td>
                            <td>20억원<br>이상</td>
                            <td>10억원<br>이상</td>
                            <td>5억원<br>이상</td>
                        </tr>
                        <tr>
                            <td>2018년~<br>2019년</td>
                            <td>15억원<br>이상</td>
                            <td>7억 5천원<br>이상</td>
                            <td>5억원<br>이상</td>
                        </tr>
                        <tr>
                            <td>2020년</td>
                            <td>10억원<br>이상</td>
                            <td>5억원<br>이상</td>
                            <td>3억 5천원<br>이상</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="cons_info_area area3">
                <p class="tit_cons">2. 개인사업자 법인전환 방법 </p>
                <img class="img-fluid" :src="imgPath+'target_graph.png'" alt="">
            </div>
            <div class="cons_info_area area4">
                <div class="box_checkpoint">
                    <p class="tit">개인사업자 법인전환 <strong><img class="ico" src="" data-json-path="/image/svg/check01.svg">체크 포인트</strong></p>
                    <div class="list_desc">
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg"><span class="d-block">현재 처한 회사의 사정에 따라 이익이 되는지를 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg"><span class="d-block">세금 부담은 어떻게 달라지는지 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg"><span class="d-block">회사의 업무 특성과 부합하는지 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg"><span class="d-block">기업의 자금 여건을 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg"><span class="d-block">향후 매출을 고려해야 합니다.</span></p>
                        <p class="desc"><img class="ico" src="" data-json-path="/image/svg/check01.svg"><span class="d-block">비용 등을 고려해야 합니다.</span></p>
                    </div>
                    <img class="clip" src="/img/common/ico/clip.png" alt="">                    
                </div>
            </div>
        </div>
    </section>
    <!-- //대상기업 -->

    <!-- 사업자 -->
    <section id="business" class="isAppear">
        <div class="inner">
            <h3 class="tit_section"><span>개인사업자 VS 법인사업자</span></h3>
            <div class="cons_info_area area1">
                <div class="item_cont">
                    <p class="tit_cons">1. 개인사업자 VS 법인사업자 </p>
                    <div class="img_cont">
                        <img class="tit_img" :src="imgPath+'business_tit_corp.png'" alt="">
                    </div>
                    <div class="item">
                        <p class="tit">설립절차와 비용</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>법원 설립등기 절차가 필요하며 자본금과 등록면허세 등의 설립비용이 소요됨</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">사업의 책임성과 신뢰도</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>법인의 주주는 출자나 지분한도 내에서만 책임을 지고 개인에 비래 대외신뢰도가 높음</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">자금조달과 이익분배</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>주식발행이나 회사채 발행을 통해 자금을 조달</p>
                            <p><i class="bullet">•</i>배당을 통해 이익이 분배됨</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">소득처분</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>세무조정금액이 사외로 유출된 경우</p>
                            <p><i class="bullet">•</i>귀속자의 소득이로 처분하여 귀속자의 소득세 납세의무 유발</p>
                            <p><i class="bullet">•</i>무조정금액이 시내에 남아 있는 경우 </p>
                            <p><i class="bullet">•</i>유보로 처분하여 관리</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">대표자/사업자 급여</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>손금인정</p>
                            <p><i class="bullet">•</i>대표에게 근로소득세부과</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">대표자/사업자 퇴직급여</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>퇴직급여 충당금의 설정 가능</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">가업승계 시 자산평가</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>지분승계로 비상장주식 평가법 적용</p>
                        </div>
                    </div>
                </div>
                <div class="item_cont">
                    <div class="img_cont">
                        <img class="tit_img" :src="imgPath+'business_tit_person.png'" alt="">
                    </div>
                    <div class="item">
                        <p class="tit">설립절차와 비용</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>설립철차가 비교적 쉽고, 비용이 적어 사업규모나 자본이 적은 사업에 유리</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">사업의 책임성과 신뢰도</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>경영상 발생하는 모든 문제와 부채손실에 대한 위험은 전적으로 사업주 혼자 책임</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">자금조달과 이익분배</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>자본조달에 한계</p>
                            <p><i class="bullet">•</i>발생이익 활용에 제약 없음</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">소득처분</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>세무조정금액이 사외로 유출된 경우</p>
                            <p><i class="bullet">•</i>귀속자의 소득으로 처분하지 않고 사업주가 인출하여 증여한 걷으로 봄</p>
                            <p><i class="bullet">•</i>세무조정금액이 기업내부에 남아있는 경우 </p>
                            <p><i class="bullet">•</i>유보로 처분하여 유보소득조정 명세서에서 관리함</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">대표자/사업자 급여</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>필요경비 불 산입</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">대표자/사업자 퇴직급여</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>퇴직급여 충당금의 설정 불가</p>
                        </div>
                    </div>
                    <div class="item">
                        <p class="tit">가업승계 시 자산평가</p>
                        <div class="desc">                        
                            <p><i class="bullet">•</i>시가(실거래가)</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="cons_info_area area2">
                <p class="tit_cons"> <span>2. 개인사업자 VS 법인사업자 세율 비교 </span> </p>
                <table class="table table-navy">
                    <colgroup>
                        <col style="width:auto;">
                        <col style="width:auto;">
                        <col style="width:auto;">
                        <col style="width:auto;">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>소득구간</th>
                            <th>개인사업자<br>세율</th>
                            <th>소득구간</th>
                            <th>법인사업자<br>세율</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1,200만<br>이하</td>
                            <td>6%</td>
                            <td>2억원<br>이하</td>
                            <td>10%</td>
                        </tr>
                        <tr>
                            <td>4,600만<br>이하</td>
                            <td>15%</td>
                            <td>2억 ~ 200억원<br>이하</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td>8,800만<br>이하</td>
                            <td>24%</td>
                            <td>200억원<br>이하</td>
                            <td>22%</td>
                        </tr>
                        <tr>
                            <td>1억 5천만<br>이하</td>
                            <td>35%</td>
                            <td>3000억원<br>초과</td>
                            <td>25%</td>
                        </tr>
                        <tr>
                            <td>3억 이하</td>
                            <td>38%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>5억 이하</td>
                            <td>40%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                        <tr>
                            <td>5억 이상</td>
                            <td>42%</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="cons_info_area area3">
                <p class="tit_cons"><span>3. 개인사업자 법인전환 방법별 주요 세금</span></p>
                <div class="table_cont">
                    <table class="table table-navy">
                        <colgroup>
                            <col style="width:auto;">
                            <col style="width:auto;">
                            <col style="width:auto;">
                            <col style="width:auto;">
                        </colgroup>
                        <thead class="thead-multiline">
                            <tr>
                                <th rowspan="2">구분</th>
                                <th colspan="3">법인전환방법</th>
                            </tr>
                            <tr>
                                <th>세 감면<br>포괄양수도</th>
                                <th>현물출자</th>
                                <th>일반사업<br>양수도</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>부가가치세</th>
                                <td>과세제외</td>
                                <td>과세제외</td>
                                <td>과세</td>
                            </tr>
                            <tr>
                                <th>양도소득세</th>
                                <td>이월과세</td>
                                <td>이월과세</td>
                                <td>과세</td>
                            </tr>
                            <tr>
                                <th>부동산 등<br>취득세</th>
                                <td>면제</td>
                                <td>면제</td>
                                <td>과세</td>
                            </tr>
                            <tr>
                                <th>차량 등<br>취득세</th>
                                <td>면세</td>
                                <td>면세</td>
                                <td>과세</td>
                            </tr>
                            <tr>
                                <th>법인성립 등록면허세</th>
                                <td>과세</td>
                                <td>과세</td>
                                <td>과세</td>
                            </tr>
                            <tr>
                                <th>국민주택<br>채권</th>
                                <td>적액매입</td>
                                <td>적액매입</td>
                                <td>적액매입</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </section>
    <!-- //사업자 -->

    <!-- 컨설팅 프로세스 -->
    <consultingProcess data-list-idx="1" data-title="컨설팅 프로세스" />
    <!-- //컨설팅 프로세스 -->

    <!-- 전문가 네트워크 -->
    <networkTab class="is-event-none" />
    <!-- //전문가 네트워크 -->

    <!-- 무료 컨설팅 상담신청 -->
    <section id="consulting" class="isAppear"></section>
    <!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/conversion/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/conversion.scss';
</style>
<template>
    <main id="container">
        <!-- 신청자 정보 -->
        <section id="info">
            <div class="inner">
                <h3 class="tit_section"></h3>
                <p class="desc_section"></p>
                <ul class="list_info consult">
                    <li class="item_info"><strong class="type">상담분야 :</strong><span class="info_type"></span></li>
                    <li class="item_info"><strong class="type">지역 :</strong><span class="info_region"></span></li>
                    <li class="item_info"><strong class="type">컨설팅 안내 받을 연락처 :</strong><span class="info_phone"></span></li>
                    <li class="item_info"><strong class="type">신청자 성함 :</strong><span class="info_name"></span></li>
                    <li class="item_info"><strong class="type">회사명 :</strong><span class="info_corpName"></span></li>
                </ul>
                <ul class="list_info seminar">
                    <li class="item_info"><strong class="type">세미나</strong><span class="info_seminar"></span></li>
                    <li class="item_info"><strong class="type">회사명</strong><span class="info_corpName"></span></li>
                    <li class="item_info"><strong class="type">사업자등록번호</strong><span class="info_corpNumber"></span></li>
                    <li class="item_info"><strong class="type">참가자 이름</strong><span class="info_name"></span></li>
                    <li class="item_info"><strong class="type">휴대폰 번호</strong><span class="info_phone"></span></li>
                </ul>
                <div class="btn_area">
                    <button class="btn_back" type="button">이전 화면으로 이동</button>
                </div>
            </div>
        </section>
        <!-- //신청자 정보 -->
    </main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/etc/complete/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/etc/complete.scss';
</style>
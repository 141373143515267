<template>
<!-- main -->
    <main id="container">
        <!-- 메인 -->
        <section id="visual" class="isAppear" data-type="b">
            <div class="inner">
                <h2>배당정책</h2>
                <p class="desc_section">
                    배당은 소유와 경영이 분리된 <b>주식회사에서 소유주가</b><br>
                    <b>이익금을 회수하는 주요 방법</b> 이자 절세를 위한<br>
                    효과적인 도구이므로 이를 적극적으로 활용할<br>
                    필요가 있습니다.
                </p>
                <div class="div-button-consult">
                    <button class="btn_cont" onclick="LAYER_CONT(191)" type="button">
                        <span>배당정책 상담신청</span>
                        <i></i>
                    </button>
                </div>
            </div>
        </section>
        <!-- //메인 -->

        <!-- 컨설팅 사례 -->
        <section id="example" class="isAppear" data-type="b">
            <h3 class="tit_section">배당정책 컨설팅 사례</h3>
            <button class="videoLayer" data-src="https://www.youtube.com/embed/8OqQSUAW2_A" data-title="법인의 배당정책 활성화는 필수" data-type="youtube" type="button" title="영상재생">
                <img class="thumb" src="https://img.youtube.com/vi/8OqQSUAW2_A/maxresdefault.jpg" alt="스크린샷">
            </button>
            <div class="inner">
                <div class="list_talk">
                    <div class="item_talk">
                        <img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
                        <div class="tooltip">
                            <p class="tit"><strong>컨설팅 문의</strong></p>
                            <p>가업승계 전 소액주주의 지분 정리와 자녀, 손자들 명의로 주식 이동을 희망합니다.</p>
                        </div>
                    </div>
                    <div class="item_solution">
                        <p class="item_tit"><strong>해결방법</strong></p>
                        <div class="item_answer">
                            <p>대표이사가 보유한 주식의 일부를 비과세 범위내에서 3명의 손주들에게 증여하였으며 자녀들과 손주들의 주식양수대금은 차등배당으로 해결하였습니다.</p>
                            <p>주식가치평가를 통해 시가 산정 후 주식양수도 계약을 체결하였습니다.</p>
                            <p>매매당사자의 관계가 특수관계자 여부에 따라 적합한 범위 내 주식 가격 인하가 가능했습니다.</p>
                            <p>지원본부 소속의 전문가 그룹인 세무사, 변호사와 팀을 이루어 증여세, 양도세, 차등배당 소득세 신고 및 납부하였습니다.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- /컨설팅 사례 -->

        <!-- 효과 -->
        <section id="effect">
            <h3 class="tit_section">배당정책 효과</h3>
            <div class="inner">
                <div class="effect_list">
                    <div class="effect_item isAppear">
                        <p class="no-number">배당은 소유와 경영이 분리된 주식회사에서  <strong>소유주가 이익금을 회수하는 주요 방법이자 절세를 위한 효과적인 도구</strong> 이므로 이를 적극적으로 활용할 필요가 있습니다.</p>
                        <div class="thumb_list">
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="소득분산 및  자금출처 확보" :src="imgPath+'policy_b_effect_img01.jpg'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">소득분산 및<br>자금출처 확보</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="절세효과" :src="imgPath+'policy_b_effect_img02.jpg'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">절세효과</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="중간배당 가능" :src="imgPath+'policy_b_effect_img03.jpg'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">중간배당 가능</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="가업승계와 상속플랜으로 연결 가능" :src="imgPath+'policy_b_effect_img04.jpg'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">가업승계와 <br> 상속플랜으로 연결 가능</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="가지급금정리 솔루션으로 적용 가능" :src="imgPath+'policy_b_effect_img05.jpg'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">가지급금정리 <br> 솔루션으로 적용 가능</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="비상장주식의 주가관리 가능" :src="imgPath+'policy_b_effect_img06.jpg'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">비상장주식의 <br> 주가관리 가능</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- / 효과 -->

        
        <!-- 종류 및 활용 -->
        <section id="kind">
            <h3 class="tit_section">배당정책 종류 및 활용 </h3>
            <div class="inner">
                <div class="kind_list">
                    <div class="kind_item isAppear">
                        <p><strong>배당정책 종류 및 활용</strong></p>
                        <div class="thumb_list">
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="차등배당 플랜" :src="imgPath+'policy_b_kind_img01.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">차등배당 플랜</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="중간배당 플랜" :src="imgPath+'policy_b_kind_img02.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">중간배당 플랜</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="Step-Up배당 플랜" :src="imgPath+'policy_b_kind_img03.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">Step-Up배당 플랜</div>
                                </div>
                            </div>
                            <div class="thumb_item">
                                <div class="thumb">
                                    <img alt="명의신탁해지 배당" :src="imgPath+'policy_b_kind_img04.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">명의신탁해지 배당</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kind_item isAppear">
                        <p><strong>배당정책 활용 목적</strong></p>
                        <div class="thumb_list">
                            <div class="thumb_item type">
                                <div class="thumb">
                                    <img alt="법인자금 회수" :src="imgPath+'policy_b_kind_img05.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">법인자금 회수</div>
                                </div>
                            </div>
                            <div class="thumb_item type">
                                <div class="thumb">
                                    <img alt="가업승계를 위한 사전준비" :src="imgPath+'policy_b_kind_img06.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">가업승계를<br>위한 사전준비</div>
                                </div>
                            </div>
                            <div class="thumb_item type">
                                <div class="thumb">
                                    <img alt="상속 및 증여세, 양도소득세 절세전략의 일환" :src="imgPath+'policy_b_kind_img07.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">상속 및 증여세,<br>양도소득세<br>절세전략의 일환</div>
                                </div>
                            </div>
                            <div class="thumb_item type">
                                <div class="thumb">
                                    <img alt="주가관리 목적" :src="imgPath+'policy_b_kind_img08.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">주가관리 목적</div>
                                </div>
                            </div>
                            <div class="thumb_item type">
                                <div class="thumb">
                                    <img alt="대표의 경영리스크 분산" :src="imgPath+'policy_b_kind_img09.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">대표의<br> 경영리스크 분산</div>
                                </div>
                            </div>
                            <div class="thumb_item type">
                                <div class="thumb">
                                    <img alt="주주의 배당압력 해소" :src="imgPath+'policy_b_kind_img10.png'">
                                </div>
                                <div class="text">
                                    <div class="text_cnt">주주의<br>배당압력 해소</div>
                                </div>
                            </div>
                       </div>
                    </div>
                    <div class="kind_item isAppear">
                        <div class="checkpoint">
                            <p>배당정책 활용 시<i>&nbsp;Check point</i></p>
                            <ol>
                                <li>
                                    <p>Check&nbsp;</p>
                                    <div class="check_text">
                                        배당정책 활용 시 법에서 정한 요건을 충족하고<br> 있는지 고려해야 합니다. 적법한 규정과 절차<br> 준수 여부를 확인해야 합니다.
                                    </div>
                                </li>
                                <li>
                                    <p>Check&nbsp;</p>
                                    <div class="check_text">
                                        적법한 규정과 절차 준수 여부를 확인해야 합니다.
                                    </div>
                                </li>
                                <li>
                                    <p>Check&nbsp;</p>
                                    <div class="check_text">
                                        지분설계, 절세, 배당가능금액 등의 철저한 분석<br>여부를 확인해야 합니다.
                                    </div>
                                </li>
                                <li>
                                    <p>Check&nbsp;</p>
                                    <div class="check_text">
                                        법인정관에 배당 관련 사항 명시 여부를<br>확인해야 합니다.
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- / 효과 -->

        <!-- 컨설팅 프로세스 -->
        <consultingProcess_b data-type="1" data-list-count="7" />
        <!-- //컨설팅 프로세스 -->

        <!-- 전문가 네트워크 -->
        <networkTab class="is-event-none" />
        <!-- //전문가 네트워크 -->

        <!-- 무료 컨설팅 상담신청 -->
        <section id="consulting" class="isAppear"></section>
        <!-- //무료 컨설팅 상담신청 -->
    </main ><!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/consulting/policy_b/'
        }
    }
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/consulting/policy_b.scss';
</style>
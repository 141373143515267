<template>
<!-- header -->
<header>
    <h1 class="ci" title="매경경영지원본부">
        <a class="link_ci" href="/" title="메인으로 이동하기"><img class="ci" src="/img/common/logo/mkbiz_basic.svg" alt=""></a>
        <a class="link_symbol" href="/" title="메인으로 이동하기"><img class="symbol" src="/img/common/logo/mkbiz_symbol.svg" alt=""></a>
    </h1>
    <button type="button" class="toggleMenu"><i></i><i></i><i></i></button>

    <!-- GNB -->
    <div class="gnb_layer">
        <div class="top clearfix">
            <a class="link_home" href="/" title="메인으로 이동하기"><img class="ico" src="/img/common/ico/home.svg" alt=""></a>
            <button class="btn_cont float-start" onclick="LAYER_CONT()" type="button">
                <img class="tit" src="/img/main/visual_cont_tit.png">
            </button>
        </div>
        <nav class="wrap_gnb">
            <div class="cate_area">
                <a class="tit_cate" data-text="title" href="javascript:void(0)" data-alt="title"></a>
                <ul class="list_menu">
                    <li class="item_menu"><a class="link_menu"></a></li>
                </ul>
            </div>
        </nav>
        <div class="bottom clearfix">
            <a class="btn_kakao float-end" href="http://pf.kakao.com/_HzxfKs/chat" target="_blank" title="카카오 상담채널">
                <img src="/img/common/ico/btn_kakao.png" alt="">
            </a>
            <a class="link_sns facebook float-end" href="//www.facebook.com/mkbizkr" target="_blank"><img class="ico" src="" data-json-path="/image/sns/facebook02.svg" alt=""></a>
            <a class="link_sns blog float-end" href="//blog.naver.com/mkbizkr " target="_blank"><img class="ico" src="" data-json-path="/image/sns/naver02.svg" alt=""></a>
        </div>
        <div class="form_layer">
            <div class="step_header">
                <p class="tit_layer">무료 컨설팅 상담신청</p>
                <button type="button" class="prve-step hidden" title="이전"></button>
            </div>
            <nav class="step_bar">
                <i></i>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </nav>
            <div class="step_contents">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide step_one">
                            <div class="inner">
                                <p class="slide_title">상담분야 선택</p>
                                <ul class="flex_ul_css ">
                                    <li class="li_purchase" data-consultId="238">
                                        <label>
                                            <i></i>
                                            <span>자사주매입</span>
                                        </label>
                                    </li>
                                    <li class="li_assets" data-consultId="246">
                                        <label>
                                            <i></i>
                                            <span>(가족법인활용)<br>자산이전</span>
                                        </label>
                                    </li>
                                    <li class="li_restructure" data-consultId="239">
                                        <label>
                                            <i></i>
                                            <span>지배구조개선</span>
                                        </label>
                                    </li>
                                    <li class="li_strategy" data-consultId="193">
                                        <label>
                                            <i></i>
                                            <span>가업승계전략</span>
                                        </label>
                                    </li>
                                    <li class="li_collection" data-consultId="189">
                                        <label>
                                            <i></i>
                                            <span>차명주식회수</span>
                                        </label>
                                    </li>
                                    <li class="li_payment" data-consultId="190">
                                        <label>
                                            <i></i>
                                            <span>가지급금정리</span>
                                        </label>
                                    </li>
                                    <li class="li_policy" data-consultId="191">
                                        <label>
                                            <i></i>
                                            <span>배당정책</span>
                                        </label>
                                    </li>
                                    <li class="li_conversion" data-consultId="192">
                                        <label>
                                            <i></i>
                                            <span>법인전환</span>
                                        </label>
                                    </li>
                                    <li class="li_certify" data-consultId="247">
                                        <label>
                                            <i></i>
                                            <span>인증/정책자금/<br>경정청구</span>
                                        </label>
                                    </li>
                                    <li class="li_hospital" data-consultId="248">
                                        <label>
                                            <i></i>
                                            <span>병/의원컨설팅</span>
                                        </label>
                                    </li>
                                    <li class="li_etc" data-consultId="240">
                                        <label>
                                            <i></i>
                                            <span>기타</span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="swiper-slide step_two">
                            <div class="inner">
                                <p class="slide_title">지역 선택</p>
                                <ul class="flex_ul_css">
                                    <li>
                                        <input type="radio" name="region" data-value="/code"  data-attr-fullname="/name" data-attr-code="/code" value="" data-id="region">
                                        <label>
                                            <span data-text="/shortName"></span>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="swiper-slide step_third">
                            <div class="inner">
                                <div class="loadform" data-load="false">
                                    <li class="hidden row row_css row_type"  data-row="type" data-check="true" data-check-pass="false" data-check-prop="consultType" data-inbound-prop="consultCodeId" data-inbound-value="" data-check-comment="상담분야를 선택하세요.">
                                        <span>상담분야</span>
                                    </li>
                                    <li class="hidden row row_css row_region" data-row="region" data-check="true" data-check-pass="false" data-check-prop="region" data-inbound-prop="region" data-inbound-value="" data-check-comment="지역을 선택해주세요." >
                                        <span>지역선택</span>
                                    </li>
                                </div>
                                <button type="button" class="fake_submit"><i>회사명 완료</i></button>
                            </div>
                            <!-- <button type="button" class="fake_submit"><i>회사명 완료</i></button> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="swiper-pagination"></div> -->
            </div>
        </div>
    </div>

</header>
<!-- header -->
</template>



<style lang="scss">
</style>



<script>
    export default {}
</script>



<template>
<!-- main -->
<main id="container">
	<!-- 메인 -->
	<section id="visual" class="isAppear" data-type="b">
		<div class="inner">
			<h2>지배구조개선</h2>
			<p class="desc_section">
				비상장기업 지배구조란 <b>기업의 소유권 구조에</b><br>
				<b>큰 변화가 발생</b>하는 내용 등경영권에 대한 내용들이<br>
				대표적인 지배구조와 관련된 오너 CEO 리스크들입니다. 
			</p>
			<div class="div-button-consult">
				<button class="btn_cont" onclick="LAYER_CONT(239)" type="button">
					<span>지배구조개선 상담신청</span>
					<i></i>
				</button>
			</div>
		</div>
	</section>
	<!-- //메인 -->

    <!-- 컨설팅 사례 -->
     <section id="example" class="isAppear" data-type="b">
        <h3 class="tit_section">지배구조개선 컨설팅 사례</h3>
        <button class="videoLayer" data-src="https://www.youtube.com/embed/RlJijOS9kXY" data-title="지배구조 개선을 통한 중소기업 대표의 리스크 해결!" data-type="youtube" type="button" title="영상재생">
            <img class="thumb" src="https://img.youtube.com/vi/RlJijOS9kXY/maxresdefault.jpg" alt="스크린샷">
        </button>
        <div class="inner">
            <div class="list_talk">
                <div class="item_talk">
                    <img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
                    <div class="tooltip">
                        <p class="tit"><strong>문제점</strong></p>
                        <p>안산에서 제조업을 영위하는 A사의 김모 대표는 사업 확장을 위해 투자를 해야 할 상황으로 당초 본인이 증자하고 일부 차입을 더해 기존의 A사 명의로 투자를 하려고 하는데 이 방법이 맞는지 의뢰를 하였습니다.</p>
                    </div>
                </div>
                <div class="item_solution">
                    <p class="item_tit"><strong>해결방법</strong></p>
                    <div class="item_answer">
                        <p>가업승계 고려 시 투자로 기업가치를 높이는 것은 자금부담과 세금부담이 증가됩니다.</p>
						<p>자녀를 대주주로 하는 신설법인을 설립하고 신설법인 명의로 하여 투자를 집행하는 것이 유용합니다.</p>
						<p>가업승계 고려 시 주주구성을 변화시키는 것보다 기업지배구조 개선에 더 효과적입니다.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /컨설팅 사례 -->

	<!-- 회수방법 -->
	<section id="structure">
		<h3 class="tit_section">최적의 기업지배구조</h3>
		<div class="inner">
			<div class="structure_list">
				<div class="structure_item isAppear">
					<div class="process_item">
						<div class="process">
							<ul>
								<li>
									<div class="thumb">
										<img alt="자본구조 " :src="imgPath+'restructure_b_structure_img_01.png'">
									</div>
									<p>
										<span>자본구조</span>
									</p>
								</li>
								<li>
									<div class="thumb">
										<img alt="임원 및 회사의 기관구성" :src="imgPath+'restructure_b_structure_img_02.png'">
									</div>
									<p>
										<span>임원 및 회사의 기관구성</span>
									</p>
								</li>
								<li>
									<div class="thumb">
										<img alt="주주구성" :src="imgPath+'restructure_b_structure_img_03.png'">
									</div>
									<p><span>주주구성</span></p>
								</li>
								<li>
									<div class="thumb">
										<img alt="이익금회수방안" :src="imgPath+'restructure_b_structure_img_04.png'">
									</div>
									<p><span>이익금회수방안</span></p>
								</li>
							</ul>
						</div>
					</div>
					<div class="arrow_obj isAppear">
						<div class="arrow_box">
							<img alt="" :src="imgPath+'restructure_b_structure_arrow.png'">
						</div>
						<div class="text_box">
							<div class="txt">
								이 과정에서 비상장사의 특성상 외부 이해관계자는<br>
								적으므로 소액주주 보호, 내부 감시 및 통제 등에 대한<br>
								리스크 요인보다는 세금과 관련된 리스크 요인이 더 큰<br>
								비중을 차지하므로 절세측면이 중요하게 고려되어야 합니다.
							</div>
						</div>
					</div>
					<div class="tip">
						<div class="circle_tip">TIP</div>
						<div class="tip_box">
							<p>
								가업승계를 위해서는 새롭게 시작하는 성장성 있는<br>
								사업을 자녀 명의의 법인으로 하는 것이 <br>
								유리한 이유는 추후 가업승계 시 부모님의 지분을 <br>
								인수할 때 자녀명의의 법인을 활용하는 것이 <br>
								절세나 자금조달/활용 측면에서 유리한 점이 있습니다.
							</p>
						</div>
					</div>
				</div>
				<div class="structure_item isAppear">
					<div class="checkpoint">
						<p>기업지배구조개선 처리 시<i>&nbsp;Check point</i></p>
						<ol>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									세금과 비용을 절감할 수 있느냐가 중요합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									합법적인 절차와 규정을 준수해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									신설회사 분할에 대한 국세청 소명 요구 대응<br> 전략에 대한 수립 여부를 확인해야 합니다.
								</div>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- / 회수방법 -->


	<!-- 문제점 -->
	<section id="effect">
		<h3 class="tit_section">개선 이유 및 기대효과</h3>
		<div class="inner">
			<div class="effect_list">
				<div class="effect_item isAppear">
					<p><strong>지주회사를 통한 기업지배구조 개선이 필요한 이유</strong></p>
					<div class="thumb_item">
						<div class="thumb">
							<img alt="가업승계를 위해 법인의 지배구조개선이 필요" :src="imgPath+'restructure_b_effect_img_01.jpg'">
						</div>
						<div class="text">
							<div class="text_cnt">가업승계를 위해 법인의<br> 지배구조개선이 필요</div>
						</div>
					</div>
					<div class="thumb_item">
						<div class="thumb">
							<img alt="비상장주식 가치가 높아 가업승계가 어려운 이유" :src="imgPath+'restructure_b_effect_img_02.jpg'">
						</div>
						<div class="text">
							<div class="text_cnt">비상장주식 가치가 높아 <br> 가업승계가 어려운 이유</div>
						</div>
					</div>
					<div class="thumb_item">
						<div class="thumb">
							<img alt="자회사를 취득해야 하는 경우" :src="imgPath+'restructure_b_effect_img_03.jpg'">
						</div>
						<div class="text">
							<div class="text_cnt">자회사를 취득해야 하는 경우</div>
						</div>
					</div>
				</div>
				<div class="effect_item isAppear">
					<p><strong>기업지배구조개선을 위한 지주회사 기대효과</strong></p>
					<div class="thumb_item">
						<div class="thumb">
							<img alt="대주주 지분율 증가로 인해 기업의 지배력 강화 가능" :src="imgPath+'restructure_b_effect_img_04.jpg'">
						</div>
						<div class="text">
							<div class="text_cnt">대주주 지분율 증가로 인해 기업의 지배력 강화 가능</div>
						</div>
					</div>
					<div class="thumb_item">
						<div class="thumb">
							<img alt="경영권 방어 가능" :src="imgPath+'restructure_b_effect_img_05.jpg'">
						</div>
						<div class="text">
							<div class="text_cnt">경영권 방어 가능</div>
						</div>
					</div>
					<div class="thumb_item">
						<div class="thumb">
							<img alt="세 부담을 줄인 성공적인 가업승계 가능" :src="imgPath+'restructure_b_effect_img_06.jpg'">
						</div>
						<div class="text">
							<div class="text_cnt">세 부담을 줄인<br> 성공적인 가업승계 가능</div>
						</div>
					</div>
				</div>
				<div class="effect_item isAppear">
					<p><strong>주식회사의 지분율에 따른 주주권리<br>(상법상 비상장회사 기준)</strong></p>
					<div class="contents">
						<p class="isAppear">
							※ 주식회사의 총발행주식에 대하여 특정주주가 소유하고<br>
								&nbsp;&nbsp;&nbsp;&nbsp;있는 주식의 비율을 말하며 지주비율이라고도 한다.
						</p>
						<ul>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_1.png">
								</div>
								<p>
									<strong>1주</strong>
									<span>의결권, 주총하자에<br>대한 제소권</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_2.png">
								</div>
								<p>
									<strong>주식 1%</strong>
									<span>대표이사 소송권</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_3.png">
								</div>
								<p>
									<strong>주식 3%</strong>
									<span>지배주주의 권한 남용 견제,<br>위법행위 감시 및 통제</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_4.png">
								</div>
								<p>
									<strong>주식 25%</strong>
									<span>단독 출석시<br>보통결의사항 통과<br>(이사선임, 이익배당,<br>재무제표 승인)</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_5.png">
								</div>
								<p>
									<strong>주식 33.4%</strong>
									<span>단독 출석시<br>특별결의사항 통과<br>(정관변경, 회사의 합병,<br>이사의 해임 등)</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_6.png">
								</div>
								<p>
									<strong>주식 50% + 1주</strong>
									<span>보통결의사항 통과<br>(표결로 이길 수 있는 지분율)</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_7.png">
								</div>
								<p>
									<strong>주식 66.7%</strong>
									<span>특별결의사항 통과<br>(표결로 이길 수 있는 지분율)</span>
								</p>
							</li>
							<li class="isAppear">
								<div class="thumb">
									<img alt="" src="/img/consulting/restructure_b/effect_img_1_8.png">
								</div>
								<p>
									<strong>주식 100%</strong>
									<span>1인 주주</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="effect_item isAppear">
					<p><strong>비상장주식가치 평가</strong></p>
					<div class="contents">
						<p class="isAppear">
							비상장법인의 주식가치 평가방법은 ‘상속세 및 증여세법<br>
							시행령 제54조(비상장주식의 평가)’에 명시된<br>
							‘순손익가치’와 ‘순자산가치’의 가중 평균에 의해 평가
						</p>
						<div class="img_area isAppear">
							<img alt="" src="/img/consulting/restructure_b/effect_img_2.png">
						</div>
					</div>
				</div>
				<div class="effect_item isAppear">
					<p><strong>비상장주식가치 평가 활용 예시 : 임원(CEO)</strong></p>
					<div class="contents">
						<div class="img_area">
							<img class="isAppear" alt="" src="/img/consulting/restructure_b/effect_img_3_1.png">
							<img class="isAppear" alt="" src="/img/consulting/restructure_b/effect_img_3_2.png">
							<img class="isAppear" alt="" src="/img/consulting/restructure_b/effect_img_3_3.png">
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- / 문제점 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-type="3" data-list-count="6" data-count-bool="false" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="isAppear"></section>
	<!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/restructure_b/'
		}
	}
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/restructure_b.scss';
</style>
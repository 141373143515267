<template>
<!-- main -->
<main id="container">
    <!-- 글보기 -->
    <section id="boardView" class="isAppear">
        <div class="board_view">
            <div class="view_tit">
                <p class="cate" data-category="진행중"></p>
                <p class="tit"></p>
                <p class="date"></p>
            </div>
            <div class="view_cont">
                <div class="content">
                    <!-- 내용 -->
                </div>
                <!-- render_form -->
                <div class="render_form">
                    <li class="row row_time row_css" data-row="seminarTime" data-check="true" data-check-pass="false" data-check-comment="세미나일정을 선택해주세요." data-inbound-prop="contentSeminarId" data-inbound-value="">
                        <label class="select_wrap label_css">
                            <select class="select_css">
                                <option value="">선택</option>
                            </select>
                            <u class="select_arrow arrow_css theme_border_left_color theme_border_bottom_color"></u>
                        </label>
                    </li>
                </div>

                <div class="map hidden">
                    <p class="tit">오시는 길</p>
                    <p class="desc">주소 : 대구 중구 공평로 88 대구광역시청</p>
                    <div class="kakaomap"></div>
                </div>
            </div>
        </div>
        <div class="btn_area">
            <a class="link_list" href="">목록</a>
        </div>
    </section>
    <!-- //글보기 -->
</main ><!-- /main -->
</template>



<script>
import $ from 'jquery'

export default {
    mounted() {
        $(function(){ // DOCUMENT READY...
        /*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */(function(){



        })();/*
        ■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■
        */});// DOCUMENT READY...
    },
}
</script>



<style scoped lang="scss">
    @import '@/assets/scss/board/view.scss';
</style>

<template>
<!-- main -->
<main id="container">

	<!-- 메인 -->
	<section id="visual" class="isAppear" data-type="b">
		<div class="inner">
				<h2>(가족법인활용)자산이전</h2>
				<p class="desc_section">
					가족법인이란? 상증세법상 지배주주<br>
					(지분율이 가장 높은 개인주주 등)와 그 친족이<br>
					<b>직·간접적으로 30% 이상의 지분율을 보유한 법인</b><br>
					입니다. (*비상장 오너 기업의 대부분이 이에 해당)
				</p>
				<div class="div-button-consult">
					<button class="btn_cont" onclick="LAYER_CONT(246)" type="button">
						<span>(가족법인활용)자산이전 상담신청</span>
						<i></i>
					</button>
				</div>
		</div>
	</section>
	<!-- /메인 -->

	<!-- 실제 적용 및 효과 -->
	<section id="effect" class="section">
		<h3 class="tit_section">실제 적용 및 효과</h3>
		<div class="inner">
			<div class="effect_list">
				<div class="effect_item isAppear">
					<p><strong>실무적 관점</strong></p>
					<span>고액자산가나 중소(견)기업 오너들의 가족들이<br>지배주주인 가족 법인을 설립한 후, 이를 활용한 절세나<br>투자계획을 수립하여 실행</span>
				</div>
				<div class="effect_item ">
					<p><strong>효과 및 장점</strong></p>
					<div class="thumb_item isAppear">
						<div class="thumb">
							<img alt="" src="/img/consulting/assets_b/effect_img_1_1.png">
						</div>
						<div class="text">
							<div class="text_cnt">
								[자산투자 및 사업투자 과정]의<br>
								소득 및 자산 증식과 관련된<br>
								<b>귀속 주체를 자녀 및 손자녀</b><br>
								<b>세대로 미리 확정하는 효과</b><br>
								(상속세 절세 혜택)
							</div>
						</div>
					</div>
					<div class="thumb_item isAppear">
						<div class="thumb">
							<img alt="" src="/img/consulting/assets_b/effect_img_1_2.png">
						</div>
						<div class="text">
							<div class="text_cnt">
								소득세에 대한 과세 측면에서도,<br>
								개인 또는 기존 법인 명의로<br>
								투자하는 것보다 <b>더 적극적인</b><br>
								<b>분산 정책 가능</b>
							</div>
						</div>
					</div>
					<div class="thumb_item isAppear">
						<div class="thumb">
							<img alt="" src="/img/consulting/assets_b/effect_img_1_3.png">
						</div>
						<div class="text">
							<div class="text_cnt">
								<b>자금조달</b> 측면에서도 여러가지<br>
								장점 존재
							</div>
						</div>
					</div>
					<div class="thumb_item isAppear">
						<div class="thumb">
							<img alt="" src="/img/consulting/assets_b/effect_img_1_4.png">
						</div>
						<div class="text">
							<div class="text_cnt">
								기존 법인의 가업승계,<br>
								이익금 환원, 가지급금 정리 등<br>
								<b>오너 CEO 리스크 관리 가능</b>
							</div>
						</div>
					</div>
				</div>
				<div class="effect_item isAppear">
					<p><strong>특정법인(관계회사)을 활용한 가업승계</strong></p>
					<div class="ytv_box">
						<button class="videoLayer" data-src="https://www.youtube.com/embed/ZKy8V5yukFQ" data-title="특정법인(관계회사)을 활용한 가업승계" data-type="youtube" type="button" title="영상재생">
							<img class="thumb" src="https://img.youtube.com/vi/ZKy8V5yukFQ/maxresdefault.jpg" alt="스크린샷">
						</button>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- / 실제 적용 및 효과 -->


	<!-- 관련 세법의 검토와 사례 -->
	<section id="example" class="section">
		<h3 class="tit_section">관련 세법의 검토와 사례</h3>
		<div class="inner">
			<ul>
				<li class="isAppear">
					<strong>가족 법인에 과도한 이익을 주게 될 경우</strong>
					<p>
						외관상 그 가족 법인에 이익을 준 것이지만, 간접적으로 그 가족 법인의 지배주주들인 가족들에게 이익을 준 것으로 볼 여지가 있기 때문에 세법에서는 이러한 경우에 대한 구체적인 과세 요건을 <b>상속세 및 증여세법 제45조의5(특정법인과의 거래를 통한 이익의 증여 의제)를 통해 규정</b>
					</p>
				</li>
				<li class="isAppear">
					<strong>가족 법인에 지배주주의 특수관계인이 가족 법인에 이익을 분여 할 경우</strong>
					<p>
						가족 법인에게 법인세가 과세되는 것과는 별개로 가족 법인의 지배주주들에게 <b>간접적인 증여가 발생한 것으로 보아(=증여의제) 증여세를 과세</b>
					</p>
				</li>
				<li class="isAppear">
					<strong>증여의제가 되어 증여세를 과세하는 사례</strong>
					<p>
						<span>재산 또는 용역을 무상으로 제공 받는 것</span>
						<span>재산 또는 용역을 통상적인 거래 관행에 비추어 볼 때 현저히 낮은 대가로 양도ㆍ제공 받는 것</span>
						<span>재산 또는 용역을 통상적인 거래 관행에 비추어 볼 때 현저히 높은 대가로 양도ㆍ제공 하는 것</span>
						<span>해당 법인의 채무를 면제ㆍ인수 또는 변제하는 것. 다만, 해당 법인이 해산(합병 또는 분할에 의한 해산은 제외) 중인 경우로서 주주 등에게 분배 할 잔여재산이 없는 경우는 제외</span>
						<span>시가보다 낮은 가액으로 해당 법인에 현물출자하는 것</span>
					</p>
				</li>
			</ul>
		</div>
	</section>
	<!-- / 관련 세법의 검토와 사례 -->

	<!-- 활용 접근법 -->
	<section id="using" class="section">
		<h3 class="tit_section">활용 접근법</h3>
		<div class="inner">
			<div class="box">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_1.png" alt="">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_2.png" alt="">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_3.png" alt="">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_4.png" alt="">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_5.png" alt="">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_6.png" alt="">
				<img class="isAppear" src="/img/consulting/assets_b/using_img_1_7.png" alt="">
			</div>
		</div>
	</section>
	<!-- / 활용 접근법 -->

	<!-- 활용 방안 -->
	<section id="method" class="section">
		<h3 class="tit_section">활용 방안</h3>
		<div class="inner">
			<div class="box">
				<ul>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/assets_b/method_img_1_1.png" alt="">
						</div>
						<div class="info">
							<strong>가족 법인 증여의제 기준금액 미만으로 하는 법인 주주(=가족 법인) 차등 배당</strong>
							<p>
								<span>가족 법인의 <b>주주구성이 25%씩 가족 4인일 경우</b>에는 기존의 법인으로부터 가족 법인이 <b>매년 4억원 이상의 합법적이고 증여세가 비과세되는 차등 배당</b>을 받는 것이 가능</span>
								<span>단, 가족 법인의 배당에 따른 법인세는 과세됨</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/assets_b/method_img_1_2.png" alt="">
						</div>
						<div class="info">
							<strong>가족 법인 증여의제 기준금액 미만으로 하는 무이자, 저리 가수금 플랜</strong>
							<p>
								<span>가족 법인의 <b>주주구성이 25%씩 가족 4인일 경우에는 가족들로부터 최대 약 96억원의 가수금을 무이자로 차입 가능</b>하며, 이 과정에서 게임 채권단 가족 법인, 가족 법인의 지배주주 모두에게 세금문제가 발생하지 않을 수 있음</span>
								<span>기준이 되는 이자율은 4.6%</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/assets_b/method_img_1_3.png" alt="">
						</div>
						<div class="info">
							<strong>가족 법인 증여의제 기준금액 미만으로 하는 증여 또는 채무면제 플랜</strong>
							<p>
								<span>가족 법인의 <b>주주구성이 25%씩 가족 4인일 경우</b>에는 가족들로부터 가족 법인이 <b>매년 4억원 이상의 합법적이고 증여세가 비과세되는 재산 증여 또는 채무 면제</b>를 받는 것이 가능</span>
								<span>단, 가족 법인에 해당 증여 및 채무면제이익에 따른 법인세는 과세됨</span>
							</p>
						</div>
					</li>
					<li class="isAppear">
						<div class="thumb">
							<img src="/img/consulting/assets_b/method_img_1_4.png" alt="">
						</div>
						<div class="info">
							<strong>가족 법인이 기존 법인의 주주로 참여한 후 불균등 증/감자를 활용한 가업승계 플랜</strong>
							<p>
								<span>불균등 저가 감자에 참여하지 않거나 불균등 저가 증자에 참여하는 것은 가족 법인 증여의제 규정에서 정한 거래 유형이 아니므로 관련된 <b><em>증/감자</em>를 통한 지배주주의 간접적 이익증가에 대해서는 증여세가 비과세</b> 됨</span>
								<span>단, 불공정자본거래를 통한 가족 법인의 이익에 대해서는 법인세가 과세되며, 다른 세무적 이슈 여부에 대한 추가적인 판단이 요구됨</span>
							</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- / 활용 방안 -->

	<!-- 주의사항 -->
	<section id="caution" class="section">
		<h3 class="tit_section">주의사항</h3>
		<div class="inner">
			<div class="box">
				<p class="tit">
					<span>규정해석상 주의사항</span>
				</p>
				<ul>
					<li class="isAppear">
						<span>증여이익 과세 여부의 기준인 1억원의 판단은 [거래일 기준 소급 1년 단위+동일한 유형]의 합계를 기준으로 한다.</span>
					</li>
					<li class="isAppear">
						<span>동일한 유형이 아닐 경우에는 1억원의 판단을 별도로 한다.</span>
					</li>
					<li class="isAppear">
						<span>가족 법인 판단(30% 이상)이나 증여의제금액 계산 시 곱해주는 지분율은 자기 주식은 제외한 지분율을 기준으로 한다.</span>
					</li>
					<li class="isAppear">
						<span>가족 법인과의 거래를 통한 이익의 증여의제는 합산배제증여재산이 아니므로 10년 이내에 동일인으로부터 받은 다른 증여 이익과 합산하여 증여세를 신고납부하여야 한다.</span>
					</li>
					<li class="isAppear">
						<span>가족 법인에 이익을 준 자가 가족 법인의 지배주주인 경우 자기 증여 부분에 대해서는 증여세를 과세하지 않는다.</span>
					</li>
				</ul>
			</div>
		</div>
	</section>
	<!-- / 주의사항 -->

	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-type="1" data-list-count="7" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="isAppear"></section>
	<!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
<!-- //container -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/assets_b/'
		}
	},
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/assets_b.scss';
</style>
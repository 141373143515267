<template>
<!-- main -->
<main id="container">
	<!-- 메인 -->
	<section id="visual" class="isAppear" data-type="b">
		<div class="inner">
			<h2>가업승계전략</h2>
			<p class="desc_section">
				기업의 가업승계는 <b>기업의 규모와 형태,</b> <br>
				<b>상속 및 증여자의 요건에 따라 그 방법을 달리</b> 해야 하며,<br>
				상속세나 증여세의 문제에 대한 대책을<br>
				체계적으로 마련해야 합니다.
			</p>
			<div class="div-button-consult">
				<button class="btn_cont" onclick="LAYER_CONT(193)" type="button">
					<span>가업승계전략 상담신청</span>
					<i></i>
				</button>
			</div>
		</div>
	</section>
	<!-- //메인 -->

	<!-- 컨설팅 사례 -->
	<section id="example" class="isAppear" data-type="b">
		<h3 class="tit_section">가업승계전략 컨설팅 사례</h3>
		<button class="videoLayer" data-src="https://www.youtube.com/embed/mMi5Zxqt9yo" data-title="가업상속공제와 가업승계 증여세 과세특례 2024년 총정리" data-type="youtube" type="button" title="영상재생">
			<img class="thumb" src="https://img.youtube.com/vi/mMi5Zxqt9yo/maxresdefault.jpg" alt="스크린샷">
		</button>
		<div class="inner">
			<div class="list_talk">
				<div class="item_talk">
					<img class="profile" :src="imgPath+'example_talk_profile_client.png'" alt="">
					<div class="tooltip">
						<p class="tit"><strong>컨설팅 문의</strong></p>
						<p>대표이사 부부의 지분이 100%로 후계자에 대한 지분이 없어 상속 발생 시 상속세가 30억 이상 발생되는 문제가 있습니다.</p>
					</div>
				</div>
				<div class="item_solution">
					<p class="item_tit"><strong>해결방법</strong></p>
					<div class="item_answer">
						<p>기업가치 인하에 우선적으로 방향성을 두어 실질적인 절세효과를 가져왔습니다.</p>
						<p>사모님의 지분을 먼저 이동 후에 대표이사의 주식을 이동하였으며, 기업가치 인하 플랜은 구체적으로 순손익과 순자산 두가지 요인을 구분해서 5년간의 솔루션을 도출하였습니다.</p>
						<p>후계자 지분 100%로 관련 신설법인 설립 후 합병하였습니다.</p>
						<p>대표이사의 실질적인 임원퇴직을 하여 순손익 주당가치 평가금액을 인하 하였습니다.</p>
						<p>임원퇴직 후 반영된 기업가치로 사모님 40% 지분을 STEP UP 이익소각 => 후계자 가족대상으로 3개년 차등배당을 실시하였습니다.</p>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /컨설팅 사례 -->

	<!-- 지원제도 -->
	<section id="support">
		<h3 class="tit_section">가업승계 지원제도</h3>
		<div class="inner">
			<div class="tab-container">
				<div class="tab_nav">
					<button type="button" class="tab-btn active"><span>기업상속공제</span></button>
					<button type="button" class="tab-btn"><span>가업승계<br>증여세 과세특례</span></button>
				</div>
				<div class="tab-wrapper">
					<div class="tab_wrap active">
						<div class="item_cont">
							<p class="item_title">지원요건</p>
							<div class="list">
								<p class="list_title">기업요건</p>
								<ul>
									<li>피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업</li>
									<li>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 조특법상 중소기업(매출액과 독립성 요건 충족)으로서 자산총액 5천억원 미만인 중소기업</li>
									<li>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 직전 3개 사업연도의 평균매출액이 3천억원 미만인 조특법상 중견기업</li>
									<li>2개 이상의 가업을 영위하는 경우 가업에 해당하는지 여부는 각 사업장별로 판단</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">피상속자 (증여자)</p>
								<ul>
									<li>상속개시일 현재 거주자</li>
									<li>피상속인과 그 특수관계자가 지분 50%이상을 10년 이상 계속하여 보유</li>
									<li>
										아래 3가지 중 1가지를 충족하는 기간을 대표이사로 재직(공동대표이사 포함)
										<ol class="number_ol">
											<li>가업영위기간의 50% 이상</li>
											<li>상속개시일부터 소급한 10년 중 5년 이상(위 1,2는 피상속인이 상속개시일 현재 가업을 사망일까지 영위하여야 함)</li>
											<li>10년 이상의 기간(상속인이 피상속인의 대표이사 직을 승계한 날부터 상속개시일까지 계속하여 재직한 경우에 한함)</li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">상속인 (수증자)</p>
								<ul>
									<li>상속개시일 현재 18세 이상</li>
									<li>상속개시일 전 2년 이상 가업에 종사(피상속인이 65세 이전이나 천재지변으로 사망 시 등 일부 예외인정 규정 존재)</li>
									<li>상속제 신고기한까지 임원취임 및 신고기한부터 2년 이내 대표이사 취임</li>
									<li>
										중견기업은 가업상속재산 외에 상속재산의 가액이 해당 상속인이 상속세로 납부할 금액에 2배를 초과하지 않아야 함
										<ol class="line_ol">
											<li>위 1,2,3번의 요건을 상속인의 배우자가 충족시에도 인정</li>
											<li>2인 이상의 공동상속 인정</li>
											<li>가업재산 중 일부만의 가업상속도 인정<br>(단,모든요건을 갖춘 상속인이 상속받는 가업상속재산만 가업상속공제가 됨)</li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">신고서 제출</p>
								<ul>
									<li>상속세 과세표준신고서와 함께 가업상속공제신고서 등을 관할 세무서장에게 제출하여야 함</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">지원배제 요건</p>
								<ul>
									<li>피상속인 또는 상속인이 가업경영에서 탈세/회계부정 행위로 징역형 또는 벌금형을 선고받고 형이 확정될 경우에는 가업상속공제 배제 (사후관리기간이라면 추징)</li>
								</ul>
							</div>
						</div>
						<div class="item_cont">
							<p class="item_title">지원 제도</p>
							<div class="list">
								<p class="list_title">가업요건</p>
								<ul>
									<li>
										가업상속재산가액 전액을 한도범위내에서 상속세 계산 시 상속공제를 해줌
										<ol class="line_ol">
											<li>
												<strong>가업상속재산(법인가업)</strong>
												<span>상증법상 주식평가액 X [1-(사업무관자산가액/총자산가액)]</span>
											</li>
											<li>
												<strong>사업무관자산(상속개시일 현재)</strong>
												<span><i>①</i>비사업용토지(법인세법 55조의 2)</span>
												<span><i>②</i>업무무관자산(법인세법 시행령 49조) 및 임대용 부동산</span>
												<span><i>③</i>대여금(법인세법 시행령 61조1항2호)</span>
												<span><i>④</i>과다보유 현금(직전 5개 사업연도말 평균 현금 보유액의 150% 초과)</span>
												<span><i>⑤</i>법인의 영업활동과 무관하게 보유한 주식등, 채권 및 금융상품④는 제외</span>
											</li>
											<li>
												<strong>공제한도 : 피상속인의 가업영위기간별로 10년 이상 200억원, 20년이상 300억원, 30년 이상 500억원으로 차등 적용</strong>
												<span><i>①</i>일반연부연납 가능</span>
												<span><i>②</i>가업상속재산에 해당 하나 가업상속공제를 받지 못한 부분에 대해서는 최장 20년의 연부연납 가능</span>
											</li>
										</ol>
									</li>
								</ul>
							</div>
						</div>
						<div class="item_cont">
							<p class="item_title">사후 관리</p>
							<div class="list">
								<p class="list_title">기간</p>
								<ul>
									<li>상속개시일부터 7년을 사후관리</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">자산처분 금지요건</p>
								<ul>
									<li>
										가업용자산의 20%(상속개시일부터 5년 이내는 10%)이상을 처분하는 경우
										<ol class="line_ol">
											<li><strong>가업용자산이란 가업법인의 사업에 직접 사용되는 사업용 고정자산</strong></li>
											<li><strong>처분비율 : 처분한 가업용자산/가업용 자산(가액은 상속개시일 기준)</strong></li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">가업종사 요건</p>
								<ul>
									<li>
										피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업
										<ol class="line_ol">
											<li><strong>대표이사 직을 유지하지 않는 경우</strong></li>
											<li><strong>가업의 주된 업종을 변경하는 경우(중분류 내 변경은 인정, 평가심의위원회 심의시는 중분류 외 변경 허용)</strong></li>
											<li><strong>1년 이상 휴/폐업하는 경</strong></li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">지분유지 요건</p>
								<ul>
									<li>
										상속인의 지분이 감소하는 경우
										<ol class="line_ol">
											<li><strong>처분/유상증자시 실권하는 경우</strong></li>
											<li><strong>상속인의 특수관계자가 처분 및 유상증자시 실권하여 상속인이 최대주주에 해당되지 않은 경우</strong></li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">고용유지 요건1</p>
								<ul>
									<li>
										아래를 모두 충족하는 경우
										<ol class="line_ol">
											<li><strong>매년의 정규직 근로자 수의 평균이 기준고용인원의 80%에 미달하는 경우</strong></li>
											<li>
												<strong>매년의 정규직 근로자의 총급여액이 기준총급여액의 80%에 미달하는 경우</strong>
												<span><i>①</i>정규직 근로자는 근로기준법에 의하되 계약기간 1년 미만 / 월60시간 미만 근로자 / 건강.국민연금 미가입자 등은 제외</span>
												<span><i>②</i>기준고용인원 : 상속개시 직전 2개 사업연도의 정규직근로자 수의 평균</span>
												<span><i>③</i>기준총급여액 : 상속개시 직전 2개 사업연도의 정규직근로자에게 지급한 총급여액의 평균</span>
												<span><i>④</i>이 기준은 매년 판단함</span>
											</li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">고용유지 요건2</p>
								<ul>
									<li> 상속개시된 사업연도말부터 7년간 정규직 근로자 수의 전체평균이 기준고용인원에 미달하고, 7년간 총급여액의 전체 평균이 기준총급여액에 미달하는 경우(7년 후 판단) </li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">위반시 제재</p>
								<ul>
									<li>[공제받은 금액 X 사후의무 위반기간에 따른 추징율]<br> 한 금액을 상속개시 당시의 상속세 과세가액에 산입하여 상속세를 재계산한 상속세에 추징사유 발생일까지의 이자 상당액(연1.8%의 이율)을 가산하여 납부하여야 함</li>
									<li>
										단, 자산처분금지 위반시는<br> (공제받은 금액 X 추징율 X 자산처분비율)한 금액을<br> 기준으로 함
										<ol class="line_ol">
											<li><strong>기간별 추징율 : 5년 미만은 100%, 5년 이상은 80%</strong></li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">정당한 사유</p>
								<ul>
									<li>사후관리의무 위반에 정당한 사유가 있는 경우에는 상속세가 추징되지 않음<br> Ex) 내용연수가 지난 가업용자산의 처분, 상속인의 사망, 합병 등 조직변경 / 물납 / 상장요건, 충족위한 지분감소(단, 최대주주는 유지해야 함)</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">기타</p>
								<ul>
									<li>
										가업상속재산에 대한 양도소득세 이월과세
										<ol class="line_ol">
											<li> 가업상속공제를 적용받는 재산 중 양도소득세 과세대상 재산에 대해서는 상속인이 양도할 때 피상속인의 보유기간 동안 발생한 지본이득에 대하여 양도소득세를 납부하여야 함 </li>
										</ol>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="tab_wrap">
						<div class="item_cont">
							<p class="item_title">지원요건</p>
							<div class="list">
								<p class="list_title">기업요건</p>
								<ul>
									<li>피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업</li>
									<li>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 조특법상 중소기업(매출액과 독립성 요건 충족)으로서 자산총액 5천억원 미만인 중소기업</li>
									<li>직전연도말 기준 상증법에 정해진 업종을 주된 사업으로 영위하는 직전 3개 사업연도의 평균매출액이 3천억원 미만인 조특법상 중견기업</li>
									<li>2개 이상의 가업을 영위하는 경우 가업에 해당하는지 여부는 각 사업장별로 판단</li>
									<li>단, 증여자를 기준으로 요건충족여부를 판단하며,  개인사업체의 경우에는 증여세 과세특례규정이  적용되지 않음</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">피상속자 (증여자)</p>
								<ul>
									<li>증여일 현재 60세 이상인 수증자의 부모(증여당시 부모가 사망한 경우에는 그 사망한 부모의 부모를 포함) </li>
									<li>증여자와 그 특수관계자가 기분의 50%이상을 10년 이상 계속하여 보유</li>
									<li>가업을 10년 이상 계속하여 경영(대표이사 재직요건은 없음)</li>
									<li>부모가 각각 10년 이상 계속하여 가업을 경영한 경우에는 부와 모가 모두 특례증여의 증여자가 될 수 있음.</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">상속인 (수증자)</p>
								<ul>
									<li>증여일 현재 18세 이상의 거주자인 자녀</li>
									<li>
										수증자 또는 그 배우자가 증여세 신고 기한까지 가업에  종사하고, 증여일로 부터 5년 이내에 대표이사 (공통대표 이사 포함)에 취임
										<ol class="star_ol">
											<li>2인 이상이 가업을 승계하는 경우 가업승계자 모두에게 특례적용</li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">신고서 제출</p>
								<ul>
									<li>증여세 신고기한까지 과세표준 신고서와 함께 주식 등 특례신청서를 납세지 관할세무서장에게 제출하여야 함</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">지원배제 요건</p>
								<ul>
									<li>없음</li>
								</ul>
							</div>
						</div>
						<div class="item_cont">
							<p class="item_title">지원제도</p>
							<div class="list">
								<p class="list_title">가입요건</p>
								<ul>
									<li>가업주식등의 가액 중 가업자산상당액에 대한 증여세의 과세가액(100억원 한도)에서 5억원을 공제한 후, 10%(과세표준 30억원 초과분은 20%)세율을 적용하여 증여세를 계산</li>
								</ul>
								<table class="table">
									<thead>
										<tr>
											<th>가업자산상당액</th>
											<th>공제</th>
											<th>과세표준</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>5억</td>
											<td>5억</td>
											<td>0</td>
										</tr>
										<tr>
											<td>35억</td>
											<td>5억</td>
											<td>30억</td>
										</tr>
									</tbody>
								</table>
								<ol class="line_ol">
									<li>
										<strong>가업자산상당액</strong>
										<span>상증법상 주식평가액 X [1-(사업무관자산가액/총자산가액)]</span>
									</li>
									<li>
										<strong>사업무관자산은 좌동(증여일 현재)</strong>
									</li>
									<li>
										<strong>공제한도는 100억원</strong>
										<span><i>①</i>해당 증여 전에 부모로부터 동일 가업주식을 증여받은 가액은 합산하나, 일반증여재산과는 합산하지 않음</span>
										<span><i>②</i>신고세액공제 미적용</span>
										<span><i>③</i>가업주식 등의 가액 중 사업무관자산비율 상당액과 과세특례한도초과분은 일반증여재산으로서의 증여세를 계산함</span>
										<span><i>④</i>일반연부연납 가능</span>
										<span><i>⑤</i>가업상속요건을 갖춘 경우에는 특례증여 받은 주식도 추후 가업상속공제가 가능</span>
										<span><i>⑥</i>상속공제 종합한도에서 차감는 사전증여에서 제외됨</span>
										<span><i>⑦</i>한 수증자가 가업승계와 창업자금의 과세특례를 중복적용 받을 수는 없음</span>
									</li>
								</ol>
							</div>
						</div>
						<div class="item_cont">
							<p class="item_title">사후 관리</p>
							<div class="list">
								<p class="list_title">기간</p>
								<ul>
									<li>증여일부터 7년을 사후관리</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">자산처분 금지요건</p>
								<ul>
									<li>해당사항 없음</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">가업종사 요건</p>
								<ul>
									<li>
										피상속인이 10년 이상 업종변경 없이 계속하여 경영한 기업
										<ol class="line_ol">
											<li><strong>대표이사 직을 유지하지 않는 경우</strong></li>
											<li><strong>가업의 주된 업종을 변경하는 경우(중분류 내 변경은 인정, 평가심의위원회 심의시는 중분류 외 변경 허용)</strong></li>
											<li><strong>1년 이상 휴/폐업하는 경우</strong></li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">지분유지 요건</p>
								<ul>
									<li>
										상속인의 지분이 감소하는 경우
										<ol class="line_ol">
											<li><strong>처분/유상증자시 실권하는 경우</strong></li>
											<li><strong>상속인의 특수관계자가 처분 및 유상증자시 실권하여 상속인이 최대주주에 해당되지 않은 경우</strong></li>
										</ol>
									</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">고용유지 요건1</p>
								<ul>
									<li>해당사항 없음</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">고용유지 요건2</p>
								<ul>
									<li>해당사항 없음</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">위반시 제재</p>
								<ul>
									<li>해당 가업주식의 가액을 일반증여재산으로 보아 기본세율(10%~50%)로 증여세를 재계산하여 추징사유 발생일까지의 이자상당액(1일 2.5/10,000의 이율)과 함께 납부하여야 함</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">정당한 사유</p>
								<ul>
									<li>사후관리의무 위반에 정당한 사유가 있는 경우에는 증여세가 추징되지 않음<br> Ex&#41; 수증자의 사망, 증여받은 주식을 국가 등에 증여, 수증자의 병역의무 이행 / 질병요양 등, 합병 등 조직변경 / 물납 / 상장요건 충족위한 지분감소(단, 최대주주는 유지해야 함)</li>
								</ul>
							</div>
							<div class="list">
								<p class="list_title">기타</p>
								<ul>
									<li>기간에 관계없이 상속재산에 합산<br>(증여 후 10년이 경과하여도 상속재산에 합산. 단 요건 충족시에는 해당 주식에 대하여 가업상속공제 적용 가능)</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /지원제도 -->

	<!-- 3가지 방법 -->
	<section id="method">
		<h3 class="tit_section">가업승계 3가지 방법</h3>
		<div class="inner">
			<div class="method_list">
				<div class="method_item isAppear">
					<p class="no-number"><strong>가업승계 전략</strong></p>
					<div class="thumb_list">
						<div class="thumb_item">
							<div class="thumb">
								<img alt="상속 및 증여세 부담 완화 전략 수립" :src="imgPath+'strategy_b_method_img_01.jpg'">
							</div>
							<div class="text">
								<div class="text_cnt">상속 및 증여세<br> 부담 완화 전략 수립 </div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="M&A 활용 승계 전략" :src="imgPath+'strategy_b_method_img_02.jpg'">
							</div>
							<div class="text">
								<div class="text_cnt">M&A 활용<br> 승계 전략</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="가업승계를 위한 기업 맞춤형 장기계획 수립" :src="imgPath+'strategy_b_method_img_03.jpg'">
							</div>
							<div class="text">
								<div class="text_cnt">가업승계를 위한<br> 기업 맞춤형<br> 장기계획 수립</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="가업승계 정부지원제도 적극 활용법 제시" :src="imgPath+'strategy_b_method_img_04.jpg'">
							</div>
							<div class="text">
								<div class="text_cnt">가업승계 정부지원제도 <br> 적극 활용법 제시</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="가업승계를 위한 장애물 제거 " :src="imgPath+'strategy_b_method_img_05.jpg'">
							</div>
							<div class="text">
								<div class="text_cnt">가업승계를 위한<br> 장애물 제거 </div>
							</div>
						</div>
					</div>
					<div class="icon"></div>
				</div>
				<div class="method_item isAppear">
					<div class="count_list">
						<div class="count">
							<p>중소기업의 대주주가 비상장법인의 주식을 <b class="orange">양도</b>하는 경우</p>
							<div class="mt-130">
								<img alt="상속 및 증여세 부담 완화 전략 수립" :src="imgPath+'strategy_b_method_img_07.png'">
							</div>
						</div>
						<div class="count">
							<p>자녀가 부모의 주식을 <b class="orange">증여</b>를 통해 승계하는 경우</p>
							<div class="list">
								<ul>
									<li>10% ~ 50% 증여세율 적용</li>
									<li>승계 시점에 부모의 주식가치가 높다면 증여세 부담이 증가되기에 승계 이전 주식가치를 낮추는 것이 좋음</li>
									<li>증여세 부담이 있다면 원활한 가업승계가 어렵기 때문에 현행 세법의 다양한 가업승계 지원제도 이용<br> (가업상속공제, 가업승계 증여세 과세특례)</li>
								</ul>
							</div>
						</div>
						<div class="count">
							<p><b class="orange">상속</b>을 통한 가업승계</p>
							<div class="list">
								<ul>
									<li>비상장회사일수록 상속제 재원문제를 법인 활용하여 준비하는 것이 필수적</li>
									<li>상속받는 법인에서 상당 부분의 납세재원을 충당하는 것이 바람직</li>
									<li>계약자와 수익자는 법인으로 피보험자는 CEO의 형태로 종신보험, 정기보험에 가입하는 방안 추천 피보험자인 CEO의 유고 시 보험회사로부터 약속된 보험금을 현금성 자산으로 지급 받음으로써 법인의 경영활동에 필요한 지금과 무관한 잉여현금흐름을 발생 시킬 수 있으며, 해당 자금을 상속제 납세재원으로 활용 가능</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div class="method_item isAppear">
					<p><strong>가업승계 정의</strong></p>
					<div class="contents isAppear">
						<p>경영하는 기업이 지속되도록 소유권ㆍ경영권을 물려주는 것</p>
						<div class="cont_box isAppear">
							<strong>소유권 승계</strong>
							<ul>
								<li>
									<b>증여</b>
									<span>
										생전!<br>
										후계자에게 이전
									</span>
								</li>
								<li>
									<b>상속</b>
									<span>
										사망 후<br>
										후계자에게 이전
									</span>
								</li>
							</ul>
						</div>
						<div class="cont_box isAppear">
							<strong>경영권 승계</strong>
							<ul>
								<li>
									<b>증여 + 상속</b>
									<span>
										생전에 일부,<br>
										사망 후 잔여 이전
									</span>
								</li>
								<li>
									<b>청산 또는 M&A</b>
									<span>
										후계자가 없는 경우,<br>
										사업운영상 양도
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="method_item isAppear">
					<p><strong>가업승계 가이드</strong></p>
					<div class="contents">
						<p class="isAppear">세무준비가 부족하면 납세재원 부족으로 기업존속 불확실!</p>
						<strong class="isAppear">막대한 세금을 어떻게 줄일지, 어떻게 세금을 마련할지 준비</strong>
						<ul>
							<li class="isAppear">
								<strong>절세 관점</strong>
								<p>
									<span>가업승계=주식이전(증여세, 상속세, 양도세)</span>
									<span>가업승계 실행시점 주식평가액 규모</span>
									<span>주식평가 하락을 통한 절세방안</span>
									<span>가업 재산의 실질적인 가치 하락 無</span>
								</p>
							</li>
							<li class="img_area isAppear">
								<img alt="" :src="imgPath+'method_img_1.png'">
							</li>
							<li class="isAppear">
								<strong>세금 준비</strong>
								<p>
									<span>세금 납무재원 마련=현금 유동성 확보</span>
									<span>세금 납부재원 직접조달vs외부 차입</span>
									<span>자금출처 입증 절세방안 준비</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="method_item isAppear">
					<p><strong>가업승계의 재무적인 해결방안</strong></p>
					<div class="thumb_list">
						<div class="thumb_item">
							<div class="thumb">
								<img alt="비상장주식가치 평가 및 지속적인 관리" :src="imgPath+'method_img_2_1.png'">
							</div>
							<div class="text">
								<div class="text_cnt">비상장주식가치 <b>평가</b> 및<br>지속적인 <b>관리</b></div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="CEO의 주식을 자녀와 배우자로 지분이동" :src="imgPath+'method_img_2_2.png'">
							</div>
							<div class="text">
								<div class="text_cnt">CEO의 주식을 자녀와<br>배우자로 <b>지분이동</b></div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="자녀의 자금 유동성 확보 (자금출처 대비)" :src="imgPath+'method_img_2_3.png'">
							</div>
							<div class="text">
								<div class="text_cnt">자녀의 자금 <b>유동성 확보</b><br>(자금출처 대비)</div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="자녀를 임원으로 등재하기" :src="imgPath+'method_img_2_4.png'">
							</div>
							<div class="text">
								<div class="text_cnt">자녀를 <b>임원</b>으로<br><b>등재하기</b></div>
							</div>
						</div>
						<div class="thumb_item">
							<div class="thumb">
								<img alt="CEO의 지분율은 낮추고 소유재산 이전하기" :src="imgPath+'method_img_2_5.png'">
							</div>
							<div class="text">
								<div class="text_cnt">CEO의 지분율은 낮추고<br><b>소유재산 이전</b>하기</div>
							</div>
						</div>
					</div>
				</div>
				<div class="method_item">
					<p><strong>상속ㆍ증여 절세의 기본전략<br><em>(자금 흐름과 출처 마련 솔루션)</em></strong></p>
					<div class="contents isAppear">
						<div class="img_area">
							<img src="/img/consulting/strategy_b/method_img_3.png" alt="">
						</div>
						<ul>
							<li class="isAppear">
								<strong>현재 보유재산의 파악 및 세무상 평가 기준에 따라 상속세 계산</strong>
								<p>자산-부채를 모두 포함한 현황 파악 및 상속세법상 평가 기준에 의한 평가액 산정</p>
							</li>
							<li class="isAppear">
								<strong>현재 보유재산을 근거로 향후 매 10년 단위의 상속재산 예상가액 추정 및 그에 근거한 상속세액 예측 (10년 단위 재수행)</strong>
								<p>추정시 보유사업체의 성장 속도, 경제지표 (금리, 환율, 부동산 /주식 시장 등)고려</p>
							</li>
							<li class="isAppear">
								<strong>사전 증여전략/사전 처분전략의<br>수립 ↔ 독보적 유류분 설계</strong>
								<p>10년 주기로 증여/부동산,저평가 주식 이전 등 다양한 방법의 상속/증여 전략수립</p>
							</li>
						</ul>
					</div>
				</div>
				<div class="method_item isAppear">
					<div class="checkpoint">
						<p>가업승계<i>&nbsp;Check point</i></p>
						<ol>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									가업승계 시 높은 상속세율에 따른 세금부담을<br> 완화를 위한 전략을 수립해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									비업무용자산(현금, 업무무관 부동산, 가지급금,<br> 투자유가증권 등)은 가업상속에서<br> 제외해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									가업상속공제 활용을 위한 명의신탁 차명주식을<br> 회수해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									가업승계를 위한 효과적인 지배구조 개선 방안을<br> 제시해야 합니다.
								</div>
							</li>
							<li>
								<p>Check&nbsp;</p>
								<div class="check_text">
									가업승계지원제도 활용 및 사후관리 방안을<br> 제시해야 합니다.
								</div>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /3가지 방법 -->


	<!-- 컨설팅 프로세스 -->
	<consultingProcess_b data-type="1" data-list-count="7" />
	<!-- //컨설팅 프로세스 -->

	<!-- 전문가 네트워크 -->
	<networkTab class="is-event-none" />
	<!-- //전문가 네트워크 -->

	<!-- 무료 컨설팅 상담신청 -->
	<section id="consulting" class="isAppear"></section>
	<!-- //무료 컨설팅 상담신청 -->
</main ><!-- /main -->
</template>



<script>
export default {
	data() {
		return {
			imgPath: '/img/consulting/strategy_b/'
		}
	}
}
</script>



<style scoped lang="scss">
	@import '@/assets/scss/consulting/strategy_b.scss';
</style>